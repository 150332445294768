<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card>
      <v-card-title class="primary vf-add-payment px-5">
        {{ $t("message.operationList.editPayment") }}
        <v-spacer />
        <v-icon @click.stop="vfInfo = !vfInfo" :color="vfInfo ? 'amber' : 'white'"
          >mdi-information-outline</v-icon
        >
      </v-card-title>
      <v-card-text class="px-5">
        <!-- {{ JSON.stringify(payment) }} : {{ JSON.stringify(form.data) }} -->
        <transition name="fade">
          <v-row class="ma-0 pa-0" v-if="vfInfo">
            <v-col cols="12" class="ma-0 pa-0" >
              <h3 class="vf-info vf-info-1">
                {{ $t("message.payment.errorsEdit") }}:
              </h3>
              <p class="vf-info">{{ $t("message.currency.TitleRateInfo2") }}</p>
              <p class="vf-info">{{ $t("message.currency.TitleRateInfo3") }}</p> 
            </v-col>
          </v-row>
        </transition>
        <v-form id="form-udate" ref="pform" v-model="formValid" action="#" validate-on="blur" @submit="() => false">
          <v-row class="mx-0 my-4 pa-0" v-if="form.data.transaction">
            <v-col cols="4" class="ma-0 pa-0 text-right" >
              <h4>{{ $t('message.payment.type') }}</h4>
            </v-col>
            <v-spacer/>
            <v-col cols="7" class="ma-0 pa-0" >
              {{ form.data.transaction.text }}
            </v-col>
            <v-col cols="4" class="ma-0 pa-0 text-right" >
              <h4>{{ $t('message.payment.payer') }}</h4>
            </v-col>
            <v-spacer/>
            <v-col cols="7" class="ma-0 pa-0" >
              {{ payers[form.data.payer-1].text }}
            </v-col>
            <template v-if="form.data.transaction.type == 3">
              <v-col cols="4" class="ma-0 pa-0 text-right" >
                <h4>{{ $t('message.payment.receiver') }}</h4>
              </v-col>
              <v-spacer/>
              <v-col cols="7" class="ma-0 pa-0" >
                {{ payers[form.data.receiver-1].text }}
              </v-col>
            </template>
          </v-row>
          <v-row class="ma-0 pa-0" v-if="form.data.transaction">
            <v-col cols="5" class="ma-0 pa-0">
              <v-text-field
                dense
                type="number"
                v-model="form.data.amount"
                :label="$t('message.amount')"
                :rules="[validationRules().required, validationRules().number]"
                hide-details="auto"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-spacer/>
            <v-col cols="6" class="ma-0 pa-0">
              <v-select
                dense
                class="ma-0 pa-0"
                :items="mints"
                :rules="[validationRules().required]"
                :hint="Math.round(form.data.amount) > 0 ? amountFinal : rateSample"
                :label="$t('message.navbar.currency')"
                v-model="form.data.currency"
                :value="currencys[0].id"
                outlined
                persistent-hint
                return-object
                single-line
                @input="setRate"
              >
              </v-select>
            </v-col>

            <v-col cols="5" class="ma-0 pa-0">
              <v-text-field
                dense
                type="number"
                v-model="form.data.rate"
                :label="$t('message.currency.rateIn') + '\xa0' + currencys[currencys[0].id].name"
                :rules="[validationRules().required, validationRules().number]"
                hide-details="auto"
                outlined
                :disabled="currencys[0].id == form.data.currency.value"
                :append-icon="((currencys[0].id != form.data.currency.value) && currencys[form.data.currency.value].forex) ? 'mdi-chart-line' : ''"                    
                @click:append="forex = !forex"
              >
              </v-text-field>
            </v-col>
            <v-spacer/>
            <v-col cols="6" class="ma-0 pa-0">
              <v-select
                class="my-0"
                @input="iconSelect"
                :readonly="form.data.transaction.iconFixed"
                dense
                :items="iconLabels"
                :label="$t('message.payment.icon')"
                v-model="form.data.icon"
                :rules="[]"
                outlined
              >
                <template slot="selection" slot-scope="data">
                  <v-icon color="primary">{{ data.item.icon }}</v-icon
                  ><!-- &nbsp; {{ data.item.text }}-->
                </template>
                <template slot="item" slot-scope="data">
                  <v-icon color="primary">{{ data.item.icon }}</v-icon
                  ><!-- &nbsp; {{ data.item.text }}-->
                </template>
              </v-select>
            </v-col>
          </v-row>
          <transition name="fade">
            <v-row class="ma-0 mb-2 pa-0" v-if="forex && (form.data.currency.value != currencys[0].id) && form.data.transaction">              
              <v-col cols="5" class="ma-0 mb-4 pa-0 pl-2 text-right">
                {{ $t('message.currency.rateSourceMaster') }}:<br/><b class="primary--text">{{ currencys[form.data.currency.value].rate }}</b>
              </v-col>
              <v-spacer/>
              <v-col cols="6" class="ma-0 mt-2 mb-4 pa-0 text-left">
                <v-btn small color="primary" @click.stop="setByRate(form.data.currency.value)">
                  {{ $t('message.apply') }}
                </v-btn>
              </v-col> 
              <v-col cols="5" v-if="currencys[form.data.currency.value].forex" class="ma-0 mb-4 pa-0 pl-2 text-right">
                {{ $t('message.currency.rateSourceForex') }}:<br/><b class="primary--text">{{ currencys[form.data.currency.value].forex }}</b>
              </v-col>
              <v-spacer/>
              <v-col cols="6" v-if="currencys[form.data.currency.value].forex" class="ma-0 mt-2 mb-4 pa-0 text-left">
                <v-btn small color="primary" @click.stop="setForex(form.data.currency.value)">
                  {{ $t('message.apply') }}
                </v-btn>
              </v-col> 
            </v-row>
          </transition>
          <v-row class="ma-0 pa-0" v-if="false">
            <v-col cols="12" class="ma-0 pa-0">
              <p class="mt-0 px-3"
                v-if="form.data.currency.value && (form.data.currency.value != currencys[0].id)">
                <v-icon color="blue">mdi-information</v-icon>
                {{ $t("message.payment.actualRates") }}
              </p>
              <p class="mt-0 px-3"
                v-if="payment.type == 999">
                <v-icon color="blue">mdi-information</v-icon>
                {{ $t("message.payment.iconPermanent") }}
              </p>              
            </v-col>
          </v-row>
          <template v-if="shareShemasAllowed">
            <v-row class="ma-0 pa-0">
              <v-col :cols="12" class="ma-0 pa-0">
                <v-select
                  class="mt-0 mx-0"
                  dense
                  :items="shareSmenas"
                  :label="$t('message.members.shareds')"
                  v-model="form.data.shema"
                  outlined
                  persistent-hint
                  @input="shareSelect"
                >
                </v-select>
              </v-col>
              <transition name="fade">
                <v-col cols="12" class="ma-0 pa-0" key="share-slider" v-if="form.data.shema == 3">
                  <template v-for="(trv, idx) in travellers">
                    <div class="mx-0 mb-0 mt-4" :key="idx" v-if="idx > 0">
                      <div class="ma-0">
                        <p v-if="trv.color" :style="'color: '+palette[trv.color-1]">{{ trv.name }}</p>
                        <p v-else :class="userColors[idx-1]+'--text'">{{ trv.name }}</p>                      
                      </div>
                      <v-slider                     
                        class="mt-n8 mb-n4"
                        style="margin-left: 30%"
                        v-model="slider[idx - 1]"
                        label=""
                        thumb-color="primary"
                        thumb-label="always"
                        thumb-size="32"
                        min="0"
                        :max="sliderMax"
                        ticks
                      ></v-slider>
                    </div>
                  </template>
                </v-col>
                <v-col key="share-list" cols="12" class="ma-0 pa-0" v-else>
                  <table class="ma-0 mb-6 pa-0">
                    <tr v-for="(item, idx) in travellers" :key="idx">
                      <td v-if="idx && item.color" class="pa-0 pt-1 text-left" :style="'color: '+palette[item.color-1]">{{ item.name }}</td>
                      <td v-if="idx && !item.color" :class="userColors[idx-1]+'--text pa-0 pt-1 text-left'">{{ item.name }}</td>
                      <td v-if="idx" style="width:100%" :class="userColors[idx-1]+'--text pa-0 pt-1 pl-2 text-right'">
                        <bar-part
                          :part="item.shema[form.data.shema]"
                          :total="totalParts"
                          :tcolor="item.color ? palette[item.color-1] : null"
                          :progress="progressBar"                          
                          :color="userColors[idx-1]">
                        </bar-part>
                      </td>                    
                    </tr>
                  </table>                   
                </v-col>
              </transition>
              <transition name="fade" class="my-0 py-0">
                <v-col cols="12" v-if="sliderIsNull" class="my-0 py-0">
                  <h4 class="mt-0 mb-4 py-0"><v-icon color="blue">mdi-alert</v-icon> {{ $t('message.payment.somebodyMust')}}</h4>
                </v-col>
              </transition>              
            </v-row>
          </template>
          <template v-else>
            <v-row v-if="shareParts.multi" class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <p v-if="form.data.transaction.type == 2" class="mt-0 pa-0">
                  <v-icon color="blue">mdi-information</v-icon>
                  {{ $t("message.payments.refundShemaTheSame") }}
                  {{ $t("message.payments.refundShemaTheSame2") }}
                </p>                
                <table class="ma-0 mb-6 pa-0">
                  <template v-for="(item, idx) in travellers">
                    <tr v-if="idx" :key="idx">
                      <td v-if="idx && item.color" class="pa-0 pt-1 text-left" :style="'color: '+palette[item.color-1]">{{ item.name }}</td>
                      <td v-if="idx && !item.color" :class="userColors[idx-1]+'--text pa-0 pt-1 text-left'">{{ item.name }}</td>
                      <td v-if="idx" style="width:100%" :class="userColors[idx-1]+'--text pa-0 pt-1 pl-2 text-right'">
                        <bar-part
                          :part="shareParts.parts[idx-1]"
                          :total="shareParts.total"
                          :tcolor="item.color ? palette[item.color-1] : null"
                          :progress="progressBar"
                          :color="userColors[idx-1]">
                        </bar-part>
                      </td>                    
                    </tr>
                  </template>
                </table>
              </v-col>
            </v-row>                         
          </template>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <v-textarea
                v-model="form.data.description"
                @input="form.data.descriptionChanged = true"
                :label="$t('message.payment.description')"
                :rules="[validationRules().required, validationRules().length]"
                hide-details="auto"
                outlined
                no-resize
                height="100px"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider class="mt-3"></v-divider>

      <v-card-actions class="ma-5 mb-0 pa-0 pb-5">
        <v-btn
          color="success"
          :disabled="!formValid || (shareShemasAllowed && sliderIsNull)"
          elevation="4"
          @click.stop="save"
        >
          {{ $t("message.save") }}
        </v-btn>        
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="4" @click.stop="closeForm">
          {{ $t("message.close") }}
        </v-btn>
      </v-card-actions>
      <div v-show="vKeyboard" style="height:20em">
      </div>      
    </v-card>
  </v-dialog>
</template>
<script>
import { defs } from "./defaults.js";
import BarPart from "./BarPart";
export default {
  name: "PaymentAlt",
  model: {
    prop: 'dialogModel',
    event: 'open'
  },  
  components: {
    BarPart     
  },  
  props: {
    payment: { type: Object },
    id: { type: Number },
    travellers: { type: Array },
    currencys: { type: Array },
    lbls: { type: Array },
    shareds: { type: Array },
    transactions: { type: Array },
    transactionsRefund: { type: Array },
    vKeyboard: { type: Boolean },
    dialogModel: { type: Number },
    userColors: { type: Array }
  },
  data: () => {
    return {
      formValid: false,
      form: {
        data: {
          idx: null,
          icon: null,
          transaction: 0,
          payer: 0,
          receiver: 0,
          amount: 0,
          currency: 0,
          rate: 0,
          shema: 0,
          shemas: [],
          description: "",
          descriptionChanged: false
        },
      },
      slider: [],
      vfInfo: false,
      dialog: false,
      sliderMax: 10,
      listenerUpdate: false,
      palette: defs.colorPalette.colors,      
      forex: false,
      progressBarTimer: null,
      progressBar: 1       
    };
  },
  updated() {
    if(!this.listenerUpdate) {
      document.getElementById('form-udate')?.addEventListener('submit', (evt) => {
        evt.preventDefault();
        this.listenerUpdate = true;
        return false;
      }, false);
    }
  },  
  computed: {
    shareParts() {
      let parts = {
        multi: false,
        total: 0,
        shareds: 0,
        parts: [],
      };
      if(this.form.data.shemas) {
        this.form.data.shemas.forEach((prt) => {
          if(prt) {
            parts.total += prt;
            parts.shareds ++;
          }
        });
        parts.parts = this.form.data.shemas;
      } else {
        this.travellers.forEach((trv, idx) => {
          if(idx) {
            if(trv.shema[this.form.data.shema]) {
              parts.total += trv.shema[this.form.data.shema];
              parts.shareds ++;
            }
            parts.parts[idx-1] = trv.shema[this.form.data.shema];
          }
        });
      }
      parts.multi = parts.shareds > 1 ? true : false;
      return parts;
    },    
    iconLabels() {
      let icons = [];
      this.lbls.forEach((label) => {
        if(this.form.data?.transaction?.iconFlag & label.flags) {
          icons.push(label);
        }
      });
      return icons;
    },    
    totalParts() {
      let total = 0;
      this.travellers.forEach((trv, idx) => {
        if(idx) {
          total += trv.shema[this.form.data.shema];
        }
      });
      return total;
    },    
    sliderIsNull() {
      let result = true;
      this.slider.forEach((sld) => {
        if(sld > 0) {
          result = false;
        }
      });
      return result;
    },    
    shareShemasAllowed() {
      return (
        (typeof this.form.data.transaction.shema == 'undefined') && 
        (this.form.data.transaction.type == 1)) ? true : false;
    },
    shareSmenas() {
      let list = structuredClone(this.shareds);
      list.forEach((shr, idx) => {
        shr.text = this.travellers[0].shema[idx];
      });
      list.push({
        text: this.$t("message.members.free"),
        value: 3,
      });
      return list;
    },
    payers() {
      let payers = [];
      this.travellers.forEach((trv, idx) => {
        if (trv.allowed) {
          payers.push({ text: trv.name, value: idx });
        }
      });
      return payers;
    },
    receivers() {
      let receivers = [];
      this.payers.forEach((rcv) => {
        if (rcv.value != this.form.data.payer) {
          receivers.push(rcv);
        }
      });
      return receivers;
    },
    mints() {
      let mints = [];
      this.currencys.forEach((mnt, idx) => {
        if (mnt.allowed) {
          mints.push({ text: mnt.name, value: idx, rate: mnt.rate });
        }
      });
      return mints;
    },
    rateSample() {
      if (
        this.form.data.currency.value &&
        this.form.data.currency.value != this.currencys[0].id
      ) {
        return (
          "1 " +
          this.form.data.currency.text +
          " = " +
          this.currencys[this.form.data.currency.value].rate +
          " " +
          this.currencys[this.currencys[0].id].name
        );
      }
      return "";
    },
    amountFinal() {
      if (
        this.form.data.currency.value &&
        this.form.data.currency.value != this.currencys[0].id
      ) {
        return (
          (
            this.form.data.amount *
            this.currencys[this.form.data.currency.value].rate
          ).toFixed(2) +
          " " +
          this.currencys[this.currencys[0].id].name
        );
      }
      return "";
    },
  },
  methods: {
    startProgressBar() {
      if(this.progressBarTimer) {
        window.clearInterval(this.progressBarTimer);
      }
      this.progressBar = 0;
      this.progressBarTimer = window.setInterval(() => {
        if(this.progressBar < 0.99) {
          this.progressBar += 0.025;
        } else {
          window.clearInterval(this.progressBarTimer);
          this.progressBarTimer = null;
          this.progressBar = 1;
        }
      }, 25);
    },    
    parseSplit(txt, idx) {
      let parts = txt.split('$');
      if(parts.length < (idx+1)) {
        parts[idx] = '*';
      } 
      return parts[idx];
    },    
    setForex(idx) {
      this.form.data.rate = this.currencys[idx].forex;
      this.forex = 0; 
    },
    setByRate(idx) {
      this.form.data.rate = this.currencys[idx].rate;
      this.forex = 0; 
    },    
    validationRules() {
      return {
        required: (value) => !!value || this.$t("message.validation.required"),
        number: (value) =>
          (parseFloat(value) > 0) || this.$t("message.validation.numberPos0"),
        length: (value) =>
          (value && value.length > 2) || this.$t("message.validation.length3")
      };
    },

    init() {  
      this.vfInfo = false;
      this.forex = false;
      let shema = 0;      
      let receiver = 0;
      let transaction = 0;
      if(this.payment.type == 3) {      // receiver id for transfer
        this.travellers.forEach((traveller,id) => {
          if(this.payment.shemas[id-1] > 0) {
            receiver = id;
          }
        });
      }
      if(typeof (this.payment.shema) != 'undefined') {        // shema
        shema = parseInt(this.payment.shema);
      }
      if(shema == 3) {                                         // shemas
        this.slider = structuredClone(this.payment.shemas);
      } else {
        this.slider = new Array(this.travellers.length - 1);
        this.slider.fill(1);            
      }
      if(this.payment.type == 2) {                            // refund transaction
        this.transactionsRefund.forEach( (tr, idx) => {
          if(tr.value == this.payment.subtype) {
            transaction = this.transactionsRefund[idx];
          }
        });
      } else {                                                // direct transaction
        this.transactions.forEach( (tr, idx) => {
          if(tr.value == this.payment.subtype) {
            transaction = this.transactions[idx];
          }
        });
      }
      if(!transaction) {
        console.log('Invalig transaction edit');
        this.dialog = false;        
      }
      this.form = {
        data: {
          icon: this.payment.icon ? this.payment.icon : 11,
          transaction: transaction,
          payer: this.payment.payer,
          receiver: receiver,
          amount: Math.abs(this.payment.amount),
          rate: this.payment.rate,
          currency: {
            text: this.currencys[this.payment.currency].name,
            value: this.payment.currency,
            rate: this.payment.rate,
          },
          shema: shema,
          shemas: structuredClone(this.payment.shemas),
          description: this.payment.memo,
        },
      };
    },

    save() {
      let shema = this.form.data.shema;
      let shemas = (this.form.data.shema == 3) ? structuredClone(this.slider) : null;
      if(typeof this.form.data.transaction.shema != 'undefined') {
        let pshema = this.form.data.transaction.shema;
        if(pshema >= 0) {
          shema = pshema;
        } else {
          shema = 3;
          shemas = new Array(this.travellers.length - 1);
          shemas.fill(0);          
          switch(pshema) {
            case -1:      // перевод
              shemas[this.form.data.receiver-1] = 1;
              break;
            case -2:      // личная покупка
              shemas[this.form.data.payer-1] = 1;
              break;                 
          }
        }
      }
      this.$emit("save", {
        id: this.id,
        type: this.form.data.transaction.type,
        subtype: this.form.data.transaction.value,
        icon: this.form.data.icon,        
        payer: this.form.data.payer,
        amount: this.form.data.amount,
        currency: this.form.data.currency.value,
        rate: this.form.data.rate,
        shema: parseInt(shema),
        shemas: structuredClone(shemas),
        description: this.form.data.description.trim()
      });
      this.vfInfo = false;
      this.$emit("open",0);
    },
    closeForm() {
      this.vfInfo = false;
      this.$emit("open",0);
      this.$refs.pform.resetValidation();
    },
    setRate() {
      this.form.data.rate = this.form.data.currency.rate;
      this.forex = false;
      // console.log('Rate '+this.form.data.currency.rate);
    }, 
    shareSelect() {
      this.form.data.linkTo = false;
      if(typeof (this.travellers[0].links[this.form.data.icon]) != 'undefined') {   // С этой иконкой есть связь на схему
        let links = structuredClone(this.travellers[0].links[this.form.data.icon]);
        if(typeof links == 'object') {                                // Произвольная - сравнить
          if(this.form.data.shema == 3) {
            this.slider = structuredClone(links);
            this.form.data.linkTo = true;
          }
        } else if(this.form.data.shema == links) {                    // Фиксированная
          this.form.data.linkTo = true;
        }
      }
      this.startProgressBar();
    },
    typeSelect() {
      this.form.data.icon = this.form.data.transaction.icon;
      this.iconSelect();
    },
    iconSelect() {
      this.form.data.shema = 0;
      if (!this.form.data.descriptionChanged ||
        !this.form.data.description.length) {       // Корректируем описание под иконку, если оно еще нетронутое
        this.form.data.description =
          this.lbls[this.form.data.icon - 1].text + ". ";
        this.form.data.descriptionChanged = false;
      } 
      if(typeof (this.travellers[0].links[this.form.data.icon]) != 'undefined') {   // С этой иконкой есть связь на схему
        let links = (this.travellers[0].links[this.form.data.icon]);
        if(typeof links == 'object') {                                // Произвольная
          this.form.data.shema = 3;
          this.slider = structuredClone(links);
        } else {                                                      // Фиксированная
          this.form.data.shema = links;
        }
        this.form.data.linkTo = true;
      } else {
        this.form.data.linkTo = false;                                // Нет связи
      }
    },
    toggleInfo() {
      this.vfInfo = !this.vfInfo;
    }
  },
  watch: {
    dialogModel: {
      handler(value) {
        if(value) {
          this.startProgressBar();
          this.init();
        }
        this.dialog = !!value;
      }
    },
    dialog: function () {
      if (this.formValid) {
        this.$refs.pform.resetValidation();
      }
    }
  }
};
</script>
<style scoped>
.vf-add-payment {
  color: white;
  font-size: 1.1em !important;  
  font-weight: bold;  
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.fade-move {
  transition: transform 1s;
}
p.vf-info {
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0;
}
h3.vf-info {
  font-size: 1em;
  margin: 0.3em 0 0 0;
  text-decoration: underline;
}
.vf-info-1 {
  margin-top: 1em !important;
}
.vf-info-2 {
  margin-top: 1em !important;
}
.line-compact {
  line-height: 0.9em !important;
}
</style>
