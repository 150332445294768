<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card v-if="pay">
      <v-card-title class="primary vf-add-payment px-5">
        {{ $t("message.payment.refund") }}
        <v-spacer />
        <v-icon @click.stop="vfInfo = !vfInfo" :color="vfInfo ? 'amber' : 'white'">
          mdi-information-outline
        </v-icon>
      </v-card-title>
      <v-card-text class="px-5">
        <transition name="fade">
          <v-row class="ma-0 pa-0" v-if="vfInfo">
            <v-col cols="12" class="ma-0 pa-0" >
              <h3 class="vf-info vf-info-1">
                {{ $t("message.payment.receiver") }}:
              </h3>
              <p class="vf-info">{{ $t("message.payment.receiverNoSame") }}</p>            
              <h3 class="vf-info">
                {{ $t("message.amount") }}:
              </h3>
              <p class="vf-info">{{ $t("message.payment.amountInfo") }}</p>
              <h3 class="vf-info">
                {{ $t("message.navbar.currency") }}:
              </h3>
              <p class="vf-info">{{ $t("message.payment.currencyInfo") }}</p>
              <h3 class="vf-info">
                {{ $t("message.payment.shareds") }}:
              </h3>
              <p class="vf-info">{{ $t("message.payments.refundShemaTheSame") }}</p>
            </v-col>
          </v-row>
        </transition>
        <v-form id="form-udate" ref="pform" v-model="formValid" action="#" validate-on="blur" @submit="() => false">
          <v-row class="ma-0 pa-0">
            <v-col cols="3" class="mt-8 pa-0">
              <v-select
                @input="iconSelect"
                dense
                :items="iconLabels"
                :label="$t('message.payment.icon')"
                v-model="form.data.icon"
                :readonly="refund.iconFixed"
                :rules="[]"
                outlined
              >
                <template slot="selection" slot-scope="data">
                  <v-icon color="primary">{{ data.item.icon }}</v-icon><!-- {{ data.item.text }}-->
                </template>
                <template slot="item" slot-scope="data">
                  <v-icon color="primary">{{ data.item.icon }}</v-icon><!-- {{ data.item.text }}-->
                </template>
              </v-select>
            </v-col>
            <v-spacer />            
            <v-col cols="8" class="mt-8 pa-0">
              <v-select
                v-if="refunds"
                @input="typeSelect"
                class="my-0"
                dense
                :items="refunds"
                :label="$t('message.payments.refundSelection')"
                v-model="refund"
                outlined
                return-object>
              </v-select>   
              <h3 v-else>      
                <!--<v-icon large color="primary" v-if="false">{{ lbls[refund.icon-1].icon }}</v-icon> &nbsp;   -->
                {{ refund.text }} ({{ pay.memo}})
              </h3>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <p>
                <v-icon color="blue">mdi-information</v-icon>
                <vf-expantion-text 
                  v-model="expantionText"
                  :text="refund.info"
                  :length="47"
                  :from-length="700"
                  :option="$t('message.more')"
                  exp-class="primary--text">
                </vf-expantion-text>
              </p>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-select
                dense
                :items="payers"
                :label="$t('message.payment.receiver')"
                v-model="form.data.payer"
                :rules="[validationRules().required]"
                outlined
                :readonly="!(flag & flags.writeAll)"
              >
              </v-select>
            </v-col>
            <v-col cols="5" class="ma-0 pa-0">
              <v-text-field
                dense
                type="number"
                v-model="form.data.amount"
                :label="$t('message.amount')"
                :rules="[validationRules().required, validationRules().number]"
                hide-details="auto"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" class="ma-0 pa-0">
              <v-select
                @input="currencySelect"
                dense
                :items="mints"
                :rules="[validationRules().required]"
                :hint="Math.round(form.data.amount) > 0 ? amountFinal : rateSample"
                :label="$t('message.navbar.currency')"
                v-model="form.data.currency"
                :value="currencys[0].id"
                outlined
                persistent-hint
                return-object
                single-line
              >
              </v-select>
            </v-col>      
          </v-row>
          <transition name="fade">
            <v-row v-if="form.data.currency.value != currencys[0].id" class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <v-radio-group v-model="rateFrom" class="ma-0 pa-0">
                  <template v-slot:label>
                    <div>{{ parseSplit($t('message.currency.rateSource'),0) }} {{form.data.currency.text}} {{ parseSplit($t('message.currency.rateSource'),1) }}:</div>
                  </template>
                  <v-radio :value="0" class="ma-0 my-2 pa-0">
                    <template v-slot:label>
                      <div><b class="primary--text">{{ currencys[form.data.currency.value].rate }}</b> ({{ $t('message.currency.rateSourceMaster') }})</div>
                    </template>
                  </v-radio>
                  <v-radio :value="1" v-if="currencys[form.data.currency.value].forex" class="ma-0 my-2 pa-0">
                    <template v-slot:label>
                      <div><b class="primary--text">{{ currencys[form.data.currency.value].forex }}</b> ({{ $t('message.currency.rateSourceForex') }})</div>
                    </template>
                  </v-radio>
                  <v-radio :value="2" class="ma-0 my-2 pa-0">
                    <template v-slot:label>
                      <div>{{ $t('message.currency.rateSourceCustom') }}</div>
                    </template>
                  </v-radio>              
                </v-radio-group>
              </v-col>
              <transition name="fade">
                <v-col v-if="rateFrom == 2" cols="12" class="ma-0 mb-6 pa-0">
                  <v-col cols="5" class="ma-0 pa-0">
                    <v-text-field
                      class="ma-0 pa-0"
                      dense
                      type="number"
                      v-model="form.data.rate"
                      :label="$t('message.currency.rate')+' '+form.data.currency.text"
                      :rules="[validationRules().required, validationRules().number]"
                      hide-details="auto"
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-col>
              </transition>
            </v-row>
          </transition>
          <v-row v-if="shareParts.multi" class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <p class="mt-0 pa-0">
                <v-icon color="blue">mdi-information</v-icon>
                {{ $t("message.payments.refundShemaTheSame") }}
                {{ $t("message.payments.refundShemaTheSame2") }}
              </p>
              <table class="ma-0 mb-6 pa-0">
                <template v-for="(item, idx) in travellers">
                  <tr v-if="idx" :key="idx">
                    <td v-if="idx && item.color" class="pa-0 pt-1 text-left" :style="'color: '+palette[item.color-1]">{{ item.name }}</td>
                    <td v-if="idx && !item.color" :class="userColors[idx-1]+'--text pa-0 pt-1 text-left'">{{ item.name }}</td>
                    <td v-if="idx" style="width:100%" :class="userColors[idx-1]+'--text pa-0 pt-1 pl-2 text-right'">
                      <bar-part
                        :part="shareParts.parts[idx-1]"
                        :total="shareParts.total"
                        :tcolor="item.color ? palette[item.color-1] : null"
                        :progress="progressBar"
                        :color="userColors[idx-1]">
                      </bar-part>
                    </td>                    
                  </tr>
                </template>
              </table>
            </v-col>
          </v-row>              
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <v-textarea
                v-model="form.data.description"
                @input="form.data.descriptionChanged = true"
                :label="$t('message.payment.description')"
                :rules="[validationRules().required, validationRules().length]"
                hide-details="auto"
                outlined
                no-resize
                height="100px"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider class="mt-3"></v-divider>

      <v-card-actions class="ma-5 mb-0 pa-0 pb-5">
        <v-btn
          color="success"
          :disabled="!formValid"
          elevation="4"
          @click.stop="save"
        >
          {{ $t("message.save") }}
        </v-btn>        
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="4" @click.stop="closeForm">
          {{ $t("message.close") }}
        </v-btn>
      </v-card-actions>
      <div v-show="vKeyboard" style="height:20em">
      </div>      
    </v-card>
  </v-dialog>
</template>
<script>
import { defs } from "./defaults.js";
import VfExpantionText from "./VfExpantionText";
import BarPart from "./BarPart";
export default {
  name: "PaymentRefund",
  model: {
    prop: 'dialogModel',
    event: 'open'
  },   
  components: {
    VfExpantionText,
    BarPart     
  },
  props: {
    travellers: { type: Array },
    currencys: { type: Array },
    lbls: { type: Array },
    shareds: { type: Array },
    pay: { type: Object },
    transactionsRefund: { type: Array },
    vKeyboard: { type: Boolean },
    dialogModel: { type: Number },
    flag: { type: Number },
    userColors: { type: Array }
  },
  data: () => {
    return {
      formValid: false,
      rateFrom: 0,
      form: {
        data: {
          icon: null,
          transaction: {},
          payer: 0,
          receiver: 0,
          amount: 0,
          currency: {
            value: 0
          },
          shema: 0,
          shemas: [],
          description: "",
          descriptionChanged: false,
          linkTo: false,
        },
      },
      expantionText: false,
      vfInfo: false,
      dialog: false,
      refund: { info : "" },
      refunds: null,
      // getRefund: [0, [0, 1], [3, 4], -1, -1, -1, 2, 2],    // pay -> refund transactions
      flags: defs.slaveFlags,
      palette: defs.colorPalette.colors, 
      listenerUpdate: false,
      progressBarTimer: null,
      progressBar: 1       
    };
  },
  updated() {
    if(!this.listenerUpdate) {
      document.getElementById('form-udate')?.addEventListener('submit', (evt) => {
        evt.preventDefault();
        this.listenerUpdate = true;
        return false;
      }, false);
    }
  },  
  computed: {
    iconLabels() {
      let icons = [];
      this.lbls.forEach((label) => {
        if(this.refund?.iconFlag & label.flags) {
          icons.push(label);
        }
      });
      return icons;
    }, 
    shareParts() {
      let parts = {
        multi: false,
        total: 0,
        shareds: 0,
        parts: [],
      };
      if(this.form.data.shemas) {
        this.form.data.shemas.forEach((prt) => {
          if(prt) {
            parts.total += prt;
            parts.shareds ++;
          }
        });
        parts.parts = this.form.data.shemas;
      } else {
        this.travellers.forEach((trv, idx) => {
          if(idx) {
            if(trv.shema[this.form.data.shema]) {
              parts.total += trv.shema[this.form.data.shema];
              parts.shareds ++;
            }
            parts.parts[idx-1] = trv.shema[this.form.data.shema];
          }
        });
      }
      parts.multi = parts.shareds > 1 ? true : false;
      return parts;
    }, 
    payers() {
      let payers = [];
      this.travellers.forEach((trv, idx) => {
        if (trv.allowed) {
          payers.push({ text: trv.name, value: idx });
        }
      });
      return payers;
    },
    mints() {
      let mints = [];
      this.currencys.forEach((mnt, idx) => {
        if (mnt.allowed) {
          mints.push({ text: mnt.name, value: idx });
        }
      });
      return mints;
    },
    actualRate() {
      let rate = 1;
      switch(this.rateFrom) {
        case 0:
          rate = this.currencys[this.form.data.currency.value].rate;
          break;
        case 1:
          rate = this.currencys[this.form.data.currency.value].forex;
          break;
        case 2:
          rate = this.form.data.rate;
          break;          
      }
      return rate;
    },    
    rateSample() {
      if (
        this.form.data.currency.value &&
        this.form.data.currency.value != this.currencys[0].id
      ) {
        return (
          "1 " +
          this.form.data.currency.text +
          " = " + this.actualRate +
          // this.currencys[this.form.data.currency.value].rate +
          " " +
          this.currencys[this.currencys[0].id].name
        );
      }
      return "";
    },
    amountFinal() {
      if (
        this.form.data.currency.value &&
        this.form.data.currency.value != this.currencys[0].id
      ) {
        return (
          (
            this.form.data.amount * this.actualRate
            // this.currencys[this.form.data.currency.value].rate
          ).toFixed(2) +
          " " +
          this.currencys[this.currencys[0].id].name
        );
      }
      return "";
    },
  },
  methods: {
    startProgressBar() {
      if(this.progressBarTimer) {
        window.clearInterval(this.progressBarTimer);
      }
      this.progressBar = 0;
      this.progressBarTimer = window.setInterval(() => {
        if(this.progressBar < 0.99) {
          this.progressBar += 0.025;
        } else {
          window.clearInterval(this.progressBarTimer);
          this.progressBarTimer = null;
          this.progressBar = 1;
        }
      }, 25);
    },    
    parseSplit(txt, idx) {
      let parts = txt.split('$');
      if(parts.length < (idx+1)) {
        parts[idx] = '*';
      } 
      return parts[idx];
    },
    currencySelect() {
      this.rateFrom = 0;
      this.form.data.rate = this.currencys[this.form.data.currency.value].rate;
    },    
    validationRules() {
      return {
        required: (value) => !!value || this.$t("message.validation.required"),
        number: (value) =>
          (parseFloat(value) > 0) || this.$t("message.validation.numberPos0"),
        length: (value) =>
          (value && value.length > 2) || this.$t("message.validation.length3"),
      };
    },
    init() {
      this.vfInfo = false;
      this.expantionText = false;  
      this.rateFrom = 0;    
      if(this.pay) {
        let x = this.pay;
        if(typeof this.pay.subtype == 'undefined') {
          x.subtype = 1;
          this.dialog = true;
        }
        else {
          console.log('Refund creation: ' + this.pay.subtype);
          console.log(JSON.stringify(this.pay));
          // let rf = this.getRefund[this.pay.subtype-1];
          let rf = this.pay?.transaction?.refundTransaction;
          if(typeof rf == 'object') {   // есть выбор альтернативных refund
            this.refunds = [];
            rf.forEach( (r) => {
              this.refunds.push(this.transactionsRefund[r]);
            });
            this.refund = this.refunds[0];
            this.dialog = true;
          } else if (rf != null) {
            this.refunds = null;
            this.refund = this.transactionsRefund[rf];
            this.dialog = true;
          } else {
            console.log('Invalig refund creation');
            this.dialog = false;            
          }
          if(this.dialog) {
            this.form = {
              data: {
                // icon: this.pay.icon ? this.pay.icon : this.refund.icon,
                icon: this.refund.iconInherited ? this.pay.icon : this.refund.icon,
                transaction: this.pay.transaction,
                payer: this.pay.payer,
                receiver: 0,
                amount: this.pay.amount,
                currency: {
                  text: this.currencys[this.pay.currency].name,
                  value: this.pay.currency,
                },
                rate: this.currencys[this.pay.currency].rate,
                shema: parseInt(this.pay.shema),
                shemas: structuredClone(this.pay.shemas),
                description: this.pay.memo,
                descriptionChanged: false,
                linkTo: false,
              },
            };
          }
        }
      }
    },

    save() {
      this.$emit("save", {
        type: 2,
        subtype: this.refund.value,
        icon: this.form.data.icon,
        payer: this.form.data.payer,
        rate: this.actualRate,
        receiver: 0,
        amount: this.form.data.amount,
        currency: this.form.data.currency.value,
        shemaLink: this.refund.refundLink ? this.pay.id : -1,
        shema: parseInt(this.pay.shema),
        shemas: structuredClone(this.pay.shemas),
        setLink: null,
        description: this.form.data.description.trim()
      });
      this.closeForm();
    },
    closeForm() {
      if(this.dialog) {
        this.clearForm();
      }
      this.$emit("open",0);
    },
    clearForm() {
      this.init();
      this.$refs.pform.resetValidation();
    },
    typeSelect() {    // Переключает иконку при выборе типа платежа
      // this.form.data.icon = this.refund.icon;
      this.form.data.icon = this.refund.iconInherited ? this.pay.icon : this.refund.icon,
      this.iconSelect();
    },    
    iconSelect() {
      if (!this.form.data.descriptionChanged ||
      !this.form.data.description.length) {
        this.form.data.description = this.lbls[this.form.data.icon - 1].text + ". ";
        this.form.data.descriptionChanged = false;
      }
    },
    toggleInfo() {
      this.vfInfo = !this.vfInfo;
    }
  },
  watch: {
    dialogModel: {
      handler(value) {
        if(value) {
          this.init();
          this.startProgressBar();
        } else {
          this.dialog = false; // !!value;
        }
      }      
    },     
    dialog: {
      handler() {
        if (this.formValid) {
          // this.$refs.pform.reset(); // Validation();
          this.$refs.pform.resetValidation();
        }
      }
    }        
  }
};
</script>
<style scoped>
th.vf-name {
  font-size: 1.1em !important;
}
.vf-add-payment {
  color: white;
  font-size: 1.1em !important;  
  font-weight: bold;  
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.fade-move {
  transition: transform 1s;
}
p.vf-info {
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0;
}
h3.vf-info {
  font-size: 1em;
  margin: 0.3em 0 0 0;
  text-decoration: underline;
}
.vf-info-1 {
  margin-top: 1em !important;
}
.vf-info-2 {
  margin-top: 1em !important;
}
.line-compact {
  line-height: 0.9em !important;
}
</style>
