<template>
    <div :class="fillColor+' ma-0 mb-1 rounded'" 
    :style="'border: 0; padding: 3px; font-size: 4px; position: relative; height: 24px; line-height: 16px; max-width: '+maxWidth+'px; opacity: '+progress">
      <div v-if="tcolor" class="rounded ma-0 pa-0 text-center" 
      :style="'font-size: 4px; background-color: '+tcolor+'; height: 18px; width: '+(Math.sqrt(progress) * selfPart)+'%; opacity: '+progress">&nbsp;</div>
      <div v-else :class="color+' ma-0 pa-0 rounded text-center'" 
      :style="'font-size: 4px; height: 18px; width: '+(Math.sqrt(progress) * selfPart)+'%'">&nbsp;</div>
      <div class="text-center" style="font-size: 4px; position: absolute; left: 0; top: 0; width: 100%; height: 18px; padding: 3px">
        <transition name="fade">
          <div v-if="progress == 1" class="rounded black--text font-weight-bold ma-0" 
            style="font-size: 13px; padding: 2px 4px 0 4px; display: inline-block; height: 18px; background-color: rgba(255,255,255,0.6)">
            {{share ? share : 0}}
          </div>
        </transition>
      </div>      
    </div>
</template>
<script>
export default {
  name: "BarPart",
  props: {
    part: { type: Number },
    total: { type: Number, default: 0 },
    color: { type: String },
    tcolor: { type: String, default: null },
    fillColor: { type: String, default: 'blue lighten-4' },
    maxWidth: { type: Number, default: 700 },
    progress: {type: Number, default: 1},
  },  
  data: () => {
    return { };
  },
  computed: {
    parts() {
      return this.total ? this.total : this.part;
    },
    selfPart() {
      return this.total ? Math.ceil(100 * this.part / this.total) : 100;
    },
    share() {
      return (this.selfPart > 0) ? (this.part+' / '+this.parts) : '';
    }
  }
};
</script>
<style scoped>
.line-compact {
  line-height: 0.9em !important;
  margin: 0 !important;
}
.fade-enter-active {
  transition: opacity 0.5s ease;
}
.fade-leave-active {
  transition: opacity 0s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
