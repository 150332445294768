<template>
  <div>
    <table cellspacing="4px" class="vf-bl" v-if="mode == 1">
      <tr>
        <th class="vf-bl">{{ $t("message.name") }}</th>
        <th class="vf-bl">{{ $t("message.payment.incomingBalance") }}</th>
        <th class="vf-bl">{{ $t("message.payment.debet_") }}</th>
        <th class="vf-bl">{{ $t("message.payment.credit_") }}</th>
        <th class="vf-bl">{{ $t("message.payment.outgoingBalance") }}</th>
      </tr>
      <template v-for="(item, idx) in payment.balance.names">
        <tr v-if="payment.balance.creditN[idx] || payment.balance.debetN[idx]" :key="idx">
          <td :class="(payment.balance.tcolor[idx] ? '' : (payment.balance.color[idx] + '--text'))+' vf-blx'"
            :style="payment.balance.tcolor[idx] ? 'color: '+payment.balance.tcolor[idx] : ''">
            <span class="vf-fbl">{{ item }}</span><br />
            <bar-part
              v-if="payment.balance.shemaPart[idx].parts > 1"
              :part="payment.balance.shemaPart[idx].part"
              :total="payment.balance.shemaPart[idx].parts"
              :tcolor="payment.balance.tcolor[idx]"
              :progress="progressBar"
              :color="payment.balance.color[idx]">
            </bar-part>
          </td>
          <template v-if="payment.balance.tcolor[idx]">
            <td class="vf-bl" :style="'color: '+payment.balance.tcolor[idx]">
              {{ payment.balance.in[idx] }}
            </td>
            <td class="vf-bl" :style="'color: '+payment.balance.tcolor[idx]">
              {{ payment.balance.debetN[idx] ? (payment.balance.debetN[idx].toFixed(2) + "\xa0" + balanceCurrency) : ''}}
            </td>
            <td class="vf-bl" :style="'color: '+payment.balance.tcolor[idx]">
              {{ payment.balance.creditN[idx] ? (payment.balance.creditN[idx].toFixed(2) + "\xa0" + balanceCurrency) : '' }}
            </td>
            <td class="vf-bl" :style="'color: '+payment.balance.tcolor[idx]">
              {{ payment.balance.out[idx] }}
            </td>
          </template>
          <template v-else>
            <td :class="payment.balance.color[idx] + '--text vf-bl'">
              {{ payment.balance.in[idx] }}
            </td>
            <td :class="payment.balance.color[idx] + '--text vf-bl'">
              {{ payment.balance.debetN[idx] ? (payment.balance.debetN[idx].toFixed(2) + "\xa0" + balanceCurrency) : ''}}
            </td>
            <td :class="payment.balance.color[idx] + '--text vf-bl'">
              {{ payment.balance.creditN[idx] ? (payment.balance.creditN[idx].toFixed(2) + "\xa0" + balanceCurrency) : '' }}
            </td>
            <td :class="payment.balance.color[idx] + '--text vf-bl'">
              {{ payment.balance.out[idx] }}
            </td>
          </template>          
        </tr>
      </template>        
    </table>
    <template v-if="mode == 2">
      <template v-for="(item, idx) in payment.balance.names">
        <table
          cellspacing="5px"
          class="vf-bl"
          :key="idx"
          v-if="payment.balance.creditN[idx] || payment.balance.debetN[idx]"
        >
            <template v-if="payment.balance.shemaPart[idx].parts > 16">
              <tr>
                <th colspan="2" :class="(payment.balance.tcolor[idx] ? '' : (payment.balance.color[idx] + '--text'))+' vf-dl vf-fbl'"
                :style="payment.balance.tcolor[idx] ? 'color: '+payment.balance.tcolor[idx] : ''">
                {{ item }}</th>
              </tr>
              <tr>
                <th colspan="2" class="text-center">
                  <bar-part
                    v-if="payment.balance.shemaPart[idx].parts > 1"
                    :part="payment.balance.shemaPart[idx].part"
                    :total="payment.balance.shemaPart[idx].parts"
                    :tcolor="payment.balance.tcolor[idx]"
                    :progress="progressBar"
                    :color="payment.balance.color[idx]"
                    fillColor="white">
                  </bar-part>             
                </th>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th :class="(payment.balance.tcolor[idx] ? '' : (payment.balance.color[idx] + '--text'))+' vf-dl vf-fbl'"
                  :style="payment.balance.tcolor[idx] ? 'color: '+payment.balance.tcolor[idx] : ''">{{ item }}</th>             
                <th class="text-center">
                  <bar-part
                    v-if="payment.balance.shemaPart[idx].parts > 1"
                    :part="payment.balance.shemaPart[idx].part"
                    :total="payment.balance.shemaPart[idx].parts"
                    :tcolor="payment.balance.tcolor[idx]"
                    :progress="progressBar"
                    :color="payment.balance.color[idx]"
                    fillColor="white">
                  </bar-part>             
                </th>              
              </tr>
            </template>
          <tr>
            <th class="vf-bl">{{ $t("message.payment.incomingBalance") }}</th>
            <td class="vf-bl black--text">{{ payment.balance.in[idx] }}</td>
          </tr>
          <tr>
            <th class="vf-bl">{{ $t("message.payment.debet_") }}</th>
            <td class="vf-bl black--text">{{ payment.balance.debetN[idx] ? (payment.balance.debetN[idx].toFixed(2) + "\xa0" + balanceCurrency) : '' }}</td>
          </tr>
          <tr>
            <th class="vf-bl">{{ $t("message.payment.credit_") }}</th>
            <td class="vf-bl black--text">{{ payment.balance.creditN[idx] ? (payment.balance.creditN[idx].toFixed(2) + "\xa0" + balanceCurrency) : '' }}</td>
          </tr>
          <tr>
            <th class="vf-bl">{{ $t("message.payment.outgoingBalance") }}</th>
            <td class="vf-bl black--text">{{ payment.balance.out[idx] }}</td>
          </tr>        
        </table>
      </template>
    </template>
    <template v-if="mode == 3">
      <table
        cellspacing="5px"
        class="vf-bl"
      >      
        <template v-for="(item, idx) in payment.balance.names">
          <tr :key="idx" v-if="(payment.balance.creditN[idx] || payment.balance.debetN[idx]) && (payment.balance.shemaPart[idx].parts > 1) && (payment.balance.shemaPart[idx].part > 0)">
            <th v-if="payment.balance.tcolor[idx]" class="text-left vf-fbl" nowrap :style="'color: '+payment.balance.tcolor[idx]+'; width: auto'">{{ item }}</th>
            <th v-else :class="payment.balance.color[idx]+'--text text-left vf-fbl'" nowrap style="width: auto">{{ item }}</th>
            <th class="text-center" style="width: 100%">
              <bar-part
                :part="payment.balance.shemaPart[idx].part"
                :total="payment.balance.shemaPart[idx].parts"
                :tcolor="payment.balance.tcolor[idx]"
                :progress="progressBar"
                :color="payment.balance.color[idx]"
                fillColor="white">
              </bar-part>             
            </th>              
          </tr>
        </template>
        
      </table>
    </template>    
  </div>
</template>
<script>
import BarPart from "./BarPart";
export default {
  name: "PaymentBalance",
  components: {
    BarPart    
  },  
  props: {
    payment: { type: Object },
    mode: { type: Number, default: 1 },
    balanceCurrency: { type: String, default: 'UAH' },
    ping: { type: Number, default: 0 }
  },
  data: () => {
    return {
      progressBarTimer: null,
      progressBar: 1      
    };
  },
  mounted() {
    this.startProgressBar();
  },
  methods: {
    startProgressBar() {
      if(this.progressBarTimer) {
        window.clearInterval(this.progressBarTimer);
      }
      this.progressBar = 0;
      this.progressBarTimer = window.setInterval(() => {
        if(this.progressBar < 0.99) {
          this.progressBar += 0.025;
        } else {
          window.clearInterval(this.progressBarTimer);
          this.progressBarTimer = null;
          this.progressBar = 1;
        }
      }, 25);
    }, 
  },
  watch: {
    ping: {
      handler(value) {
        if(value) {
          this.startProgressBar();
        }
      }
    }
  }
};
</script>
<style scoped>
table.vf-bl {
  width: 100% !important;
}

th.vf-bl {
  text-align: center !important;
  width: 20% !important;
  border: 0;
  background-color: white;
  margin: 0;
  padding: 2px 4px 2px 4px;  
  color: black;
  font-weight: normal !important;
  font-size: 0.9em;
}
td.vf-bl {
  text-align: center !important;
  width: 20% !important;
  border: 0;
  background-color: white;
  margin: 0;
  padding: 2px 4px 2px 4px;  
  font-weight: normal !important;
  font-size: 0.9em;
}
td.vf-blx {
  text-align: center !important;
  width: 20% !important;
  border: 0;
  background-color: white;
  margin: 0;
  padding: 2px 4px 2px 4px;  
  font-weight: normal !important;
}
.vf-dl {
  text-align: center !important;
  border: 0;
  margin: 0;
  padding: 2px 4px 0 4px;
}
.vf-fbl {
  font-size: 0.9em;  
}
</style>
