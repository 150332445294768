import { appVersionServer } from "./cordova.js";
export const defs = {
  locales: [
    {
      id: 0,
      text: "EN",
      value: "en",
      liqpay: "en",
      order: 2,
      valid: true
    },
    {
      id: 1,
      text: "UA",
      value: "ua",
      liqpay: "uk",
      order: 8,
      valid: true
    },
    {
      id: 2,
      text: "RU",
      value: "ru",
      liqpay: "ru",
      order: 9,
      valid: true
    },
    {
      id: 3,
      text: "ES",
      value: "es",
      liqpay: "en",
      order: 3,
      valid: false
    },    
    {
      id: 4,
      text: "DE",
      value: "de",
      liqpay: "en",
      order: 3,
      valid: false
    },
    {
      id: 5,
      text: "FR",
      value: "fr",
      liqpay: "en",
      order: 4,
      valid: false
    },
  ],
  serverUrl: 'https://www.tripcount.click/',
  aboutUrl: 'https://www.tripcount.click/about/',
  urls :{
    dev: {
      liqpayBrowser : 'https://www.tripcount.click/liqpay/id/',
      local: 'http://192.168.2.148:8080/',
      root: 'http://192.168.2.148:8080/',
      rootR: 'http://192.168.2.148:8080/',
      invite: 'http://192.168.2.148:8080/',
      localeStore: 'c:\\',
      logo: null,
      logoGP: 'https://www.tripcount.click/img/google_play.svg'
    },    
    web: {
      liqpayBrowser : 'https://www.tripcount.click/liqpay/id/',
      local:  'https://www.tripcount.click/',
      root:   'https://www.tripcount.click/',
      rootR:  'https://www.tripcount.click/reload/action/',
      invite: 'https://www.tripcount.click/android/app/arg/?m=slave',
      localeStore: 'c:\\',
      logo: null,
      logoGP: '/img/google_play.svg'
    },
    app: {
      liqpayBrowser: 'https://www.tripcount.click/liqpay/id/',
      local:  'https://www.tripcount.click/',
      root:   'https://www.tripcount.click/android'+appVersionServer+'/app/',
      rootR:  'https://www.tripcount.click/android'+appVersionServer+'r/app/',
      invite: 'https://www.tripcount.click/android/app/arg/?m=slave',
      localeStore: '///storage/emulated/0/Download/',
      logo: 'https://www.tripcount.click/img/logo.jpg',
      logoGP: 'https://www.tripcount.click/img/google_play.svg'
    }
  },
  billing: {
    appName: "ua.lafuente.tripcount",
    apiKey: "c9bd7ccc-64b3-4ea0-8ad0-11b5e91eb240",
    revenueKey: "goog_CCJJpDHAbRpgYBhkIhZvwbEfpKw"
  },
  config: {
    version: 3,
    locale: 1,
    currentTrip: 0,
    master: 0,
    key: '',
    email: '',
    page: 1,
    maxMasters: 0,
    maxSlaves: 10,
    masters: [],
    slaves: [],
    console: false,
    uuid: ''
  },
  api: {
    version: 1,
    key: "",
    id: 0,
    refresh: 5,
  },
  transactions: [
    // type 1 - можно выбрать схему или схема 0, разрешен возврат
    // type 2 - возврат
    // type 3 - есть получатель
    // type 4 - личные покупки
    {
      header: "message.payments.paymentsA",   // Заголовок группы проводок
    },
    {
      text: "message.payments.payment",       // Название
      value: 1,                               // ID транзакции
      type: 1,                                // Тип транзакции
      allowsRefund: null,                     //
      info: "message.payments.paymentInfo",   // Описание
      icon: 1,                                // Иконка по умолчанию
      iconLink: null,                         //
      iconFixed: false,                       // ? Не разрешается смена иконки
      matrix: 0,                              // Используемая матрица расчета сальдо
      shema: null,                            //
      wallet: null,                           //
      budjet: true,                           //
      banned: true,                           // Скрывать в перечне предлагаемых проводок
      iconFlag: 1,                            // Флаг для разрешенных иконок
      shopping: null,                         //
      private: false,                         // Отображает иконку to-home в списке платежей
      privateOnly: null,                      // Скрывает проводку в режиме "без учета личных расходов"     
      refundTransaction: 0                    // Возвратная транзакция
    },
    {
      text: "message.payments.payment",
      value: 2,
      type: 1,
      info: "message.payments.checkInStoreInfo",
      icon: 7,
      matrix: 0,
      budjet: true,
      iconFlag: 1,
      refundTransaction: [0, 1]
    },
    {
      header: "message.payments.paymentsB",
      wallet: true
    },
    {
      text: "message.payments.contribution",
      value: 3,
      type: 1,
      info: "message.payments.contributionInfo",
      icon: 12,
      iconFixed: true,
      shema: 0,
      matrix: 0,
      wallet: true,
      budjet: true,
      iconFlag: 8,
      refundTransaction: [3, 4]
    },
    {
      text: "message.payments.refundFromWallet",
      value: 4,
      type: 2,
      info: "message.payments.refundFromWalletInfo",
      icon: 11,
      iconLink: 12,
      iconFixed: true,
      shema: 0,
      matrix: 1,
      wallet: true,
      budjet: true,
      iconFlag: 4,
      refundTransaction: null
    },
    {
      text: "message.payments.refundFromWallet2", // no refund 
      value: 5,
      type: 2,
      info: "message.payments.refundFromWallet2Info",
      icon: 12,
      iconFixed: true,
      shema: 0,
      matrix: 6,
      wallet: true,
      budjet: true,
      iconFlag: 8,
      private: true,
      refundTransaction: null
    },
    {
      header: "message.payments.paymentsC",
    },
    {
      text: "message.payments.moneyTransfer", // no refund
      value: 6,
      type: 3,
      info: "message.payments.moneyTransferInfo",
      icon: 11,
      iconFixed: true,
      shema: -1,
      matrix: 2,
      budjet: false,
      iconFlag: 4,
      refundTransaction: null
    },
    {
      text: "message.payments.paidForOther", // no refund
      value: 7,
      type: 3,
      allowsRefund: true,
      info: "message.payments.paidForOtherInfo",
      icon: 9,
      shema: -1,
      matrix: 3,
      budjet: false,
      iconFlag: 2,
      private: true,
      refundTransaction: 2
    },
    {
      header: "message.payments.paymentsD",
      shopping: true,
      privateOnly: true,
    },
    {
      text: "message.payments.privateShopping", // refund 2
      value: 8,
      type: 4,
      allowsRefund: true,
      info: "message.payments.privateShoppingInfo",
      icon: 8,
      shema: -2,
      matrix: 4,
      shopping: true,
      budjet: false,
      iconFlag: 2,
      private: true,
      privateOnly: true,
      refundTransaction: 2
    },
    {
      text: "message.payments.privateRefund", // no refund
      value: 9,
      type: 2,
      info: "message.payments.privateRefundInfo",
      icon: 11,
      iconFixed: true,
      shema: -2,
      matrix: 5,
      shopping: true,
      budjet: false,
      iconFlag: 4,
      privateOnly: true,
      private: true,
      banned: true,
      refundTransaction: null
    },
  ],
  transactionsRefund: [
    {
      text: "message.payment.refund",             // возврат деньгами
      value: 10,
      type: 2,
      info: "message.payments.refundCommonInfo",
      icon: 11,
      iconFixed: true,
      matrix: 1,
      refundLink: true,
      budjet: true,
      iconFlag: 4,
    },
    {
      text: "message.payments.personalGoods",       // личная покупка в чеке
      value: 11,
      type: 2,
      info: "message.payments.personalGoodsInfo",
      icon: 8,
      iconInherited: true,
      iconFixed: true,
      matrix: 6,
      refundLink: true,
      budjet: false,
      private: true,
      iconFlag: 2,
    },
    {
      text: "message.payments.privateRefund",       // возврат личной покупки деньгами
      value: 9,
      type: 2,
      info: "message.payments.privateRefundInfo",
      icon: 11,
      iconInherited: false,
      iconFixed: true,
      matrix: 5,
      refundLink: true,
      shopping: true,
      budjet: false,
      iconFlag: 4,
      private: true,
      privateOnly: true,
    },
    {
      text: "message.payments.refundFromWallet",    // вывод деньгами
      value: 4,
      type: 2,
      info: "message.payments.refundFromWalletInfo",
      icon: 11,
      iconLink: 12,
      iconFixed: true,
      matrix: 1,
      wallet: true,
      budjet: true,
      iconFlag: 4,
    },
    {
      text: "message.payments.refundFromWallet2",   // личные покупки из кошелька
      value: 5,
      type: 2,
      info: "message.payments.refundFromWallet2Info",
      icon: 12,
      iconFixed: true,
      shema: 0,
      matrix: 6,
      wallet: true,
      budjet: true,
      private: true,
      iconFlag: 8,
    },
  ],
  matrix: [
    [
      // 1, 16  +доля
      // 2, 32  +100%
      // 4, 64  -доля
      // 8, 128 -100%

      // колонки: дебет плательщика, кредит плательщика, дебет остальных, кредит остальных
      // = Факт. с учетом личных покупок
      [1, 2, 1, 0], // Платеж на общие расходы +
      [4, 8, 4, 0], // * Возврат такого платежа +
      [0, 2, 0, 4], // Перевод
      [0, 2, 1, 0], // Покупка кому-то
      [2, 2, 0, 0], // Личная покупка
      [8, 8, 0, 0], // Возврат личной покупки
      [6, 0, 4, 0], // * Личная вещь в чеке
    ],
    [
      // = Факт. без учета личных покупок
      [1, 2, 1, 0], // Платеж на общие расходы +
      [4, 8, 4, 0], // * Возврат такого платежа +
      [0, 2, 0, 4], // Перевод
      [0, 2, 0, 4], // Покупка кому-то
      [0, 0, 0, 0], // Личная покупка
      [0, 0, 0, 0], // Возврат личной покупки
      [4, 8, 4, 0], // * Личная вещь в чеке
    ],
    [
      // = Таблица с учетом личных покупок (только положительные суммы, отрицательные дебет и кредит недопустимы)
      [1, 2, 1, 0], // Платеж на общие расходы +
      [2, 1, 0, 1], // * Возврат такого платежа
      [0, 2, 1, 0], // Перевод
      [0, 2, 17, 1], // Покупка кому-то 0 2 1 0
      [2, 2, 0, 0], // Личная покупка
      [2, 2, 0, 0], // Возврат личной покупки
      [34, 3, 0, 1], // * Личная вещь в чеке 2 1 0 1
    ],
    [
      // = Таблица без учета личных покупок (только положительные суммы, отрицательные дебет и кредит недопустимы)
      [1, 2, 1, 0], // Платеж на общие расходы +
      [2, 1, 0, 1], // * Возврат такого платежа +
      [0, 2, 1, 0], // Перевод
      [0, 2, 1, 0], // Покупка кому-то
      [0, 0, 0, 0], // Личная покупка
      [0, 0, 0, 0], // Возврат личной покупки
      [2, 1, 0, 1], // * Личная вещь в чеке
    ]
  ],
  travellers: [
    {
      permanent:false,
      shema: ["Main", "A", "B"],
      links: {},
    },
    {
      name: "Noname",
      shema: [1, 1, 1],
      allowed: true,
      permanent: false,
      slave: 0,
      flag: 7
    },
  ],
  currencys: [
    {
      id: 1,
    },
    {
      name: "EUR",
      rate: 1,
      allowed: true,
      permanent: true,
    },
    {
      name: "USD",
      rate: 0.94,
      allowed: true,
    },
    {
      name: "UAH",
      rate: 0.032,
      allowed: true,
    },
    {
      name: "HRK",
      rate: 0.13,
      allowed: true,
    },
    {
      name: "HUF",
      rate: 0.0026,
      allowed: true,
    },
    {
      name: "TRY",
      rate: 0.057,
      allowed: true,
    },
  ],
  // flags: 1-общие 2-персональные 4-деньги 8-кошелек
  labels: [
    {
      text: "message.labels.accomodation",
      value: 1,
      icon: "mdi-home",
      flags: 3,
    },
    {
      text: "message.labels.airTickets",
      value: 2,
      icon: "mdi-airplane-takeoff",
      flags: 3,
    },
    {
      text: "message.labels.tickets",
      value: 3,
      icon: "mdi-bus",
      flags: 3,
    },
    {
      text: "message.labels.carRental",
      value: 4,
      icon: "mdi-car-key",
      flags: 3,
    },
    {
      text: "message.labels.fuel",
      value: 5,
      icon: "mdi-gas-station",
      flags: 3,
    },
    {
      text: "message.labels.problems",
      value: 6,
      icon: "mdi-police-badge",
      flags: 3,
    },
    {
      text: "message.labels.foods",
      value: 7,
      icon: "mdi-cart",
      flags: 3,
    },
    {
      text: "message.labels.shopping",
      value: 8,
      icon: "mdi-shopping",
      flags: 3,
    },
    {
      text: "message.labels.meals",
      value: 9,
      icon: "mdi-silverware",
      flags: 3,
    },
    {
      text: "message.labels.entertainment",
      value: 10,
      icon: "mdi-handball",
      flags: 3,
    },
    {
      text: "message.labels.moneyTransfer",
      value: 11,
      icon: "mdi-cash",
      flags: 4,
    },
    {
      text: "message.labels.wallet",
      textAlter: "message.labels.walletPlus",
      value: 12,
      icon: "mdi-wallet",
      flags: 8,
    },
    {
      text: "message.labels.liquor",
      value: 13,
      icon: "mdi-liquor",
      flags: 3,
    },
    {
      text: "message.labels.services",
      value: 14,
      icon: "mdi-hair-dryer",
      flags: 3,
    },
    {
      text: "message.labels.road",
      value: 15,
      icon: "mdi-road-variant",
      flags: 3,
    },
    {
      text: "message.labels.parking",
      value: 16,
      icon: "mdi-parking",
      flags: 3,
    }
  ],
  shareds: [
    {
      text: "message.members.basic",
      value: 0,
    },
    {
      text: "message.members.alter1",
      value: 1,
    },
    {
      text: "message.members.alter2",
      value: 2,
    }
  ],
  paytableHeaders: [
    {
      text: "message.date",
      value: "date",
      class: "pl-5 pr-3 py-2 text-left",
      itemClass: "pl-5 pr-3 py-2 text-left text-sm-body-1 text-xs-caption",
      itemNoWrap: true,
    },
    {
      text: "",
      value: "icon",
      class: "px-3 py-2 text-center",
      itemClass: "px-3 py-2 text-center vf-3icon",
    },
    {
      text: "message.description",
      value: "memo",
      filterable: true,
      class: "px-3 py-2 text-center d-none d-lg-table-cell",
      itemClass: "px-3 py-2 text-center d-none d-lg-table-cell w40p",
    },
    {
      text: "message.payer",
      value: "name",
      filterable: true,
      class: "px-3 py-2 text-center d-none d-sm-table-cell",
      itemClass: "px-3 py-2 text-center d-none d-sm-table-cell w40p",
    },
    {
      text: "message.amount",
      value: "amount",
      filterable: true,
      class: "px-3 py-2 text-right",
      itemClass: "px-3 py-2 text-right text-sm-body-1 text-xs-caption",
    },
    {
      text: "",
      value: "buttons",
      class: "px-3 py-2 text-right d-none d-md-table-cell",
      itemClass: "px-3 py-2 text-right d-none d-md-table-cell w20p",
      itemNoWrap: true,
    },
    {
      text: "",
      element: "switch",
      class: "px-3 py-2 text-right vf-icon",
      itemClass: "px-3 py-2 text-right",
    },
  ],
  slaveFlags : {
    read: 1,
    writeMy:  2,
    writeAll: 4,
    master: 8,
    banker: 16,
    creator: 32
  },
  slaveFlagsUp : {
    read: 1,
    writeMy: 3,
    writeAll: 7,
    master: 15,
    banker: 31,
    creator: 63
  },  
  slaveFlagsId : {
    "1": 0,
    "3": 1,
    "7": 2,
    "15": 3,
    "31": 3
  },
  flagsSlider: [
    {
      id: 0,
      flag: 1,
      topFlag: 1,
      info: 'message.slaveFlags.readOnly',
      icon: 'mdi-eye'
    },
    {
      id: 1,
      flag: 3,
      topFlag: 2,
      info: 'message.slaveFlags.manageOwn',
      icon: 'mdi-account-edit'
    },
    {
      id: 2,
      flag: 7,
      topFlag: 4,
      info: 'message.slaveFlags.manageAny',
      icon: 'mdi-square-edit-outline'
    },
    {
      id: 3,
      flag: 31,
      topFlag: 16,
      info: 'message.slaveFlags.manageAll',
      icon: 'mdi-tools'
    }
  ],
  userColors: ["teal", "purple", "red", "blue", "green", "pink", "brown"],
  colorPalette: { 
    rows: [
      [1,2,3],
      [4,5,6,7],
      [8,9,10,11,12],
      [13,14,15,16],
      [17,18,19],
      [20,21,22,23]],
    extraRow: 5,
    colors :[
    '#A0A000',
    '#5AB400',
    '#00C800',
    
    '#C86400',
    '#646400',
    '#008000',
    '#00966E',
    
    '#FF0000',
    '#C00000',
    '#000000',
    '#005080',
    '#008CFF',
    
    '#C80064',
    '#800080',
    '#000080',
    '#005AD2',
    
    '#C000C0',
    '#6E00D2',
    '#0000FF',

    '#5A5A5A',
    '#6E6E6E',
    '#828282',
    '#969696',
    
  ]},
  account: {
    current: 2,
    created: '20.09.2022',
    updated: '25.09.2022',
    expired: '25.09.2023',
    existing: {
      title: "X",
      value: 2,
      duration: 365,
      price: 100,
      masters: 3,
      slaves: 5,
      xls: false,
      disabled: false,
      allow: false,
      expected: '01.01.2024',
      recalc: 0    
    }, 
    tarifs:[
      {
        title: "Y",
        value: 1,
        duration: 30,
        price: 0,
        masters: 3,
        slaves: 3,
        xls: false,
        disabled: true,
        allow: false,
        expected: '01.01.2023',
        recalc: 0    
      },
      {
        title: "Master M",
        value: 2,
        duration: 365,
        price: 100,
        masters: 3,
        slaves: 5,
        xls: false,
        disabled: false,
        allow: false,
        expected: '15.12.2023',
        recalc: 0    
      },   
      {
        title: "Master L",
        value: 3,
        duration: 365,
        price: 150,
        masters: 5,
        slaves: 10,
        xls: false,
        disabled: false,
        allow: false,
        expected: '15.12.2023',
        recalc: 205    
      }, 
      {
        title: "Master XL",
        value: 4,
        duration: 365,
        price: 200,
        masters: 10,
        slaves: 20,
        xls: true,
        disabled: false,
        allow: true,
        expected: '15.12.2023',
        recalc: 160    
      }, 
      {
        title: "Master XXL",
        value: 5,
        duration: 365,
        price: 300,
        masters: 20,
        slaves: 40,
        xls: true,
        disabled: false,
        allow: true,
        expected: '15.12.2023',
        recalc: 102
      }],
    },
    shemas: ['Base', 'A', 'B', 'C', 'D']
};