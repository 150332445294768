<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card>
      <v-card-title class="primary vf-add-payment px-5">
        {{ $t('message.navbar.currency') }}
        <v-spacer/>
        <v-icon @click.stop="vfInfo = !vfInfo" :color="vfInfo ? 'amber' : 'white'">mdi-information-outline</v-icon>
      </v-card-title>
      <v-card-text class="px-5 py-0">
        <transition name="fade">
          <v-row class="ma-0 pa-0" v-if="vfInfo">
            <v-col cols="12" class="ma-0 pa-0" >
              <h3 class="vf-info vf-info-1">{{ $t('message.currency.TitleRate') }}:</h3>
              <p class="vf-info">{{ $t('message.currency.TitleRateInfo') }}</p>
              <p class="vf-info">{{ $t('message.currency.TitleRateInfo1') }}</p>
              <!-- <p class="my-0">{{ $t('message.currency.TitleRateInfo2') }}</p> -->
              <h3 class="vf-info">{{ $t('message.currency.OfBalance') }}:</h3>
              <p class="vf-info">{{ $t('message.currency.OfBalanceInfo') }}</p>
              <h3 class="vf-info">{{ $t('message.currency.activation') }}:</h3> 
              <p class="vf-info">{{ $t('message.currency.activationInfo') }}</p>
            </v-col>
          </v-row>
        </transition>        
        <v-form id="form-udate" ref="pfom" class="ma-0 mt-4 pa-0" v-model="curValid" action="#" validate-on="blur" @submit="() => false">
          <v-radio-group 
            v-model="finalCurrency" 
            @rfange="currencyChange" 
            class="ma-0 pa-0">
            <template v-for="(currency, idx) in currencys">
              <template v-if="idx > 0">
                <v-row :key="idx" class="ma-0 pa-0">
                  <v-col cols="3" class="ma-0 pa-0">
                    <v-text-field
                      type="text"
                      :class="'mt-3 mb-0'+(currency.allowed ? '' : 'cur-grey')"
                      dense
                      v-model="currency.name"
                      :label=" $t('message.title')"
                      :readonly="currency.permanent || !currency.allowed"
                      :rules="[validationRules().required, validationRules().length]"
                      hide-details="auto"
                      outlined
                      @input="currencyChange"
                    >
                    </v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-text-field
                      :class="'mt-3 mb-0'+(currency.allowed ? '' : 'cur-grey')"
                      dense
                      type="number"
                      v-model="currency.rate"
                      :label=" $t('message.currency.rateIn') +' '+currencys[finalCurrency].name"
                      :rules="[validationRules().required, validationRules().number]"
                      :readonly="(idx == finalCurrency) || !currency.allowed"
                      hide-details="auto"
                      outlined
                      @input="currencyChange"
                      :append-icon="(currency.allowed && currency.forex && !(idx == finalCurrency)) ? 'mdi-chart-line' : ''"                    
                      @click:append="openForex(idx)"
                    >
                    </v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1" class="mt-5 mb-0 pa-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-radio                    
                          :value="idx" 
                          :readonly="!curValid || finalFixed || !currency.allowed"
                          v-bind="attrs"
                          v-on="on">
                        </v-radio>
                      </template>
                      {{ $t('message.currency.OfBalance') }}.<br/>
                      <template v-if="!finalFixed">
                        {{ $t('message.currency.selectBefore') }}.<br/>
                      </template>
                      <template v-else>
                        {{ $t('message.currency.impossibleToChange') }}.<br/>
                      </template>                    
                    </v-tooltip>
                  </v-col>              
                  <v-col cols="1" class="ma-0 pa-0">
                    <v-switch         
                      :readonly="!curValid || (idx == finalCurrency)"
                      @change="currencyChange"
                      v-model="currency.allowed">
                    </v-switch>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <transition name="fade" :key="idx+'a'">
                  <v-row v-if="forex == idx" class="ma-0 mb-4 pa-0">
                    <v-col cols="7" class="ma-0 mt-1 pa-0 pl-2 text-right primary--text">
                      Forex: {{ currency.forex }}
                    </v-col>
                    <v-spacer/>
                    <v-col cols="4" class="ma-0 pa-0 text-left">
                      <v-btn small color="primary" @click.stop="setForex(idx)">
                        <v-icon>mdi-check-bold</v-icon>
                      </v-btn>
                    </v-col>                  
                  </v-row>
                </transition>
              </template>                
            </template>
          </v-radio-group>
        </v-form>
      </v-card-text>
      <v-divider class="mt-0"></v-divider>
      <v-card-actions class="ma-5 mb-0 pa-0 pb-5">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          elevation="4"
          @click.stop="closeForm"
          :disabled="!curValid"
        >
          {{ $t('message.close') }}
        </v-btn>   
      </v-card-actions>
      <div v-if="vKeyboard" style="height:20em">
      </div>           
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CurrencyEdit",
  model: {
    prop: 'dialogModel',
    event: 'open'
  },   
  props: {
    currencys: { type: Array },
    finalFixed: { type: Boolean, default: false },
    vKeyboard: { type: Boolean },
    dialogModel: { type: Number },
  },
  data: () => {
    return {
      vfInfo: false,
      dialog: false,
      finalCurrency: 1,    
      observer: false,
      curValid: false,
      listenerUpdate: false,  
      forex: 0,
    };
  },
  updated() {
    if(!this.listenerUpdate) {
      document.getElementById('form-udate')?.addEventListener('submit', (evt) => {
        evt.preventDefault();
        this.listenerUpdate = true;
        return false;
      }, false);
    }
  },  
  methods: {
    openForex(idx) {
      this.forex = (idx == this.forex) ? 0 : idx;
    },
    setForex(idx) {
      let value = this.currencys;
      value[idx].rate = this.currencys[idx].forex;
      this.forex = 0; 
      this.currencyChange();
    },
    currencyChange() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            if(this.curValid) {
              this.$emit('change',this.trip);
            }
          });
        });
      });
    },
    closeForm() {
      this.vfInfo = false;
      this.$emit("open",0);
    },
    validationRules() {
      return {
        required: value => !!value || this.$t('message.validation.required'),
        number: value => (parseFloat(value) > 0) || this.$t('message.validation.numberPos0'),
        length: value => (value && (value.length == 3)) || this.$t('message.validation.currencyLabel')
      }
    },
    toggleInfo() {
      this.vfInfo = !this.vfInfo;
    }
  },
  watch: {
    dialogModel: {
      handler(value) {
        if(value) {
          this.vfInfo = false;
          this.finalCurrency = this.currencys[0].id;  
          this.forex = 0;        
        }
        this.dialog = !!value;
        this.observer = !!value;
      }
    },    
    currencys: {
      handler() {
        if(this.observer) {
          this.finalCurrency = this.currencys[0].id;
        }
      },
      deep: true
    },
    finalCurrency: {
      handler(newValue) {
          this.observer = false;
          // console.log('Val new: '+newValue+' old: '+oldValue);
          let olds = structuredClone(this.currencys);
          let news = this.currencys;
          news.forEach((cur, idx) => {
            if(idx) {
              cur.rate = parseFloat((parseFloat(cur.rate) / parseFloat(olds[newValue].rate)).toPrecision(4));
            } else {
              cur.id = newValue;
            }
          });
          this.$emit('change');
          this.observer = true;
      }
    }    
  },
};
</script>
<style scoped>
.vf-add-payment {
  color: white;
  font-size: 1.1em !important;  
  font-weight: bold;  
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.cur-grey {
  background-color: #f0f0f0;
}
p.vf-info {
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0;
}
h3.vf-info {
  font-size: 1em;
  margin: 0.3em 0 0 0;
  text-decoration: underline;
}
.vf-info-1 {
  margin-top: 1em !important;
}
.vf-info-2 {
  margin-top: 1em !important;
}
</style>