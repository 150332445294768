<template>
  <v-container v-resize="onResize">
    <template v-if="payTable.length">
      <v-row class="ma-0 px-0 py-6">
        <v-col cols="12" class="text-center">
          <h2 class="primary--text mt-8">{{ $t("message.navbar.statisticsTitle") }}</h2>
          <table cellspacing="0" class="vf-stat">
            <tr>
              <td class="vf-statx">&nbsp;</td>
              <template v-for="(grp, idx) in graph">
                <td v-if="(grp.height > 0) && (idx < cells)" :key="idx" class="vf-stat" :style="'padding-left: '+padding+'px; padding-right: '+padding+'px;'">
                  <v-tooltip top v-if="((1+idx) < cells) || (cells >= graph.length)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-sheet v-bind="attrs" v-on="on" :elevation="6" :width="cellWidth" :height="grp.height" class="vf-stat-gr mb-2 mx-auto pt-auto pb-0 rounded-pill text-bottom"/>
                    </template>
                    {{ grp.amountCurrency }}
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-sheet v-bind="attrs" v-on="on" :elevation="6" :width="cellWidth" :height="graphRestHeight(idx)" 
                      class="vf-stat-gr mb-2 mx-auto pt-auto pb-0 rounded-pill text-bottom"/>
                    </template>
                    {{ graphRestAmount(idx) }}
                  </v-tooltip>                  
                </td>
              </template>
              <td class="vf-statx">&nbsp;</td>
            </tr>
            <tr>
              <td class="vf-stat2">&nbsp;</td>
              <template v-for="(grp, idx) in graph">
                <td v-if="(grp.height > 0) && (idx < cells)" :key="idx" class="vf-stat2">
                  <v-icon class="mt-2 mb-0" v-if="((1+idx) < cells) || (cells >= graph.length)">{{grp.icon}}</v-icon>
                  <v-icon class="mt-2 mb-0" v-else>mdi-dots-horizontal</v-icon>
                </td>
              </template>
              <td class="vf-stat2">&nbsp;</td>
            </tr>          
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer/>
        <v-col cols="11" md="8" lg="6" class="mb-0 mt-6 pa-0 text-center">
          <v-select
            dense
            class="ma-0 pa-0"
            :items="accounts"
            :value="0"
            v-model="account"
            outlined
          >
          </v-select>
        </v-col>
        <v-spacer/>
      </v-row>
      <v-row>
        <v-spacer/>
        <v-col cols="8" sm="6" md="4" lg="3" class="ma-0 pa-0 text-center">
          <v-switch
            class="ma-0 pa-0"
            :input-value="privateBudget"
            :false-value="false"
            :label="$t('message.operationList.privateIncluded')"
            @change="privateSwitch"
          >
          </v-switch>
        </v-col>
        <v-spacer/>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-left"><v-icon>mdi-sigma</v-icon></td>
                  <td class="text-left text-body-1 d-none d-sm-table-cell">{{ $t('message.operationList.totalBudget') }}</td>
                  <td class="text-right text-body-1">{{ totalAmount }}</td>
                </tr>              
                <template v-for="(item,id) in graph">
                  <tr v-if="item.height" :key="id">
                    <td class="text-left"><v-icon>{{ item.icon }}</v-icon></td>
                    <td class="text-left text-body-1 d-none d-sm-table-cell">{{ item.text }}</td>
                    <td class="text-right text-body-1">{{ item.amountCurrency }}</td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" class="my-16">&nbsp;</v-col>
      </v-row>
    </template>
    <v-row v-else>
      <v-col cols="12" class="text-center">
        <h1 v-if="travellers.length > 1" class="text-center mt-10 grey--text text--lighten-1 vf-await">
          {{ $t(apiBusy ? "message.operationList.processTransactions" : "message.operationList.noTransactions") }}
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  model: {
    prop: 'privateBudget',
    event: 'private'
  },  
  props: {
    travellers: { type: Array },
    payments: { type: Array },
    payTable: { type: Array },  
    apiBusy:  { type: Boolean },
    lbls: { type: Array },
    balanceCurrency: { type: String },
    privateBudget: { type: Boolean }
  },
  data() {
    return {
      account: 0,
      size: 0,
      cellWidth: 25,
      padding: 3,
      cells: 30,
      updater: 1,
      // privateBudget: true,
      VF_MAX_HEIGHT: 200,
      VF_MIN_HEIGHT: 5,
      VF_MAX_WIDTH: 40,
      VF_MIN_WIDTH: 28,
      VF_MAX_PADDING: 6,
      VF_MIN_PADDING: 2,      
      VF_BORDER: 60,
    };
  },
  computed: {
    totalAmount() {
      let total = 0;
      this.graph.forEach( (grp) => {
        total += grp.amount;
      });
      return total.toFixed(2) + '\xA0' + this.balanceCurrency;
    },
    accounts() {
      let accounts = [];
      accounts.push({
        text: this.$t("message.all"),
        value: 0
      });
      this.travellers.forEach( (trv, idx) => {
        if(idx && trv.allowed) {
          accounts.push({
            text: trv.name,
            value: idx
          });
        }
      });
      return accounts;
    },
    graph() {
      let graph = structuredClone( this.lbls );
      let graphMax = 0;

      graph.forEach( (grp) => {
        grp.amount = 0;
        grp.amountCurrency = '';
        grp.height = 0;
        if(grp.textAlter) {
          grp.text = grp.textAlter;
        }
      });
      this.payTable.forEach( (pay) => {
        if(this.updater && (typeof (pay.balance.debetN) != 'undefined')) {
          let theme = (typeof pay.theme == 'undefined') ? 11 : parseInt(pay.theme);
          let themeNeg = (pay.themeLink) ? parseInt(pay.themeLink) : theme;
          this.travellers.forEach((traveller,id) => {
            if(id && (!this.account || (this.account == id))) {
              if(pay.balance.debetN[id-1] > 0) {
                graph[theme-1].amount += pay.balance.debetN[id-1];
              } else {
                graph[themeNeg-1].amount += pay.balance.debetN[id-1];
              }
            }
          });
        }
      });
      graph.forEach( (grp) => {
        if(grp.amount > graphMax) {
          graphMax = grp.amount;
        }
      });
      graph.sort((a,b) => {
        return (a.amount > b.amount) ? -1 : 1;
      });
      let last = 0;
      if(graphMax > 0) {
        let ratio = this.VF_MAX_HEIGHT / graphMax;
        graph.forEach( (grp, idx) => {
          if(grp.amount > 0) {
            grp.height = this.VF_MIN_HEIGHT + Math.floor( grp.amount * ratio );
            grp.amountCurrency = grp.amount.toFixed(2) + '\xA0' + this.balanceCurrency;
            last = idx;
          }
        });
      }
      return graph.splice(0,last+1);
    } 
  },
  methods: {
    graphRestHeight(id) {
      let height = 0;
      for(let idx = id; idx < this.graph.length; idx++) {
        height += this.graph[idx].height;
      }
      return height;
    },
    graphRestAmount(id) {
      let amount = 0;
      for(let idx = id; idx < this.graph.length; idx++) {
        amount += this.graph[idx].amount;
      }
      return amount.toFixed(2) + '\xA0' + this.balanceCurrency;
    },
    privateSwitch(event) {
      this.$emit('private',event);
    },
    onResize() {
      this.updater++;
      let width = window.innerWidth - this.VF_BORDER;
      this.padding = Math.min(this.VF_MAX_PADDING, Math.max(this.VF_MIN_PADDING,Math.floor(width / this.graph.length - this.VF_MAX_WIDTH)));
      //this.cells = Math.min(this.graph.length, Math.floor(width / (this.VF_MIN_WIDTH + 2 * this.padding)));
      this.cells = Math.floor(width / (this.VF_MIN_WIDTH + 2 * this.padding));
      this.cellWidth = Math.min(this.VF_MAX_WIDTH, Math.floor(width / this.cells - 2 * this.padding));
    },
  }
};
</script>

<style scoped>
table.vf-stat {
  margin: 0 auto 0 auto;
  width: 100%;
}
td.vf-stat {
  width: auto;
  height: 300px;
  vertical-align: bottom;
  text-align: center;
  border-bottom: solid blue 1px;
}
td.vf-statx {
  width: 50%;
  border-bottom: solid blue 1px;
}
td.vf-stat2 {
  width: auto;
  height: 20px;
  vertical-align: top;
  text-align: center;
}
.vf-stat-gr {
  background: linear-gradient(165deg, rgb(255, 0, 0) 10%, rgba(128,0,0,1) 90%);
}
</style>
