<template>
  <span>
    <template v-if="(text.length < fromLength) || expanded">
      {{ text }}
    </template>
    <template v-else>
      {{ text.substring(0, length) }}
      <b @click.stop="openExp" :class="expClass + ' pointer'"> ... {{option}}</b>
    </template>
  </span>
</template>
<script>
export default {
  name: "VfExpantionText",
  model: {
    prop: 'expanded',
    event: 'expand'
  },   
  props: {
    text: { type: String },
    length: { type: Number },
    fromLength: { type: Number },
    option: { type: String },
    expClass: { type: String },
    expanded: { type: Boolean }
  },
  data() {
    return {
      exp_text: false
    };
  },
  methods: {
    openExp() {
      this.$emit('expand',true);
    }
  },
  watch: { 
    text: {
      handler() {
        this.$emit('expand',false);
      }
    },    
  }
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
