<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card>
      <v-card-title class="primary vf-add-payment px-5">
        {{$t('message.navbar.account')}}
        <v-spacer />
        <v-icon @click.stop="vfInfo = !vfInfo" :color="vfInfo ? 'amber' : 'white'">
          mdi-information-outline
        </v-icon>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
        <template v-if="!config.master">
          <transition name="fade">
            <v-row class="ma-0 pa-0 px-5" v-if="vfInfo">
              <v-col cols="12" class="ma-0 pa-0" >
                <h3 class="vf-info vf-info-1">{{ $t("message.reg.title") }}</h3>
                <p class="vf-info">{{ $t('message.reg.reg1') }}</p>
                <p class="vf-info vf-info-1">{{ $t('message.reg.reg2') }}</p>
                <h3 class="vf-info vf-info-1">{{ $t("message.reg.title1") }}</h3>
                <p class="vf-info">{{ $t('message.reg.reg3') }}</p>
                <p class="vf-info vf-info-1">{{ $t('message.reg.reg3a') }}</p>
                <p class="vf-info vf-info-1">{{ $t('message.reg.reg4') }}</p>
                <h3 class="vf-info vf-info-1">{{ $t("message.reg.title2") }}</h3>
                <p class="vf-info">{{ $t('message.reg.reg5') }}</p>
              </v-col>
            </v-row>
          </transition>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="mt-6 mb-0 pa-0 px-5">
              <v-form id="form-reg" ref="pform" v-model="regValid" validate-on="blur">
                <v-text-field
                  class="ma-0 mb-4 pa-0"
                  dense
                  type="email"
                  v-model="email"
                  label="E-Mail"
                  :rules="[validationRules().email]"
                  hide-details="auto"
                  outlined>
                </v-text-field>
                <v-row class="ma-0 pa-0">
                  <v-col cols="2" class="ma-0 pa-0">
                    <v-checkbox
                      class="ma-0 pa-0"
                      v-model="recover"
                      hide-details/>
                  </v-col>
                  <v-col cols="8" class="ma-0 pa-0 text-left">
                    {{$t('message.reg.recover')}}
                  </v-col>
                </v-row>
                <v-row class="ma-0 mt-4 pa-0">
                  <v-col cols="2" class="ma-0 pa-0 ">
                    <v-checkbox
                      :rules="[validationRules().required]"
                      class="ma-0 pa-0"
                      v-model="agreement"
                      hide-details/>
                  </v-col>
                  <v-col cols="8" class="ma-0 pa-0 text-left">
                    {{ parseSplit($t('message.reg.agreement'),0) }} <br/><a href="#" @click.stop="$emit('agree',1)">{{ parseSplit($t('message.reg.agreement'),1) }}</a>{{ parseSplit($t('message.reg.agreement'),2) }}<br/><a href="#" @click.stop="$emit('agree',0)">{{ parseSplit($t('message.reg.agreement'),3) }}</a><br/>
                    {{ parseSplit($t('message.reg.agreement'),4) }}
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <transition name="fade">
            <v-row class="ma-0 pa-0 px-5 pb-4" v-if="vfInfo">
              <v-col cols="12" class="ma-0 pa-0" >
                <p class="vf-info vf-info-2">{{ $t('message.reg.account') }}</p>
                <p class="vf-info vf-info-1">{{ $t('message.reg.account1') }}</p>
                <p class="vf-info vf-info-1">{{ $t('message.reg.account2') }}</p>
              </v-col>
            </v-row>
          </transition>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 px-5 py-2 text-left primary--text vf-pointer blue lighten-4" @click.stop="showMainStart">
              {{ $t('message.reg.mainData') }}
            </v-col>
            <transition name="fade">
              <v-col cols="12" class="mx-0 pa-0" v-if="showMain">
                <v-simple-table class="ma-0 pa-0">
                  <tbody>
                    <tr>
                      <td class="ma-0 pa-0 px-5">{{ $t('message.reg.accountId') }}</td><td class="ma-0 pa-0 px-5">{{config.master}}</td>
                    </tr>
                    <tr v-if="!emailEdit">
                      <td colspan="2" class="ma-0 pa-0 px-5">
                        <div style="display: grid; grid-template-columns: 20% 80%">
                          <div class="text-left">
                            <v-icon @click.stop="emailEdit = true">
                              mdi-square-edit-outline
                            </v-icon>
                          </div>                          
                          <div class="text-right">
                            {{config.email}}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="emailEdit">
                      <td colspan="2" class="ma-0 pa-0 px-5">
                        <p class="mt-4">{{ $t('message.reg.emailAlert') }}</p>
                        <v-form id="form-udate" ref="eform" v-model="emailValid" action="#" validate-on="blur">
                          <v-text-field
                            class="mt-4"
                            dense
                            type="email"
                            v-model="email"
                            label="E-mail"
                            :rules="[validationRules().email]"
                            outlined
                            :append-outer-icon="emailValid ? 'mdi-check' : 'mdi-alert-outline'"
                            @click:append-outer="emailSave"
                          >
                          </v-text-field>
                        </v-form>
                      </td>
                    </tr>                   
                    <tr v-if="account.existing.masters">
                      <td v-html="$t('message.reg.tariff')" class="ma-0 pa-0 px-5"></td>
                      <td class="ma-0 pa-0 px-5">{{account.existing.title}}</td>
                    </tr>  
                    <tr v-if="account.existing.price || account.existing.gpPrice">
                      <td class="ma-0 pa-0 px-5">{{ $t('message.reg.price') }}</td>
                      <td class="ma-0 pa-0 px-5" v-if="account.existing.gpPrice">{{account.existing.gpPrice}}</td>
                      <td class="ma-0 pa-0 px-5" v-else>{{account.existing.price + ' UAH'}}</td>
                    </tr>
                    <tr>
                      <td class="ma-0 pa-0 px-5">{{ $t('message.reg.created') }}</td>
                      <td class="ma-0 pa-0 px-5">{{account.created}}</td>
                    </tr>
                    <tr v-if="account.expiration">
                      <td class="ma-0 pa-0 px-5">{{ $t('message.reg.expire') }}</td>
                      <td class="ma-0 pa-0 px-5">{{account.expiration}}<span v-if="account.expired"> {{ $t('message.reg.expired') }}</span></td>
                    </tr>
                    <tr v-if="account.expiration">
                      <td v-html="$t('message.reg.totalTrips')" class="ma-0 pa-0 px-5"></td>
                      <td class="ma-0 pa-0 px-5">{{this.config.masters.length + ' ' + $t('message.reg.of') + ' ' + this.config.maxMasters}}</td>
                    </tr>
                    <tr>
                      <td v-html="$t('message.reg.totalSlaves')" class="ma-0 pa-0 px-5"></td>
                      <td class="ma-0 pa-0 px-5">{{this.config.slaves.length + ' ' + $t('message.reg.of') + ' ' + this.config.maxSlaves}}</td>
                    </tr>
                    <tr v-if="account.expiration">
                      <td v-html="account.xls ? $t('message.reg.xlsBackup') : $t('message.reg.xlsAllow')" class="ma-0 pa-0 px-5"></td>
                      <td v-html="(account.xls ? (account.xls + ' ' + $t('message.reg.of') + ' ') : '') + $tc('message.reg.xlsQty',account.existing.xls)" class="ma-0 pa-0 px-5"></td>
                    </tr>
                    <tr v-if="account.existing.support">
                      <td class="ma-0 pa-0 px-5">{{$t('message.reg.support')}}</td>
                      <td class="ma-0 pa-0 px-5"><a :href="'mailto:'+supportEmail+'?subject=My Tripcount id: '+config.master">{{supportEmail}}</a></td>
                    </tr>                                       
                  </tbody>                          
                </v-simple-table>
              </v-col>
            </transition>
          </v-row>
          <v-row class="mx-0 mt-2 mb-0 pa-0">    
            <v-col cols="12" class="ma-0 px-5 py-2 text-left primary--text vf-pointer blue lighten-4" @click.stop="showTarifChangeStart">
              {{ $t('message.reg.changeTariff') }}
            </v-col>
            <transition name="fade">
              <v-col cols="12" class="ma-0 pa-0" v-if="showTarifChange">
                <v-select
                  class="ma-0 mt-6 mb-4 pa-0 px-5"
                  dense
                  hide-details
                  :items="account.tarifs"
                  item-text="title"
                  item-value="value"
                  item-disabled="disabled"
                  :label="$t('message.reg.newTariff')"
                  v-model="newTarif"
                  outlined/>
                <v-simple-table class="ma-0 pa-0">
                  <tbody>
                    <tr v-if="account.tarifs[newTarif-1].gpPrice || account.tarifs[newTarif-1].price">
                      <td class="ma-0 pa-0 px-5">{{ $t('message.reg.amount') }}</td>
                      <td class="ma-0 pa-0 px-5" v-if="account.tarifs[newTarif-1].gpPrice">{{account.tarifs[newTarif-1].gpPrice}}</td>
                      <td class="ma-0 pa-0 px-5" v-else>{{account.tarifs[newTarif-1].price}} UAH</td>
                    </tr>
                    <tr>
                      <td class="ma-0 pa-0 px-5">{{ $t('message.reg.duration') }}</td>
                      <td class="ma-0 pa-0 px-5">{{account.tarifs[newTarif-1].duration + ' ' + $t('message.reg.days')}}</td>
                    </tr> 
                    <template v-if="account.tarifs[newTarif-1].allow && !account.tarifs[newTarif-1].overtrips">
                      <tr v-if="account.tarifs[newTarif-1].recalc">
                        <td class="ma-0 pa-0 px-5">{{ $t('message.reg.recalc') }}</td>
                        <td class="ma-0 pa-0 px-5">{{account.tarifs[newTarif-1].recalc + ' ' + $t('message.reg.days')}}</td>
                      </tr>                     
                      <tr>
                        <td class="ma-0 pa-0 px-5">{{ $t('message.reg.expectedTo') }}</td>
                        <td class="ma-0 pa-0 px-5">{{account.tarifs[newTarif-1].expected}}</td>
                      </tr>         
                    </template>
                    <tr>
                      <td class="ma-0 pa-0 px-5">{{ $t('message.reg.trips') }}</td>
                      <td class="ma-0 pa-0 px-5">{{account.tarifs[newTarif-1].masters}}</td>
                    </tr>
                    <tr>
                      <td class="ma-0 pa-0 px-5">{{ $t('message.reg.slaves') }}</td>
                      <td class="ma-0 pa-0 px-5">{{account.tarifs[newTarif-1].slaves}}</td>
                    </tr>
                    <tr>
                      <td class="ma-0 pa-0 px-5" v-html="$t('message.reg.xlsAllow')"></td>
                      <td class="ma-0 pa-0 px-5" v-html="$tc('message.reg.xlsQty',account.tarifs[newTarif-1].xls)"></td>
                    </tr>
                    <tr>
                      <td class="ma-0 pa-0 px-5" v-html="$t('message.reg.support')"></td>
                      <td class="ma-0 pa-0 px-5" v-html="account.tarifs[newTarif-1].support ? 'e-Mail' : $tc('message.reg.no')"></td>
                    </tr>                      
                  </tbody>
                </v-simple-table>
                <template v-if="!(account.tarifs[newTarif-1].allow && !account.tarifs[newTarif-1].overtrips)">
                  <p class="mx-5" v-if="account.tarifs[newTarif-1].overtrips">{{ $t('message.reg.overtrips') }}</p>
                  <p class="mx-5" v-if="!account.tarifs[newTarif-1].allow">{{ $t('message.reg.earlyToExpand') }}</p>
                </template>
              </v-col>
            </transition>
          </v-row>          
          <v-row class="mx-0 mt-2 mb-0 pa-0" v-if="account.payments && account.payments.length">
            <v-col cols="12" class="ma-0 px-5 py-2 text-left primary--text vf-pointer blue lighten-4" @click.stop="showPaymentsStart">
              {{ $t('message.reg.myPayments') }}
            </v-col>            
            <transition name="fade">
              <v-col cols="12" class="mx-0 pa-0" v-if="showPayments">
                <v-simple-table class="ma-0 pa-0">
                  <tbody>
                    <tr v-for="pay, idx in account.payments" :key="idx">
                      <td class="ma-0 pa-0 px-5">{{pay.date}}</td>
                      <td class="ma-0 pa-0 px-5">{{pay.amount}} {{pay.currency}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>            
            </transition>
          </v-row>            
          <v-row class="mx-0 mt-2 mb-4 pa-0">
            <v-col cols="12" class="ma-0 px-5 py-2 text-left primary--text vf-pointer blue lighten-4" @click.stop="showExtrasStart">
              {{ $t('message.reg.extraOptions') }}
            </v-col>      
            <transition name="fade">
              <div class="pa-5 pb-0 ma-0" v-if="showExtras">
                <p v-if="closeAccountForced">{{ $t('message.reg.closeAccountInfo1') }}</p>
                <p v-else>{{ $t('message.reg.closeAccountInfo') }}</p>
                <v-switch         
                  :label="$t('message.reg.closeAccountForced')"
                  v-model="closeAccountForced">
                </v-switch>
                <transition name="fade">
                  <v-col cols="12" class="ma-0 pa-0" v-if="closeAccountInfo">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="closeAccountEnable"
                      :label="$t(closeAccountForced ? 'message.reg.closeAccountConfirmF' : 'message.reg.closeAccountConfirm')"/>
                  </v-col>
                </transition>
              </div>
            </transition>            
          </v-row>
        </template>     
        <v-divider class="my-2"></v-divider>           
      </v-card-text>
      <v-card-actions class="ma-5 mb-0 pa-0 pb-5">
        <template v-if="config.master">
          <v-btn
            vf-role="Close account"
            v-if="showExtras"           
            @click.stop="closeAccount"
            :disabled="!closeAccountEnable" 
            color="primary" 
            elevation="4">
            {{ $t('message.reg.closeAccount') }}
          </v-btn>          
          <v-btn 
            vf-role="Продление текущего тарифа"
            v-if="!showTarifChange && !emailEdit && !showExtras" 
            color="success" 
            :disabled="!account.tarifs[tarif-1].allow" 
            elevation="4" 
            @click.stop="pay">
            {{$t('message.reg.extend')}}
          </v-btn>
          <v-btn 
            vf-role="Выбор тарифа"
            v-if="showTarifChange && !emailEdit && !showExtras" 
            color="success" 
            :disabled="!account.tarifs[newTarif-1].allow || account.tarifs[newTarif-1].overtrips"
            elevation="4" 
            @click.stop="pay">
            {{$t((newTarif==tarif) ? 'message.reg.extend' : 'message.reg.change')}}
          </v-btn>          
        </template>
        <v-btn 
          vf-role="Регистрация акаунта"
          v-if="!config.master" 
          :disabled="!regValid" 
          color="success" 
          elevation="4" 
          @click.stop="$emit('masterRegister',{email: email, recover: recover})">
          {{$t('message.reg.send')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn 
          vf-role="Закрыть диалог"
          color="primary" 
          elevation="4"
          @click.stop="closeForm">
          {{$t('message.close')}}
        </v-btn>
      </v-card-actions>
      <div v-show="vKeyboard" style="height: 20em"></div>
    </v-card>
  </v-dialog>
</template>
<script>
//import { defs } from "./defaults.js";
import { supportEmail } from "./cordova.js";
export default {
  name: "SettingsEdit",
  model: {
    prop: "dialogModel",
    event: "open",
  },
  props: {
    config: { type: Object },
    vKeyboard: { type: Boolean },
    dialogModel: { type: Number },
    account: {type: Object}
  },
  data: () => {
    return {
      email : '',
      recover: false,
      agreement: false,
      regValid: false,      // валидация e-mail при регистрации
      emailValid: false,    // валидация изменения e-mail
      vfInfo: false,
      dialog: false,
      tarif: 5,
      showTarifChange: false,   // развернутый список новых тарифов
      showMain: true,
      showPayments: false,  // список старых платежей
      showExtras: false,        // раздел ликвидации акаунта
      closeAccountEnable: true,  // кнопка ликвидации акаунта
      closeAccountInfo: false,  // подробности
      closeAccountForced: false, // Удалить данные и закрыть акаунт
      emailEdit: false,     // редакция e-mail
      newTarif: 3,
      listenerUpdate: false,
      listenerReg: false,
      supportEmail: supportEmail
    };
  },
  updated() {
    if(!this.listenerUpdate) {
      document.getElementById('form-udate')?.addEventListener('submit', (evt) => {
        evt.preventDefault();
        this.listenerUpdate = true;
        return false;
      }, false);
    }
    if(!this.listenerReg) {
      document.getElementById('form-reg')?.addEventListener('submit', (evt) => {
        evt.preventDefault();
        this.listenerReg = true;
        return false;
      }, false);    
    }
  },
  methods: {
    parseSplit(txt, idx) {
      let parts = txt.split('$');
      if(parts.length < (idx+1)) {
        parts[idx] = '*';
      } 
      return parts[idx];
    },
    emailSave() {
      if(this.emailValid) {
        this.emailEdit = false;
        this.$emit('email',this.email);
      }
    },
    closeForm() {
      this.vfInfo = false;
      this.$emit("open", 0);
    },
    validationRules() {
      return {
        required: (value) => !!value || this.$t("message.validation.required"),
        email: (value) => (value && !!value.match(/^([a-zA-Z0-9_\-.])+@([a-zA-Z0-9_\-.])+\.([a-zA-Z0-9]{2,})+$/)) || this.$t('message.validation.email')
      };
    },
    pay() {
      this.vfInfo = false;
      this.$emit("pay", this.account.tarifs[this.newTarif-1]); 
      this.$emit("open", 0);     
      // alert('Amount: '+this.account.tarifs[this.newTarif-1].price+' User '+this.config.master+' Tarif '+this.account.tarifs[this.newTarif-1].id);
    },
    closeAccount() {
      if(!this.closeAccountInfo) {
        this.closeAccountEnable = false;
        this.closeAccountInfo = true;
      } else {
        this.$emit('wipe',this.closeAccountForced);
        this.closeForm();
      }
    },
    showExtrasStart() {
      this.hideTabs();
      this.closeAccountEnable = true;
      this.closeAccountInfo = false;      
      this.showExtras = true;
    },
    showMainStart() {
      this.hideTabs();
      this.showMain = true;
    },
    showPaymentsStart() {
      this.hideTabs();
      this.showPayments = true;
    },
    showTarifChangeStart() {
      this.hideTabs();
      this.showTarifChange = true;
    },
    hideTabs() {
      this.showMain = false;
      this.showExtras = false;   
      this.showPayments = false;  
      this.showTarifChange = false;       
    },
    toggleInfo() {
      this.vfInfo = !this.vfInfo;
    }
  },
  watch: {
    dialogModel: {
      handler(value) {
        if (value) {
          this.vfInfo = (this.config.master) ? false : true;
          this.tarif = this.account.current;
          this.newTarif = this.account.new;
          this.email = this.config.email;
        }
        this.hideTabs();
        this.dialog = !!value;
        this.emailEdit = false;     
        this.showMain = true;
        this.closeAccountEnable = true;
        this.closeAccountInfo = false;
        this.agreement = false;
        this.recover = false;
      },
    },
  },
};
</script>
<style scoped>
.vf-add-payment {
  color: white;
  font-size: 1.1em !important;  
  font-weight: bold;  
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.cur-grey {
  background-color: #f0f0f0;
}
p.vf-info {
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0;
}
h3.vf-info {
  font-size: 1em;
  margin: 0.3em 0 0 0;
  text-decoration: underline;
}
.vf-info-1 {
  margin-top: 1em !important;
}
.vf-info-2 {
  margin-top: 1em !important;
}
.vf-pointer {
  cursor: pointer;
}
</style>
