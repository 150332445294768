<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card>
      <v-card-title class="primary vf-add-payment px-5">
        {{ $t("message.navbar.trips") }}
        <v-spacer />
        <v-icon @click.stop="vfInfo = !vfInfo" :color="vfInfo ? 'amber' : 'white'"
          >mdi-information-outline</v-icon
        >
      </v-card-title>
      <v-card-text class="pa-0">
        <transition name="fade">
          <v-row class="ma-0 pa-0 px-5" v-if="vfInfo">
            <v-col cols="12" class="ma-0 pa-0" >
              <h3 class="vf-info vf-info-1">{{ $t("message.api.trips1") }}</h3>
              <p class="vf-info" v-for="(txt, idx) in $t('message.api.trips1a')" :key="'a_'+idx">{{txt}}</p>
              <v-icon class="vf-iinfo" small>mdi-tools</v-icon>
              <h3 class="vf-info">{{ $t("message.api.trips2") }}</h3>
              <p class="vf-info" v-for="(txt, idx) in $t('message.api.trips2a')" :key="'b_'+idx">{{txt}}</p>
              <v-icon class="vf-iinfo" small>mdi-hiking</v-icon>
              <h3 class="vf-info">{{ $t("message.api.trips3") }}</h3>
              <p class="vf-info" v-for="(txt, idx) in $t('message.api.trips3a')" :key="'с_'+idx">{{txt}}</p>
              <v-icon class="vf-iinfo" small>mdi-microsoft-excel</v-icon>
              <h3 class="vf-info">{{ $t("message.api.trips4") }}</h3>
              <p class="vf-info">{{ $t("message.api.trips4a") }}</p>
              <p class="vf-info">{{ $t("message.api.trips4b") }}</p>
              <p class="vf-info">{{ $t("message.api.trips4c") }}</p>
            </v-col>
          </v-row>
        </transition>
        <v-row v-if="trips.length" class="ma-0 pa-0">
          <v-col cols="12" class="ma-0 mt-8 pa-0 px-5" v-if="!tripNameEdit">
            <v-select
              @input="tripSelect"
              dense
              :items="trips"
              item-text="name"
              item-value="id"
              :label="$t('message.trip')+(trip.id ? (' • '+trip.id) : '')"
              v-model="trip"
              return-object
              outlined
              :append-outer-icon="(!!trip && (parseInt(trip.flag) & flags.creator)) ? 'mdi-square-edit-outline' : ''"
              @click:append-outer="tripNameEditor"
            >
              <template slot="selection" v-if="!!data.item" slot-scope="data">
                <v-icon color="primary">{{ !!data.item.slave ? 'mdi-hiking' : 'mdi-tools' }}</v-icon>&nbsp;
                {{ data.item.name }}
              </template>
              <template slot="item" v-if="!!data.item" slot-scope="data">
                <v-icon color="primary">{{ !!data.item.slave ? 'mdi-hiking' : 'mdi-tools' }}</v-icon>&nbsp;
                {{ data.item.name }} &nbsp; <span class="text--grey tiny">{{ data.item.id }}</span>
              </template>            
            </v-select>
          </v-col>
          <v-col cols="12" v-if="!!trip && trip.id && (parseInt(trip.flag) & flags.creator) && tripNameEdit" class="ma-0 mt-8 pa-0 px-5">
            <v-form id="form-udate" ref="tform" v-model="tripValid" action="#" validate-on="blur" @submit="() => false">
              <v-text-field
                id="trip-name-input"
                ref="tripName"
                type="text"
                class="my-0"
                dense
                v-model="trip.name"
                :label="$t('message.titleChange')"
                :rules="[validationRules().required, validationRules().titleLength]"
                outlined
                @input="tripEdit"
                :append-outer-icon="tripValid ? 'mdi-check' : 'mdi-alert-outline'"
                @click:append-outer="tripNameEdit = tripValid ? false : true"              
              >
              </v-text-field>
            </v-form>
          </v-col>          
        </v-row>
        <v-row v-if="!!trip && trip.id" class="ma-0 pa-0">
          <v-col cols="12" class="ma-0 pa-0">
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="ma-0 pa-0 px-5">{{$t('message.members.permissions')}}</td>
                  <td class="ma-0 pa-0 px-5">{{accessInfo}}</td>
                </tr>                
                <tr>
                  <td class="ma-0 pa-0 px-5">{{$t('message.operationList.totalUsers')}}</td>
                  <td class="ma-0 pa-0 px-5">{{travellers.length-1}}</td>
                </tr>
                <tr v-if="paymentsTable.length">
                  <td class="ma-0 pa-0 px-5">{{$t('message.operationList.totalBudget')}}</td>
                  <td class="ma-0 pa-0 px-5">{{paymentsTable[0].totalBudget}}</td>
                </tr> 
                <tr v-if="paymentsTable.length && (paymentsTable[0].walletAmount > 0.001)">
                  <td class="ma-0 pa-0 px-5">{{$t('message.operationList.totalWallet')}}</td>
                  <td class="ma-0 pa-0 px-5">{{paymentsTable[0].wallet}}</td>
                </tr>                                                 
                <tr>
                  <td class="ma-0 pa-0 px-5">{{$t('message.operationList.payments')}}</td>
                  <td class="ma-0 pa-0 px-5">{{paymentsTable.length}}</td>
                </tr>
                <tr v-if="paymentsTable.length">
                  <td class="ma-0 pa-0 px-5">{{$t('message.operationList.first')}}</td>
                  <td class="ma-0 pa-0 px-5">{{paymentsTable[paymentsTable.length-1].dateA}}</td>
                </tr>                                                
                <tr v-if="paymentsTable.length">
                  <td class="ma-0 pa-0 px-5">{{$t('message.operationList.last')}}</td>
                  <td class="ma-0 pa-0 px-5">{{paymentsTable[0].dateA}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="ma-0"/>
      <v-card-actions class="ma-5 mb-0 pa-0"
        v-if="!tripNameEdit && config.xls && !!trip && trip.id && (parseInt(trip.flag) & flags.creator) && (travellers.length > 1) && paymentsTable.length">
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"> 
            <v-btn
              v-bind="attrs"
              v-on="on"        
              elevation="4"
              color="primary"
              @click.stop="$emit('make-xls')"
            >
              <v-icon large>mdi-microsoft-excel</v-icon>
            </v-btn>        
          </template>
          {{$t('message.trips.saveAsXls')}}
        </v-tooltip>
      </v-card-actions>      
      <v-card-actions class="ma-5 mb-0 pa-0 pb-5" v-if="!tripNameEdit">
        <v-tooltip bottom v-if="!!(config.master) && (config.masters.length < config.maxMasters)">
          <template v-slot:activator="{ on, attrs }"> 
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="success"
              elevation="4"         
              @click.stop="addTrip">
              <v-icon large>mdi-plus</v-icon>
            </v-btn>
          </template>
          {{$t('message.trips.createTrip')}}
        </v-tooltip>
        <v-tooltip bottom v-if="trips.length">
          <template v-slot:activator="{ on, attrs }"> 
            <v-btn
              v-bind="attrs"
              v-on="on"        
              class="ml-2"
              elevation="4"
              color="error"
              @click.stop="deleteTrip"
            >
              <v-icon large>mdi-delete</v-icon>
            </v-btn>        
          </template>
          {{$t(!trip.slave ? 'message.trips.delMaster' : 'message.trips.delSlave')}}
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          elevation="4"
          @click.stop="closeForm"
        >
          {{ $t('message.close') }}
        </v-btn>
      </v-card-actions>
      <div v-show="vKeyboard" style="height: 20em"></div>
    </v-card>
  </v-dialog>
</template>
<script>
import { defs } from "./defaults.js";
export default {
  name: "SettingsEdit",
  model: {
    prop: "dialogModel",
    event: "open",
  },
  props: {
    config: { type: Object },
    vKeyboard: { type: Boolean },
    dialogModel: { type: Number },
    trips: { type: Array },
    paymentsTable: { type: Array },
    travellers: { type: Array }
  },
  data: () => {
    return {
      trip: {},
      newTrip: "",
      tripValid: false,
      tripNameEdit: false,
      withMe: false,
      vfInfo: false,
      dialog: false,
      flags: defs.slaveFlags,
      flagsUp: defs.slaveFlagsUp,
      slider: defs.flagsSlider,
      flag: 63,
      listenerUpdate: false
    };
  },
  updated() {
    if(!this.listenerUpdate) {
      document.getElementById('form-udate')?.addEventListener('submit', (evt) => {
        evt.preventDefault();
        this.listenerUpdate = true;
        return false;
      }, false);
    }
  },  
  computed: { 
    accessInfo() {
      let ai;
      if(this.trip.slave) {
        this.slider.forEach(slide => {
          if(this.trip.flag & slide.topFlag) {
            ai = this.$t(slide.info);
          }
        });
      } else {
        ai = 'Master';
      }
      return ai;
    }
  },
  methods: {
    getTripById(id) {
      return this.trips.find(trip => trip.id == id);
    },    
    tripSelect() {
      this.$emit('trip-select',this.trip);
    },    
    tripEdit() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            if(this.tripValid) {
              this.$emit('trip-change',this.trip);
            }
          });
        });
      });
    },
    tripNameEditor() {
      this.tripNameEdit = true;
      this.tripFocusSelect();
    },
    addTrip() {
      this.vfInfo = false;
      this.$emit('trip-new');
    },
    deleteTrip() {
      this.$emit('trip-delete',this.trip.id);
    },
    closeForm() {
      this.vfInfo = false;
      this.$emit("open", 0);
    },
    validationRules() {
      return {
        required: (value) => !!value || this.$t("message.validation.required"),
        titleLength: (value) =>
          !value || value.length > 3 || this.$t("message.validation.length4"),          
      };
    },
    tripFocusSelect() {
      this.$nextTick(() => {
        let input = document.getElementById('trip-name-input');
        if(input) {
          input.focus();
          input.select();
        }
      });
    },
    setCurrentTrip(focusSelect = false) {
      //console.log('New current trip '+this.config.currentTrip);
      let trp = this.getTripById(this.config.currentTrip);
      if(typeof trp != 'undefined') {
        this.trip = trp;
        if(focusSelect) {
          this.tripNameEdit = true;
          this.tripFocusSelect();
        }
      } else {
        this.trip = {};
      }
      this.$nextTick(() => {
        this.vfInfo = !this.trips.length;
      });
    },
    toggleInfo() {
      this.vfInfo = !this.vfInfo;
    }
  },
  watch: {
    dialogModel: {
      handler(value) {
        if (value) {
          this.tripNameEdit = false;
          this.vfInfo = (this.trips.length) ? false : true;
          this.setCurrentTrip();
        }
        this.dialog = !!value;
      },
    },
  },
};
</script>
<style scoped>
.vf-add-payment {
  color: white;
  font-size: 1.1em !important;  
  font-weight: bold;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.cur-grey {
  background-color: #f0f0f0;
}
.tiny {
  font-size: 0.7em;
}
p.vf-info {
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0;
}
h3.vf-info {
  font-size: 1em;
  margin: 0.3em 0 0 0;
  text-decoration: underline;
}
.vf-iinfo {
  margin: 0.7em 0 0 0 !important;
}
.vf-info-1 {
  margin-top: 1em !important;
}
.vf-info-2 {
  margin-top: 1em !important;
}
</style>
