<template>
  <div :class="'notranslate' + (carouselShow ? ' grey lighten-1' : '')" v-resize="onResize">
    <v-row :class="(payTable.length ? 'vf-balance pa-0 pt-5' : 'pa-0') + ' ma-0'"> &nbsp; </v-row>
    <v-row v-if="payTable.length" class="vf-balance ma-0 pa-0">
      <v-col cols="12" class="px-5 py-0 text-right">
        <v-icon @click.stop="vf2Info = !vf2Info" :color="vf2Info ? 'red' : 'black'">
          mdi-information-outline
        </v-icon>
      </v-col>
      <transition name="fade">
        <v-col cols="12" class="px-5 pt-0 pb-8" v-if="vf2Info">
          <h3 class="vf-2info vf-2info-1">
            {{ $t("message.payment.payTableTitle[0]") }}:
          </h3>            
          <p class="vf-2info">{{$t('message.payment.payTable[0]')}}</p>
          <p class="vf-2info">{{parseSplit($t('message.payment.payTable[1]'),0)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[1]'),1)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[1]'),2)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[1]'),3)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[1]'),4)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[1]'),5)}}</v-icon>
          </p>
          <p class="vf-2info">{{$t('message.payment.payTable[2]')}}</p>
          <h3 class="vf-2info vf-2info-1">
            {{ $t("message.payment.payTableTitle[1]") }}:
          </h3>
          <p class="vf-2info">{{$t('message.payment.payTable[3]')}}</p>
          <p class="vf-2info">{{$t('message.payment.payTable[4]')}}</p>
          <h3 class="vf-2info vf-2info-1">
            {{ $t("message.payment.payTableTitle[2]") }}:
          </h3>
          <p class="vf-2info">
            {{parseSplit($t('message.payment.payTable[6]'),0)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[6]'),1)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[6]'),2)}}</p>
          <p class="vf-2info">{{$t('message.payment.payTable[7]')}}</p>
          <h3 class="vf-2info vf-2info-1">
            {{ $t("message.payment.shareds") }}:
          </h3>
          <p class="vf-2info">{{$t('message.payment.sharedsInfo')}}</p>   
          <h3 class="vf-2info vf-2info-1">
            {{ $t("message.payment.refund") }}:
          </h3>
          <p class="vf-2info">
            {{parseSplit($t('message.payment.payTable[13]'),0)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[13]'),1)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[13]'),2)}}</p> 
          <p class="vf-2info">{{parseSplit($t('message.payment.payTable[5]'),0)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[5]'),1)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[5]'),2)}}</p>
          <p class="vf-2info">{{$t('message.payment.payTable[14]')}}</p>   
          <h3 class="vf-2info vf-2info-1">
            {{ $t("message.payment.payTableTitle[3]") }}:
          </h3>
          <p class="vf-2info">{{$t('message.currency.TitleRateInfo2')}}</p>  
          <p class="vf-2info">{{$t('message.payment.payTable[8]')}}</p>   
          <h3 class="vf-2info vf-2info-1">
            {{ $t("message.payment.payTableTitle[4]") }}
          </h3>
          <p class="vf-2info">{{parseSplit($t('message.payment.payTable[9]'),0)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[9]'),1)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[9]'),2)}}:
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[9]'),3)}}</v-icon> 
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[9]'),4)}}</v-icon> 
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[9]'),5)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[9]'),6)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[9]'),7)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[9]'),8)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[9]'),9)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[9]'),10)}}</p>
          <p class="vf-2info">{{$t('message.payment.payTable[15]')}}</p>
          <h3 class="vf-2info vf-2info-1">
            {{ $t("message.operationList.finalBalance") }}:
          </h3>            
          <p class="vf-2info">{{$t('message.payment.payTable[10]')}}</p>
          <p class="vf-2info">{{$t('message.payment.payTable[11]')}}</p>
          <p class="vf-2info">{{parseSplit($t('message.payment.payTable[12]'),0)}}
            <v-icon color="primary">{{parseSplit($t('message.payment.payTable[12]'),1)}}</v-icon>
            {{parseSplit($t('message.payment.payTable[12]'),2)}}</p>
        </v-col>                  
      </transition>
    </v-row>
    <template v-if="payTable.length">
      <!-- Balance -->
      <transition name="fade">
        <div v-if="balanceExpand" class="ma-0 pa-0">
          <v-row class="vf-balance ma-0 px-0 pt-2 pb-0">
            <v-col cols="11" class="ma-0 px-5 py-0">
              <h3 class="ma-0 pa-0">
                {{ $t("message.operationList.totalBudget") }}:
              </h3>
              <v-switch
                class="mt-0 mb-n4"
                :input-value="privateBudget"
                :false-value="false"
                :label="$t('message.operationList.privateIncluded')"
                @change="privateSwitch"
              >
              </v-switch>
              <p>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{
                      (progressBar * payTable[0].totalBudgetAmount).toFixed(2) + "\xa0" + payTable[0].balanceCurrency
                    }}</span>
                  </template>
                  {{ $t("message.operationList.totalBudgetInfo") }}
                </v-tooltip>
              </p>
              <template v-if="Math.abs(payTable[0].walletAmount) > 0.001">
                <h3 class="ma-0 pa-0">
                  {{ $t("message.operationList.totalWallet") }}:
                </h3>              
                <p>
                  {{(progressBar * payTable[0].walletAmount).toFixed(2) + "\xa0" + payTable[0].balanceCurrency}}
                </p>
              </template>
            </v-col>
            <v-col cols="1" class="ma-0 pl-0 pr-5 py-0 text-right">
              <v-icon
                v-if="balanceExpand"
                @click.stop="balanceExpand = !balanceExpand"
              >
                mdi-chevron-up
              </v-icon>
            </v-col>
          </v-row>
          <v-row class="vf-balance ma-0 pt-0 pb-2">
            <v-col cols="12" class="ma-0 px-5 py-0">
              <h3>{{ $t("message.operationList.budgetPersonal") }}:</h3>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <template v-for="(traveller, idx) in travellers">
              <v-col
                cols="12"
                sm="4"
                md="2"
                v-if="idx>0"
                :key="idx"
                class="pb-0 pt-0 px-5"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <b v-if="traveller.color" :style="'color: '+palette[traveller.color-1]">{{ traveller.name }}:</b>
                      <b v-else :class="userColors[idx - 1] + '--text'">{{ traveller.name }}:</b>
                      <br/>
                      {{(progressBar * payTable[0].totalDebetAmount[idx - 1]).toFixed(2) + "\xa0" + payTable[0].balanceCurrency}}</span>
                  </template>
                  {{ $t("message.operationList.budgetPersonalInfo") }}
                </v-tooltip>
              </v-col>
            </template>
            <v-spacer></v-spacer>
          </v-row>

          <v-row class="vf-balance ma-0 px-0 py-2">
            <v-col cols="12" class="ma-0 px-5 py-0">
              <h3>{{ $t("message.operationList.totalPayd") }}:</h3>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <template v-for="(traveller, idx) in travellers">
              <v-col
                cols="12"
                sm="4"
                md="2"
                v-if="idx>0"
                :key="idx"
                class="ma-0 px-5 py-0"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <b v-if="traveller.color" :style="'color: '+palette[traveller.color-1]">{{ traveller.name }}:</b>
                      <b v-else :class="userColors[idx - 1] + '--text'">{{ traveller.name }}:</b>
                      <br />
                      {{(progressBar * payTable[0].totalCreditAmount[idx - 1]).toFixed(2) + "\xa0" + payTable[0].balanceCurrency}}</span
                    >
                  </template>
                  {{ $t("message.operationList.totalPaydInfo") }}:
                </v-tooltip>
              </v-col>
            </template>
            <v-spacer></v-spacer>
          </v-row>
        </div>
      </transition>

      <v-row class="vf-balance ma-0 px-0 pa-2">
        <v-col cols="11" class="ma-0 px-5 py-0">
          <h3>{{ $t("message.operationList.finalBalance") }}:</h3>
        </v-col>
        <v-col cols="1" class="ma-0 pl-0 pr-5 py-0 text-right">
          <v-icon v-if="!balanceExpand" @click.stop="balanceExpand = !balanceExpand">
            mdi-chevron-down
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <template v-for="(traveller, idx) in travellers">
          <v-col
            cols="12"
            sm="4"
            md="2"
            v-if="idx>0"
            :key="idx"
            class="ma-0 px-5 py-0"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <b v-if="traveller.color" :style="'color: '+palette[traveller.color-1]">{{ traveller.name }}:</b>
                  <b v-else :class="userColors[idx - 1] + '--text'">{{ traveller.name }}:</b>
                  <br />
                  {{ (progressBar * payTable[0].balance.outAmount[idx - 1]).toFixed(2) + "\xa0" + payTable[0].balanceCurrency}}</span
                >
              </template>
              {{ $t("message.operationList.finalBalanceInfo") }}:
            </v-tooltip>
          </v-col>
        </template>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="mt-0 mx-0 px-4">
          <v-tooltip bottom v-if="config.xls && (flag & flags.creator) && (travellers.length > 1) && payments.length">
            <template v-slot:activator="{ on, attrs }"> 
              <v-btn
                v-bind="attrs"
                v-on="on"        
                class="mt-2 mb-2"
                elevation="4"
                color="primary"
                @click.stop="$emit('make-xls')">
                <v-icon large>mdi-microsoft-excel</v-icon>
              </v-btn>        
            </template>
            {{$t('message.trips.saveAsXls')}}
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col cols="12" lg="6" class="mt-0 mx-0 px-4">
          <v-text-field
            type="text"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('message.filter')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Data table -->
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <vf-data-table
            :headers="headers"
            :items="payTable"
            :table="vfTable"
            :mode="compact + updater"
            :filter="search"
            v-model="expandedRow"
          >
            <!-- Date slot -->
            <template v-slot:item-date="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    :class="travellers[item.payer].color ? '' : (userColors[item.payer - 1] + '--text')"
                    :style="travellers[item.payer].color ? ('color: '+palette[travellers[item.payer].color-1]) : ''"
                  >
                    {{
                      compact > 2
                        ? item.dateB
                        : compact > 1
                        ? item.dateA
                        : item.date
                    }}
                  </span>
                </template>
                {{ $t("message.paymentId") }}: {{ item.idx + 1 }}
              </v-tooltip>
            </template>

            <!-- Icon slot -->
            <template v-slot:item-icon="{ item }">
              <payment-icons
                v-if="travellers[item.payer].color"
                :refunded="payTable.length ? payTable[0].permanentPays : []"
                :tcolor="palette[travellers[item.payer].color-1]"
                :item="item"
              >
              </payment-icons>
              <payment-icons
                v-else
                :refunded="payTable.length ? payTable[0].permanentPays : []"
                :color="userColors[item.payer - 1]"
                :item="item"
              >
              </payment-icons>              
            </template>

            <!-- Memo slot -->
            <template v-slot:item-memo="{ item }">
              <span
                :class="travellers[item.payer].color ? '' : (userColors[item.payer - 1] + '--text')"
                :style="travellers[item.payer].color ? ('color: '+palette[travellers[item.payer].color-1]) : ''"              
              >{{
                item.memo
              }}</span>
            </template>

            <!-- Name slot -->
            <template v-slot:item-name="{ item }">
              <span 
                :class="travellers[item.payer].color ? '' : (userColors[item.payer - 1] + '--text')"
                :style="travellers[item.payer].color ? ('color: '+palette[travellers[item.payer].color-1]) : ''"              
              >{{
                item.name
              }}</span>
              <template v-if="item.name2">
                &nbsp;<v-icon>mdi-transfer-right</v-icon>&nbsp;
                <span 
                  :class="travellers[item.receiver].color ? '' : (userColors[item.receiver - 1] + '--text')"
                  :style="travellers[item.receiver].color ? ('color: '+palette[travellers[item.receiver].color-1]) : ''"                
                >{{
                  item.name2
                }}</span>
              </template>
            </template>

            <!-- Amount slot -->
            <template v-slot:item-amount="{ item }">
              <v-tooltip bottom v-if="item.currencyId != currencys[0].id">
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    :class="travellers[item.payer].color ? '' : (userColors[item.payer - 1] + '--text')"
                    :style="travellers[item.payer].color ? ('color: '+palette[travellers[item.payer].color-1]) : ''"                       
                  >
                    {{ item.amount }}
                  </span>
                </template>
                {{ item.balanceAmount }}
              </v-tooltip>
              <span v-else 
                :class="travellers[item.payer].color ? '' : (userColors[item.payer - 1] + '--text')"
                :style="travellers[item.payer].color ? ('color: '+palette[travellers[item.payer].color-1]) : ''"                   
              >
                {{ item.amount }}
              </span>
            </template>

            <!-- Buttons slot -->
            <template v-slot:item-buttons="{ item }">
              <template v-if="
                ((travellers[item.payer].slave != slave) 
                    && (flag & flags.writeAll))
                  || ((travellers[item.payer].slave == slave) 
                    && (flag & flags.writeMy))">
                <v-tooltip bottom z-index="1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="my-0"
                      :color="travellers[item.payer].color ? '' : userColors[item.payer - 1]"
                      :style="travellers[item.payer].color ? ('background-color: '+palette[travellers[item.payer].color-1]) : ''"
                      elevation="4"
                      :disabled="dataReadonly"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="alter(item.idx)"
                    >
                      <v-icon color="white" class="mx-0">mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("message.operationList.editPayment") }}
                </v-tooltip>

                <v-tooltip bottom z-index="1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="mx-1 my-0"
                      :color="travellers[item.payer].color ? '' : userColors[item.payer - 1]"
                      :style="travellers[item.payer].color ? ('background-color: '+palette[travellers[item.payer].color-1]) : ''"                      
                      elevation="4"
                      :disabled="
                        dataReadonly ||
                        !!payTable[0].permanentPays.includes(item.idx)
                      "
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="deletePay(item)"
                    >
                      <v-icon color="white" class="mx-0">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("message.deletePayment") }}
                </v-tooltip>

                <v-tooltip bottom z-index="1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="my-0"
                      :color="travellers[item.payer].color ? '' : userColors[item.payer - 1]"
                      :style="travellers[item.payer].color ? ('background-color: '+palette[travellers[item.payer].color-1]) : ''"                      
                      elevation="4"
                      :disabled="dataReadonly || !item.balance.allowsRefund"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="refundPay(item)"
                    >
                      <v-icon color="white" class="mx-0"
                        >mdi-arrow-u-left-top-bold</v-icon
                      >
                    </v-btn>
                  </template>
                  {{ $t("message.payment.refund") }}
                </v-tooltip>
              </template>
            </template>

            <!-- Expanded slot -->
            <template v-slot:expanded="{ item }">
              <v-row
                v-if="compact > 1"
                class="my-0 mx-n1 pl-2 pr-0 pt-0 pb-3"
              >
                <!-- Buttons  -->
                <template v-if="
                ((travellers[item.payer].slave != slave) 
                    && (flag & flags.writeAll))
                  || ((travellers[item.payer].slave == slave) 
                    && (flag & flags.writeMy))">
                  <!-- Button Edit -->
                  <v-col cols="12" class="text-left ma-0 pa-0">
                    <v-tooltip bottom z-index="1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          :color="travellers[item.payer].color ? '' : userColors[item.payer - 1]"
                          :style="travellers[item.payer].color ? ('background-color: '+palette[travellers[item.payer].color-1]) : ''"                          
                          :disabled="dataReadonly"
                          elevation="4"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="alter(item.idx)"
                        >
                          <v-icon color="white" class="mx-2">mdi-square-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("message.operationList.editPayment") }}
                    </v-tooltip>

                    <!-- Button Del -->
                    <v-tooltip bottom z-index="1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-4"
                          small
                          :color="travellers[item.payer].color ? '' : userColors[item.payer - 1]"
                          :style="travellers[item.payer].color ? ('background-color: '+palette[travellers[item.payer].color-1]) : ''"
                          elevation="4"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="
                            dataReadonly ||
                            !!payTable[0].permanentPays.includes(item.idx)
                          "
                          @click.stop="deletePay(item)"
                        >
                          <v-icon color="white" class="mx-2">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("message.deletePayment") }}
                    </v-tooltip>

                    <!-- Button Refund -->
                    <v-tooltip
                      bottom
                      z-index="1"
                      v-if="!dataReadonly && item.balance.allowsRefund"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          :color="travellers[item.payer].color ? '' : userColors[item.payer - 1]"
                          :style="travellers[item.payer].color ? ('background-color: '+palette[travellers[item.payer].color-1]) : ''"    
                          elevation="4"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="refundPay(item)"
                        >
                          <v-icon color="white" class="mx-2"
                            >mdi-arrow-u-left-top-bold</v-icon
                          >
                        </v-btn>
                      </template>
                      {{ $t("message.payment.refund") }}
                    </v-tooltip>
                  </v-col>
                </template>
              </v-row>

              <v-row
                class="black--text text-left my-0 ml-0 mr-n1 pl-1 pr-0 py-0 font-weight-regular text-body-2"
              >
                <!-- ID -->
                <v-col
                  :cols="compact > 1 ? 12 : 9"
                  sm="6"
                  class="text-left ma-0 pa-0"
                  >{{ $t("message.paymentId") }}: {{ item.idx + 1 }}</v-col
                >
                <v-spacer v-if="compact < 2" />

                <!-- Time -->
                <v-col
                  cols="12"
                  sm="6"
                  v-if="compact > 1"
                  class="text-left ma-0 pa-0"
                  >{{ $t("message.paymentTime") }}: {{ item.time }}</v-col
                >

                <!-- Payer . receiver -->
                <v-col
                  cols="12"
                  sm="6"
                  v-if="compact > 2"
                  class="text-left ma-0 pa-0"
                >
                  {{ item.name }}
                  <template v-if="item.name2">
                    &nbsp;<v-icon>mdi-transfer-right</v-icon>&nbsp;
                    {{ item.name2 }}
                  </template>
                </v-col>

                <!--{{ JSON.stringify(item) }}-->

                <!-- Rate -->
                <v-col
                  cols="12"
                  sm="6"
                  v-if="item.currencyId != currencys[0].id"
                  class="text-left ma-0 pa-0"
                >
                  1 {{ currencys[item.currencyId].name }} = {{ item.rate }}
                  {{ currencys[currencys[0].id].name }}
                </v-col>

                <!-- Amount in ballance cur. -->
                <v-col
                  cols="12"
                  sm="6"
                  v-if="item.currencyId != currencys[0].id"
                  class="text-left ma-0 pa-0"
                >
                  {{
                    $t("message.operationList.balanceCurrencyAmount")
                  }}:&nbsp;{{ item.balanceAmount }}
                </v-col>

                <!-- Payment category -->
                <v-col cols="12" sm="6" class="text-left ma-0 pa-0">
                  {{ item.subtypeText }} <!-- getTransactionById(item.subtype).text -->
                </v-col>

                <!-- Payment description -->
                <v-col
                  cols="12"
                  sm="6"
                  v-if="compact > 0"
                  class="text-left ma-0 pa-0"
                >
                  {{ item.memo }}
                </v-col>

                <!-- Refund payment -->
                <v-col
                  cols="12"
                  v-if="item.shemaLink >= 0"
                  class="text-left ma-0 pa-0 font-weight-regular text-body-2"
                >                
                  <p class="ma-0 pa-0">
                    <v-icon small>
                      mdi-alert-outline
                    </v-icon>                      
                    {{ $t("message.payment.linkedRefund") }}
                  </p>
                  <v-tooltip bottom z-index="1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-0 mt-o mb-2"
                        small
                        :color="travellers[item.payer].color ? '' : userColors[item.payer - 1]"
                        :style="travellers[item.payer].color ? ('background-color: '+palette[travellers[item.payer].color-1]) : ''"    
                        elevation="4"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="dataReadonly"
                        @click.stop="expandPay(item.shemaLink)"      
                      >
                        <v-icon color="white" class="mx-2"
                          >mdi-chevron-triple-left</v-icon
                        >
                      </v-btn>
                    </template>
                    {{ $t("message.payment.toRefunded") }}
                  </v-tooltip>
                </v-col>
                </v-row>

                <!-- Refunded payment -->
                <template v-if="payTable[0].permanentPays.includes(item.idx)">
                  <v-row 
                    v-if="((travellers[item.payer].slave != slave) 
                        && (flag & flags.writeAll))
                      || ((travellers[item.payer].slave == slave) 
                        && (flag & flags.writeMy))"
                    class="black--text text-left my-0 ml-0 mr-n1 pl-1 pr-0 py-0 font-weight-regular text-body-2">
                    <v-col
                      cols="12"
                      class="text-left ma-0 pa-0"
                    >
                      <v-icon small>
                        mdi-alert-outline
                      </v-icon>                    
                      {{ $t("message.payment.unableToDelRefunded") }}
                    </v-col>  
                  </v-row>
                  <v-row v-if="!refundInfo"  class="text-left my-0 ml-0 mr-n1 pl-1 pr-0 py-0 font-weight-regular">                
                    <v-col
                      cols="11"
                      class="text-left mx-0 mt-2 mb-0 pa-0 font-weight-regular primary--text text-body-2"
                      @click.stop="refundInfo = true"
                      style="cursor: pointer"
                    >
                      {{ $t("message.payment.allRefunds") }}
                    </v-col>
                    <v-col cols="1" class="text-right mx-0 mt-2 mb-0 pa-0">
                      <v-icon @click.stop="refundInfo = true">
                        mdi-chevron-down
                      </v-icon>
                    </v-col>
                  </v-row>

                  <!-- Refunded payment extended -->
                  <transition name="fade">
                    <v-row
                      v-if="refundInfo"
                      class="text-left my-2 mx-0 px-1 py-0"
                    >
                      <v-col cols="9" md="6" class="text-left ma-0 py-0 px-0">
                        <v-select
                          dense
                          outlined
                          class="font-weight-regular"
                          v-model="refundRow"
                          :items="getLinkedPays(item.idx)"
                          :label="$t('message.payment.allRefunds')"
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        cols="3"
                        md="6"
                        v-if="refundRow > 0"
                        class="text-left ma-0 py-0 px-1"
                      >
                        <v-tooltip bottom z-index="1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mx-0 mt-o mb-2"
                              :color="travellers[item.payer].color ? '' : userColors[item.payer - 1]"
                              :style="travellers[item.payer].color ? ('background-color: '+palette[travellers[item.payer].color-1]) : ''"    
                              elevation="4"
                              v-bind="attrs"
                              v-on="on"
                              :disabled="dataReadonly"
                              @click.stop="expandPayRow(refundRow-1)" 
                            >
                              <v-icon color="white" class="mx-2"
                                >mdi-chevron-triple-right</v-icon
                              >
                            </v-btn>
                          </template>
                          {{ $t("message.payment.toRefunds") }}
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </transition>
                </template>

              <!-- Balance table info -->
              <v-row class="mx-0 mt-2 mb-0 mr-n1 pa-0 text-body-2">
                <v-col
                  cols="9"
                  class="text-left ma-0 pa-0 pl-1 font-weight-regular primary--text "
                  @click.stop="buhInfo = buhInfo ? (vfInfo = false) : true"
                  style="cursor: pointer"
                >
                  {{ $t((buhInfo || !areShared(item)) ? "message.payment.buhInfo" : "message.payment.shareds") }}
                </v-col>
                <v-col cols="3" class="text-right ma-0 pa-0">
                  <v-icon
                    @click.stop="vfInfoSwitch"
                    :color="vfInfo ? 'white' : (travellers[item.payer].color ? '' : userColors[item.payer - 1])"
                    :style="travellers[item.payer].color ? ('color: '+ (vfInfo ? 'white' : palette[travellers[item.payer].color-1])) : ''"
                  >
                    mdi-information-outline
                  </v-icon>&nbsp;<v-icon v-if="buhInfo" @click.stop="vfInfo = buhInfo = false"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon v-else @click.stop="buhInfo = true"
                    >mdi-chevron-down</v-icon
                  >
                </v-col>
                <transition name="fade">
                  <v-col
                  cols="12"
                  class="text-left ma-0 pa-0"
                  v-show="buhInfo">

                  <transition name="fade">
                      <div
                        v-if="vfInfo"
                        class="black--text text-left mx-0 mt-0 mb-2 px-1 py-0"
                      >
                        <v-divider class="my-2" color="black" />
                        <p class="vf-info">
                          {{ $t("message.payment.aboutBalance") }}
                        </p>
                        <p class="vf-info">
                          {{ $t("message.payment.aboutBalance0") }}
                        </p>
                        <p class="vf-info">
                          {{ $t("message.payment.aboutBalance1") }}
                        </p>
                      </div>
                  </transition>

                    <!-- Balance table wide -->
                    <div v-if="compact < 3" class="ma-o pa-o">
                      <payment-balance 
                        :payment="item"
                        :ping="buhInfo"
                        :balanceCurrency="ballanceCurrency.name" />
                    </div>

                    <!-- Balance table mobile -->
                    <div v-else class="ma-o pa-o">
                      <payment-balance 
                      :payment="item" 
                      :mode="2"
                      :ping="buhInfo"
                      :balanceCurrency="ballanceCurrency.name" />
                    </div>

                  </v-col>
                </transition>
                <transition name="fade">
                  <v-col
                  cols="12"
                  class="text-left ma-0 pa-0"
                  v-show="!buhInfo">
                    <!-- Balance table short -->
                    <div class="ma-o pa-o">
                      <payment-balance 
                      :payment="item" 
                      :mode="3"
                      :ping="!buhInfo"
                      :balanceCurrency="ballanceCurrency.name" />
                    </div>                  
                  </v-col>
                </transition>                
              </v-row>
            </template>
          </vf-data-table>
        </v-col>
        <!-- <p>{{ JSON.stringify(userColors) }}</p> -->
        <v-col class="my-16">&nbsp;</v-col>
      </v-row> 
    </template>

    <!-- No data -->
    <template v-else>
      <v-row v-if="!(flag & flags.creator)" :class="'ma-0 pa-0' + (cordovaApp ? ' mt-6' : '')">
        <v-col cols="12">
          <h1 class="text-center mt-0 grey--text text--lighten-1 vf-await">
            {{ $t(apiBusy ? "message.operationList.processTransactions" : "message.operationList.noTransactions") }}
          <br/><br/><br/></h1>
        </v-col>
      </v-row>
      <v-row v-else class="ma-0 pa-0">
        <template v-if="apiBusy">
          <v-col cols="12" :class="'ma-0 pa-0' + (cordovaApp ? ' mt-6' : '')">
            <h1 class="text-center mt-0 grey--text text--lighten-1 vf-await">
              {{ $t("message.operationList.processTransactions") }}
            <br/><br/><br/></h1>
          </v-col>          
        </template>
        <template v-else>
          <v-spacer/>
          <v-col v-if="!carouselShow" class="ma-0 pa-0 px-8 mt-10" cols="8" sm="6" md="4" lg="3" xl="2">
            <img width="100%" src="../assets/logo2.svg"/>
          </v-col>
          <v-spacer/>
          <v-col v-if="!carouselShow" cols="12" class="ma-0 pa-0 px-8">
              <h3 style="line-height: 120%;" class="text-center mt-8 mb-0 grey--text text--lighten-1">{{$t('message.trips.guideSubtitle1')}}</h3>
              <h3 style="line-height: 120%;" class="text-center my-0 grey--text text--lighten-1">{{$t('message.trips.guideSubtitle2')}}</h3>
          </v-col>
          <v-col cols="12" v-if="!carouselShow" class="ma-0 pa-0 px-8 text-center">
            <v-btn
              class="my-4"
              color="primary"
              elevation="4"
              @click.stop="carouselShowStart"
              >{{$t('message.about.beginButton')}}</v-btn>  
          </v-col>  
          <transition name="fade">
            <v-col cols="12" v-if="carouselShow" :class="'text-center grey lighten-1 ma-0 pa-0' + (cordovaApp ? ' mt-4' : '')">
                <v-carousel 
                  style="height: 560px"
                  class="text-center ma-0 pa-0"
                  :continuous="false"
                  cycle
                  interval="10000"
                  v-model="carouselPage">
                  <v-carousel-item
                    class="text-center ma-0 pa-0"
                    v-for="(crs, crsIdx) in carousel"
                    :key="crs"
                  >
                    <v-sheet
                      color="grey lighten-1"
                      height="100%"
                      class="ma-0 pa-0"
                    >
                      <v-row
                        class="fill-height ma-0 pa-0"
                        align="center"
                        justify="center"
                      >
                        <v-col cols="12" class="ma-0 px-8">
                          <p class="text-center mt-0">{{$t(crs)}}</p>
                        </v-col>                     
                        <v-col cols="12" class="ma-0 pa-0">
                          <img :src="serverUrl+'img/carousel/begin_'+locales[config.locale].text+crsIdx+'.jpg'" style="height: 400px"/>
                        </v-col>                     
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>                 
            </v-col>
          </transition>
          <v-col cols="12" v-if="carouselShow" class="ma-0 pa-0 px-8 text-center">
            <v-btn
              class="my-4"
              color="primary"
              elevation="4"
              @click.stop="carouselShowStop"
              >{{$t('message.about.beginButtonOk')}}</v-btn>  
          </v-col>          
              <!-- <p>1. {{$t('message.about.begin[5]')}}</p>
              <p>2. {{$t('message.about.begin[6]')}}</p>
              <p>3. {{$t('message.about.begin[7]')}}</p>
              <p>4. {{$t('message.about.begin[8]')}}</p>
              <p>5. {{$t('message.about.begin[9]')}}</p> 
              <h3 style="line-height: 120%;" class="text-left my-0 grey--text text--lighten-1">{{$t('message.about.begin[10]')}}<br/><br/><br/><br/></h3>-->
        </template>
      </v-row>
    </template>

    <!-- Delete confirm dialog -->
    <v-dialog persistent v-model="dialogDel" width="500">
      <v-card>
        <v-card-title class="primary vf-add-payment px-5">
          {{ $t("message.operationList.paymentDeletion") }}
        </v-card-title>
        <v-card-text class="px-5">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <h3 class="mt-4 mb-2">
                {{ payToDel.date }} <br />
                {{ payToDel.name }}
                <template v-if="payToDel.name2">
                  &nbsp;<v-icon>mdi-transfer-right</v-icon>&nbsp;
                  {{ payToDel.name2 }}
                </template>
              </h3>
              <v-divider />
              <h3 class="my-2 fv-amount-del">
                {{ payToDel.amount }} {{ payToDel.currency }}
              </h3>
              <v-divider />
              <h4 class="my-2">{{ payToDel.memo }}</h4>
            </v-col>
          </v-row>
          <v-row
            class="ma-0 pa-0"
            v-if="
              payTable.length &&
              payTable[0].permanentPays.includes(payToDel.idx)
            "
          >
            <v-col cols="12" class="ma-0 pa-0">
              <h4>{{ $t("message.payment.unableToDelRefunded") }}</h4>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-3"></v-divider>
        <v-card-actions class="ma-5 mb-0 pa-0 pb-5">
          <v-btn
            :disabled="
              dataReadonly ||
              (!!payTable.length &&
                !!payTable[0].permanentPays.includes(payToDel.idx))
            "
            color="success"
            elevation="4"
            @click.stop="deleteConfirm"
            >{{ $t("message.deletePayment") }}</v-btn>          
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="4"
            @click.stop="closeForm"
            >{{ $t("message.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit module -->
    <payment-alt
      ref="childPaymentAlt"
      v-model="formPaymentAlt"
      :lbls="lbls"
      :payment="dataAlter"
      :id="dataAlterId"
      :shareds="shareds"
      :travellers="travellers"
      :currencys="currencys"
      :transactions="transactions"
      :transactionsRefund="transactionsRefund"
      :vKeyboard="vKeyboard"
      @save="editPay"
      :userColors="userColors"
    ></payment-alt>
  </div>
</template>
<script>
import { defs } from "./defaults.js";
import PaymentAlt from "./PaymentAlt";
import PaymentIcons from "./PaymentIcons";
import PaymentBalance from "./PaymentBalance";
import VfDataTable from "./VfDataTable";
// let shajs = require("sha.js");

export default {
  name: "PaymentList",
  components: {
    PaymentIcons,
    PaymentBalance,
    VfDataTable,
    PaymentAlt,
  },
  model: {
    prop: "privateBudget",
    event: "private",
  },
  props: {
    dataReadonly: { type: Boolean, default: true },
    apiBusy:  { type: Boolean },
    travellers: { type: Array },
    currencys: { type: Array },
    payments: { type: Array },
    payTable: { type: Array },
    lbls: { type: Array },
    shareds: { type: Array },
    transactions: { type: Array },
    transactionsRefund: { type: Array },
    privateBudget: { type: Boolean },
    cordovaApp: { type: Boolean },
    vKeyboard: { type: Boolean },
    active: { type: Boolean },
    slave: { type: Number },
    flag: { type: Number },
    userColors: { type: Array },
    config: { type: Object }
  },
  data() {
    return {
      compact: 0,
      xCompact: false,
      updater: 1,
      balanceExpand: false,
      search: "",
      dataInfo: {},
      refundInfo: false,
      buhInfo: false,
      refundRow: -1,
      expandedRow: -1,
      dataAlter: {},
      dataAlterId: null,
      dialogDel: false,
      payToDel: false,
      totalBudget: 0,
      vfInfo: false,
      vf2Info: false,
      carouselPage: 0,
      carouselShow: false,
      carousel: [
          'message.about.begin[5]',
          'message.about.begin[6]',
          'message.about.begin[7]',
          'message.about.begin[8]',
          'message.about.begin[9]',
          'message.about.begin[10]'
      ],
      formPaymentAlt: 0,
      vfTable: {
        class: "elevation-10",
        rowClassHeader: "primary white--text font-weight-bold ",
        rowClassOdd: "white",
        rowClassEven: "grey lighten-3",
        rowClass: "black--text",
        rowClassHover: "grey lighten-2",
        // rowClassHoverOdd: 'grey lighten-2',
        // rowClassHoverEven: 'grey lighten-2'
        rowClassExpanded: "blue lighten-4",
        rowClassExpandedScope: "blue lighten-4",
        colClassExpandedScope: "px-4 py-2 text-center",
      },
      headersBase: defs.paytableHeaders,
      // matrix: defs.matrix,
      // getMatrix: [-1, 0, 0, 0, 1, 6, 2, 3, 4, 5, 1, 6], // by payment subtype      
      flags: defs.slaveFlags,
      serverUrl: defs.serverUrl,
      locales: defs.locales,
      palette: defs.colorPalette.colors,  
      progressBarTimer: null,
      progressBar: 1       
      // processTable: 0      // процесс обработки данных 0 - ok, 1 - in process, 2 -empty
    };
  },
  computed: {
    showExpand() {
      return this.compact > 0;
    },
    headers() {
      let list = structuredClone(this.headersBase);
      list.forEach((item) => {
        item.text = item.text.length ? this.$t(item.text) : "";
      });
      if(this.flag == this.flags.read) {
        list.splice(5,1);
      }
      return list;
    },
    ballanceCurrency() {
      let currency = structuredClone(this.currencys[this.currencys[0].id]);
      currency.id = this.currencys[0].id;
      return currency;
    },
    slaveTravellerIdx() {
      return this.travellers.findIndex(trv => trv.slave == this.slave);
    }
  },
  mounted() {
    this.onResize();
    // this.refreshData(0);
  },
  methods: {   
    startProgressBar() {
      if(this.progressBarTimer) {
        window.clearInterval(this.progressBarTimer);
      }
      this.progressBar = 0;
      this.progressBarTimer = window.setInterval(() => {
        if(this.progressBar < 0.99) {
          this.progressBar += 0.05;
        } else {
          window.clearInterval(this.progressBarTimer);
          this.progressBarTimer = null;
          this.progressBar = 1;
        }
      }, 25);
    },     
    areShared(pay) {
      if(!this.areDebets(pay)) {
        return false;
      } else if(typeof pay?.balance?.shemaPart == 'object') {
        let parts = 0;
        pay?.balance?.shemaPart.forEach((shema) => {
          if(shema.part > 0) {
            parts++;
          }
        });
        return (parts > 1) ? true : false;
      } else {
        return false;
      }
    },
    areDebets(pay) {
      let debets = false;
      if(pay?.balance?.debetN) {
        pay?.balance?.debetN.forEach((dbt) => {
          if(dbt) {
            debets = true;
          }
        });
      }
      return debets;
    },
    carouselShowStart() {
      this.carouselPage = 0;
      this.carouselShow = 1;
      this.$emit('carouselOpen',true);
    },
    carouselShowStop() {
      this.carouselPage = 0;
      this.carouselShow = 0;
      this.$emit('carouselOpen',false);
    },    
    parseSplit(txt, idx) {
      let parts = txt.split('$');
      if(parts.length < (idx+1)) {
        parts[idx] = '*';
      } 
      return parts[idx];
    },    
    closeForm() {
      this.$emit("dialogOpen",false);
      this.dialogDel = false;
      this.formPaymentAlt = 0;
    },    
    getLinkedPays(id) {
      let linked = [];
      this.payTable.forEach((pay, idx) => {
        if (pay.shemaLink == id) {
          linked.push({
            text: this.payTable[idx].amount + " • " + this.payTable[idx].name,
            value: idx + 1,
          });
        }
      });
      return linked;
    },
    expandPay(id) {
      this.expandedRow = -1;
      this.payTable.forEach((row, idx) => {
        if (row.idx == id) {
          this.expandPayRow(idx);
        }
      });
    },
    expandPayRow(id) {
      this.vfInfo = false;
      this.vf2Info = false;
      this.expandedRow = id;
      this.buhInfo = false;
      this.$vuetify.goTo(document.getElementById('data-table-'+id),{offset: 50});
    },    
    getTransactionById(id) {
      id = parseInt(id);
      let a = [1, 2, 4, 5, 6, 8, 9, 11, 12];
      let b = [0, 1];
      return id <= a.length
        ? this.transactions[a[id - 1]]
        : this.transactionsRefund[b[id - 10]];
    },
    vfInfoSwitch() {
      if(this.vfInfo) {
        this.vfInfo = false;
      } else {
        this.vfInfo = true;
        this.buhInfo = true;
      }
    },   
    privateSwitch(event) {
      this.$emit("private", event);
      this.startProgressBar();
    },
    /*
    byMatrix(code, part) {
      return (
        (code & 1 ? part : 0) +
        (code & 2 ? 1 : 0) +
        (code & 4 ? -part : 0) +
        (code & 8 ? -1 : 0) +
        (code & 16 ? part : 0) +
        (code & 32 ? 1 : 0) +
        (code & 64 ? -part : 0) +
        (code & 128 ? -1 : 0)
      );
    },
    shemaPartsNormalise(shema, sum) {
      let result = [];
      shema.forEach((elem) => {
        result.push( {part:elem, parts:sum} );
      });
      return result;
    },
    */       
    alter(item) {
      this.dataAlterId = item;
      this.dataAlter = this.payments[item];
      this.formPaymentAlt++;
    },
    deleteConfirm() {
      this.$emit("dialogOpen",false);
      this.dialogDel = false;
      this.$emit("delete", this.payToDel.idx);
      this.updater++;
    },
    deletePay(pay) {
      this.expandedRow = -1;
      this.payToDel = pay;
      this.$emit("dialogOpen",true);
      this.dialogDel = true;
    },
    editPay(pay)  {
      // console.log('Updating: '+ JSON.stringify(pay));
      if (typeof pay.id != "undefined") {
        let x = this.payments[pay.id];
        x.amount = parseFloat(pay.type == 2 ? -pay.amount : pay.amount);
        x.shema = pay.type == 3 ? 3 : parseInt(pay.shema);
        x.shemas = pay.shemas;
        x.currency = parseInt(pay.currency);
        x.rate = parseFloat(pay.rate); // this.currencys[parseInt(pay.currency)].rate;
        x.memo = pay.description;
        x.icon = pay.icon;
        x.payer = parseInt(pay.payer);
        this.$emit("change",pay.id);
        this.updater++;        
      }
    },
    refundPay(pay) {
      this.$emit("refund", pay);
    },
    vfColor(item) {
      return item.amount > 0 ? "blue" : "red";
    },
    onResize() {
      this.compact =
        window.innerWidth < 600
          ? 3
          : window.innerWidth < 960
          ? 2
          : window.innerWidth < 1264
          ? 1
          : 0;
      this.xCompact = (window.innerWidth < 390) ? true : false;
    },
    toggleInfo() {
      if(this.formPaymentAlt) {
        this.$refs.childPaymentAlt.toggleInfo();
      } else {
        if(this.expandedRow > -1) {
          if(!this.vfInfo) {
            this.buhInfo = true;
          }
          this.vfInfo = !this.vfInfo;
        }
      }
    },
    /*
    refreshData() { //value = 1) {
      this.processTable = value;
      this.$emit("process",value);
      console.log('Paytable statement: '+this.processTable);
    }
    */
  },
  watch: { 
    balanceExpand: {
      handler() {
        this.startProgressBar();
      }
    },
    search: {
      handler() {  // Закрыть все диалоги
        this.refundInfo = false;
        this.buhInfo = false;
        this.refundRow = -1;
        this.expandedRow = -1;      
        this.vfInfo = false;
        this.vf2Info = false;
      }
    },
    active: {
      handler(value) {
        if(!value) {  // Закрыть все диалоги
          this.refundInfo = false;
          this.buhInfo = false;
          this.refundRow = -1;
          this.expandedRow = -1;      
          this.vfInfo = false;
          this.vf2Info = false;
          this.carouselShow = 0;
        }
      }
    },
    formPaymentAlt: {
      handler(value) {
        this.$emit("dialogOpen",!!value);
      },
    },
    expandedRow: {
      handler(value) {
        this.$emit('expandOpen',(value > -1) ? true : false);
        this.refundInfo = false;
        this.refundRow = null;
        this.buhInfo = false;
        this.vfInfo = false;
      },
    },
    compact: {
      handler() {
        this.vfInfo = false;
        this.vf2Info = false;
      },
    },
    payTable: {
      handler() {   // (NewPays, OldPays)
        //this.$emit("balance", this.payTable);
        let x = this.travellers;
        x.forEach((trv, id) => {
          if (id) {
            x[id].permanent = this.payTable.length
              ? this.payTable[0].permanentUsers[id]
              : false;
          }
        });
        let y = this.currencys;
        y.forEach((cur, id) => {
          if (id) {
            y[id].permanent = this.payTable.length
              ? this.payTable[0].permanentCurrencys[id]
              : false;
          }
        });
        this.vfInfo = false;
        this.vf2Info = false;
        /*
        if(this.processTable == 1) {
          this.refreshData(NewPays.length ? 0 : 2);
        }
        */
        this.startProgressBar();
      },
      deep: true,
    },
  },
};
</script>
<style lang="css">
.vf-row {
  font-weight: bold;
}
.vf-header {
  color: blue;
  font-size: 1.2em !important;
  background-color: #a0ffff;
}
.vf-add-payment {
  color: white;
  font-size: 1.1em !important;  
  font-weight: bold;  
}
.vf-balance {
  background-color: #f0f0f0;
}
.fv-amount-del {
  color: red;
}
.amountEdit {
  height: 25px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.fade-move {
  transition: transform 1s;
}
p.vf-info {
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0;
}
.vf-await {
  font-size: 1.5em !important;
}
.pointer {
  cursor: pointer;
}
p.vf-2info {
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0;
  text-align: justify;
}
h3.vf-2info {
  font-size: 1em; 
  margin: 0.3em 0 0 0;
  text-decoration: underline;
}
h3.vf-2info-1 {
  margin-top: 1em;
}
</style>
