<template>
  <span>
    <template v-if="tcolor">
      <v-icon v-if="item.balance.refund" :style="'color:'+tcolor">mdi-arrow-u-left-top-bold</v-icon>
      <v-icon v-if="paymentType(item) == 3" :style="'color:'+tcolor">mdi-swap-horizontal-bold</v-icon>
      <v-icon v-if="item.balance.private" :style="'color:'+tcolor">mdi-home-import-outline</v-icon>
      <v-icon v-if="item.shema" :style="'color:'+tcolor">{{ item.shema }}</v-icon>
      <v-icon v-if="item.icon" :style="'color:'+tcolor">{{ item.icon }}</v-icon>
    </template>
    <template v-else>
      <v-icon v-if="item.balance.refund" :color="color">mdi-arrow-u-left-top-bold</v-icon>
      <v-icon v-if="paymentType(item) == 3" :color="color">mdi-swap-horizontal-bold</v-icon>
      <v-icon v-if="item.balance.private" :color="color">mdi-home-import-outline</v-icon>
      <v-icon v-if="item.shema" :color="color">{{ item.shema }}</v-icon>
      <v-icon v-if="item.icon" :color="color">{{ item.icon }}</v-icon>
    </template>
    <v-icon v-if="!item.icon" color="grey lighten-1">mdi-cash</v-icon>
    <v-icon v-if="refunded.includes(item.idx)" color="grey lighten-1">mdi-arrow-u-left-top-bold</v-icon>
  </span>
</template>
<script>
import { defs } from "./defaults.js";
export default {
  name: "PaymentIcons",
  props: {
    item: { type: Object },
    tcolor: { type: String, default: null },
    color: { type: String, default: 'primary' },
    refunded: { type: Array }
  },
  data() {
    return {
      transactions: defs.transactions,
      transactionsRefund: defs.transactionsRefund
    }
  },
  methods: {
    paymentType(pay) {
      return this.getTransactionById(pay.subtype).type;
    },
    getTransactionById(id) {
      id = parseInt(id);
      let a = [1, 2, 4, 5, 6, 8, 9, 11, 12];
      let b = [0, 1];
      return id <= a.length
        ? this.transactions[a[id - 1]]
        : this.transactionsRefund[b[id - 10]];
    },    
  }
}
</script>