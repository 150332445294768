export const cordovaApp = false;
export const webApp = true;
export const appVersion = '1.3.8';
export const appVersionServer = '010308';
export const appVersionApi = '010301';
export const uiVersion = '230816';
export const supportEmail = 'support@tripcount.click';
export const CDCordova = function() {};
export const CDFileTransfer = function() {};
export const CDNavigator = function() {};
export const CDCordovaWebsocketPlugin = function() {};
export const CDMedia = function() {};
export const CDDevice = function() {};
export const CDLocalFileSystem = function() {};
export const CDPurchase = function() {};   // CdvPurchase
export const CDPurchases = function() {};   // Purchases
export const CDLocale = function() {};          // Locale

/*
Sound=new p(  => Sound=new Media(
l.InAppBrowser => cordova.InAppBrowser
l.plugins => cordova.plugins
d.vibrate => navigator.vibrate
m.uuid: => device.uuid:
d.app.overrideButton => navigator.app.overrideButton
let a=new c,s=encodeURI( => let fileTransfer=new FileTransfer,s=encodeURI(
*/