import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { cordovaApp, webApp } from './components/cordova.js';
import { apiPing } from "./Api4.js";
const LOGO_URL = 'https://www.tripcount.click/img/logo2i.svg';
const BG_URL = 'https://www.tripcount.click/img/bg/bg_';
const WEB_ABOUT_ROOT_URL = 'https://www.tripcount.click/about';
let splashHtml = `<h1 id="vf-title">Connecting ...</h1>
<img id="vf-intro" src="` + LOGO_URL + `"/>
<style>
h1 {
  color: white;
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 1.4em;
  margin-top: 15%;
  animation: 2s vf-title ease;
  animation-iteration-count: infinite;
  animation-fill-mode: both;  
  opacity: 0.5;
}
#vf-intro {
  animation: 3s vf-show ease;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
@keyframes vf-title {
  0%   { opacity: 0.3; }
  15%  { opacity: 1; }
  100%   { opacity: 0.3; }
}
@keyframes vf-show-w {
  0%   { opacity: 0; margin-top: 0%; margin-left: 45%; width: 10% }
  20%  { opacity: 0.8; margin-top: -8%; margin-left: 5%; width: 90% }
  40%  { opacity: 0.5; margin-top: -15%; margin-left: 35%; width: 30% }
  100% { opacity: 0; margin-top: -50%; margin-left: -460%; width: 1000% }
}
@keyframes vf-show {
  0%   { opacity: 0; margin-top: 0%; margin-left: 45%; width: 10% }
  20%  { opacity: 1; margin-top: 30%; margin-left: 5%; width: 90% }
  40%  { opacity: 0.5; margin-top: 30%; margin-left: 35%; width: 30% }
  100% { opacity: 0; margin-top: -50%; margin-left: -460%; width: 1000% }
}
</style>`;
let waiter = null;
let appHtml = document.getElementById('app');
let appHt = document.querySelector('html');
let app_reboot = cordovaApp ? true : ((typeof APP_REBOOT == 'undefined') ? false : true);

let logo;
function rootPrepare() {
  let dt = new Date();
  let idx = Math.ceil(parseFloat(dt.getTime().toString().substring(12,13)) * 0.555);  // 0.....5  
  //let idx = Math.ceil(Math.random() * 4);
  appHt.style.overflowY = 'hidden';
  appHtml.style.backgroundColor = '#1976D2';
  appHtml.style.height = '100vh';
  appHtml.style.margin = '0';
  appHtml.style.padding = '0';
  appHtml.style.textAlign = 'left';
  appHtml.style.overflowX = 'hidden';
  appHtml.style.overflowY = 'hidden';
  appHtml.style.backgroundRepeat = 'repeat-x';
  appHtml.style.backgroundSize = 'auto 100%';
  appHtml.style.backgroundPosition = 'center';
  appHtml.style.backgroundImage = 'url("'+BG_URL+idx+'.jpg")';
  logo = new Image();
}
Vue.config.productionTip = false;

function rootPing() {
  apiPing().then((ok) => {
    if(ok) {
      if(waiter) {
        window.clearInterval(waiter);
      }
      rootVue();
    } else {
      document.getElementById('vf-title').innerHTML = 'Internet connection lost';
    }
  });
}

function rootVue() {
  appHtml.innerHTML = '';
  appHt.style.overflowY = 'scroll';  
  new Vue({
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app');  
}

if(cordovaApp) {
  document.addEventListener("deviceready",() => { 
    rootVue();
  }, false);
} else {
  if(webApp && !window.localStorage.getItem('config') && !window.app_intent) {    // Если нет config загружаем лендинг
    window.location.replace(WEB_ABOUT_ROOT_URL);
  } else {                                          // Есть config - работает web приложение
    if(!app_reboot) {
      rootPrepare();
      logo.onload = () => {
        if(window.innerWidth > 600) {
          splashHtml = splashHtml.replace(/3s vf-show ease/,'4s vf-show-w ease');
        }      
        appHtml.innerHTML = splashHtml;
      }  
      logo.src = LOGO_URL;
      waiter = window.setInterval(rootPing, 3000);
    } else {
      rootPing();
    }
  }
}
