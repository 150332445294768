<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card>
      <v-card-title class="px-5 primary vf-add-payment">
        {{ $t("message.payment.newPayment") }}
        <v-spacer />
        <v-icon @click.stop="vfInfo = !vfInfo" :color="vfInfo ? 'amber' : 'white'"
          >mdi-information-outline</v-icon
        >
      </v-card-title>
      <v-card-text class="px-5">
        <transition name="fade">
          <v-row class="ma-0 pa-0" v-if="vfInfo">
            <v-col cols="12" class="ma-0 pa-0">
              <h3 class="vf-info vf-info-1">
                {{ $t("message.payment.paymentType") }}:
              </h3>
              <p class="vf-info">{{ $t("message.payment.paymentTypeInfo") }}</p>
              <h3 class="vf-info">
                {{ $t("message.amount") }}:
              </h3>
              <p class="vf-info">{{ $t("message.payment.amountInfo") }}</p>
              <h3 class="vf-info">
                {{ $t("message.navbar.currency") }}:
              </h3>
              <p class="vf-info">{{ $t("message.payment.currencyInfo") }}</p>
              <h3 class="vf-info">
                {{ $t("message.payment.shareds") }}:
              </h3>
              <p class="vf-info">{{ $t("message.payment.sharedsInfo") }}</p>
              <h3 class="vf-info">
                {{ $t("message.payment.refund") }}:
              </h3>
              <p class="vf-info">{{ $t("message.payment.refundInfo") }}</p>
              <h3 class="vf-info">
                {{ $t("message.payment.errorsEdit") }}:
              </h3>
              <p class="vf-info">{{ $t("message.currency.TitleRateInfo2") }}</p>      
            </v-col>      
          </v-row>
        </transition>
        <v-form id="form-udate" ref="pform" v-model="formValid" action="#" validate-on="blur" @submit="() => false">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="mt-8 mb-0 pa-0">
              <v-select
                @input="typeSelect"
                class="my-0"
                dense
                :items="transactionsList"
                :label="$t('message.payment.type')"
                v-model="form.data.transaction"
                :rules="[validationRules().required]"
                return-object
                outlined
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="mt-0 mb-0 pa-0">
              <v-select
                v-if="form.data.transaction"
                class="my-0"
                :readonly="form.data.transaction.iconFixed"
                @input="iconSelect"
                dense
                :items="iconLabels"
                :label="$t('message.payment.icon')"
                v-model="form.data.icon"
                :rules="[]"
                outlined
              >
                <template slot="selection" slot-scope="data">
                  <v-icon color="primary">{{ data.item.icon }}</v-icon
                  ><!-- {{ data.item.text }}-->
                </template>
                <template slot="item" slot-scope="data">
                  <v-icon color="primary">{{ data.item.icon }}</v-icon
                  ><!-- {{ data.item.text }}-->
                </template>
              </v-select>
            </v-col>
            <v-spacer />
            <v-col cols="12" class="mt-0 mb-0 pa-0">
              <p class="mt-0 px-0" v-if="form.data.transaction.type">
                <v-icon color="blue">mdi-information</v-icon>
                  <vf-expantion-text 
                    v-model="expantionText"
                    :text="form.data.transaction.info"
                    :length="47"
                    :from-length="700"
                    :option="$t('message.more')"
                    exp-class="primary--text">
                  </vf-expantion-text>
              </p>
              <v-select
                dense
                :items="payers"
                :label="$t((form.data.transaction.type == 2) ? 'message.payment.receiver' : 'message.payment.payer')"
                v-model="form.data.payer"
                :rules="[validationRules().required]"
                outlined
                :readonly="!!payerFixed"
              >
              </v-select>
              <v-select
                dense
                v-if="form.data.transaction.type == 3"
                :items="receivers"
                :label="$t('message.payment.receiver')"
                v-model="form.data.receiver"
                :rules="[validationRules().required]"
                outlined
              >
              </v-select>
            </v-col>
            <v-col cols="5" class="ma-0 pa-0">
              <v-text-field
                dense
                type="number"
                v-model="form.data.amount"
                :label="$t('message.amount')"
                :rules="[validationRules().required, validationRules().number]"
                hide-details="auto"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" class="ma-0 pa-0">
              <v-select
                dense
                @input="currencySelect"
                class="ma-0 pa-0"
                :items="mints"
                :rules="[validationRules().required]"
                :hint="Math.round(form.data.amount) > 0 ? amountFinal : rateSample"
                :label="$t('message.navbar.currency')"
                v-model="form.data.currency"
                :value="currencys[0].id"
                outlined
                persistent-hint
                return-object
                single-line
              >
              </v-select>
            </v-col>
            <p
              class="mt-0 px-3"
              v-if="false &&
                (flag & flags.banker) &&
                form.data.currency.value &&
                (form.data.currency.value != currencys[0].id)
              "
            >
              <v-icon color="blue">mdi-information</v-icon>
              {{ $t("message.payment.actualRates") }}
            </p>
          </v-row> 
          <transition name="fade">
            <v-row v-if="form.data.currency.value != currencys[0].id" class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0">
                <v-radio-group v-model="rateFrom" class="ma-0 pa-0">
                  <template v-slot:label>
                    <div>{{ parseSplit($t('message.currency.rateSource'),0) }} {{form.data.currency.text}} {{ parseSplit($t('message.currency.rateSource'),1) }}:</div>
                  </template>
                  <v-radio :value="0" class="ma-0 my-2 pa-0">
                    <template v-slot:label>
                      <div><b class="primary--text">{{ currencys[form.data.currency.value].rate }}</b> ({{ $t('message.currency.rateSourceMaster') }})</div>
                    </template>
                  </v-radio>
                  <v-radio :value="1" v-if="currencys[form.data.currency.value].forex" class="ma-0 my-2 pa-0">
                    <template v-slot:label>
                      <div><b class="primary--text">{{ currencys[form.data.currency.value].forex }}</b> ({{ $t('message.currency.rateSourceForex') }})</div>
                    </template>
                  </v-radio>
                  <v-radio :value="2" class="ma-0 my-2 pa-0">
                    <template v-slot:label>
                      <div>{{ $t('message.currency.rateSourceCustom') }}</div>
                    </template>
                  </v-radio>              
                </v-radio-group>
              </v-col>
              <transition name="fade">
                <v-col v-if="rateFrom == 2" cols="12" class="ma-0 mb-6 pa-0">
                  <v-col cols="5" class="ma-0 pa-0">
                    <v-text-field
                      class="ma-0 pa-0"
                      dense
                      type="number"
                      v-model="form.data.rate"
                      :label="$t('message.currency.rate')+' '+form.data.currency.text"
                      :rules="[validationRules().required, validationRules().number]"
                      hide-details="auto"
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-col>
              </transition>
            </v-row>
          </transition>
          <template v-if="shareShemasAllowed">
            <v-row class="ma-0 pa-0">
              <v-col :cols="12" class="ma-0 pa-0">
                <v-select
                  class="mt-0 mx-0"
                  dense
                  :items="shareShemas"
                  :label="$t('message.members.shareds')"
                  v-model="form.data.shema"
                  outlined
                  persistent-hint
                  @input="shareSelect"
                >
                </v-select>
              </v-col>
              <transition name="fade">
                <v-col cols="12" class="ma-0 pa-0" v-if="form.data.shema == 3" @click.stop="sliderUnfix">
                  <template v-for="(trv, idx) in travellers">
                    <div class="mx-0 mb-0 mt-4" :key="idx" v-if="idx > 0">
                      <div class="ma-0">
                        <p v-if="trv.color" :style="'color: '+palette[trv.color-1]">{{ trv.name }}</p>
                        <p v-else :class="userColors[idx-1]+'--text'">{{ trv.name }}</p>
                      </div>
                      <v-slider                     
                        class="mt-n8 mb-n4"
                        style="margin-left: 30%"
                        v-model="slider[idx - 1]"
                        label=""
                        :thumb-color="((cordovaApp && sliderFixer) ?  'blue lighten-2' : 'primary')"
                        thumb-label="always"
                        thumb-size="32"
                        min="0"
                        :max="sliderMax"
                        :readonly="(cordovaApp && sliderFixer)"
                        ticks
                      ></v-slider>
                    </div>
                  </template>
                </v-col>
                <v-col key="share-list" cols="12" class="ma-0 pa-0" v-else>
                  <table class="ma-0 pa-0">
                    <tr v-for="(item, idx) in travellers" :key="idx">
                      <td v-if="idx && item.color" class="pa-0 pt-1 text-left" :style="'color: '+palette[item.color-1]">{{ item.name }}</td>
                      <td v-if="idx && !item.color" :class="userColors[idx-1]+'--text pa-0 pt-1 text-left'">{{ item.name }}</td>
                      <td v-if="idx" style="width:100%" :class="userColors[idx-1]+'--text pa-0 pt-1 pl-2 text-right'">
                        <bar-part
                          :part="item.shema[form.data.shema]"
                          :total="totalParts"
                          :progress="progressBar"
                          :tcolor="item.color ? palette[item.color-1] : null"
                          :color="userColors[idx-1]">
                        </bar-part>
                      </td>                    
                    </tr>
                  </table>                  
                </v-col>                
              </transition>
              <transition name="fade" class="my-0 py-0">
                <v-col cols="12" v-if="sliderIsNull" class="my-0 py-0">
                  <h4 class="my-0 py-0"><v-icon color="blue">mdi-alert</v-icon> {{ $t('message.payment.somebodyMust')}}</h4>
                </v-col>
              </transition>              
            </v-row>
            <transition name="fade">
              <v-row v-if="(form.data.icon > 0) && (flag & flags.master)" class="mt-0 mb-3 ">
                <v-col cols="12" class="mt-0 py-0">
                  <v-switch 
                    v-model="form.data.linkTo" 
                    :label="$t('message.payment.linkRuleToIcon')"
                    ></v-switch>
                </v-col>            
              </v-row>
            </transition>
          </template>
          <template v-if="shareShemaBaseOnly">
            <v-row class="ma-0 pa-0 mb-4">
              <transition name="fade">
                <v-col cols="12" class="ma-0 pa-0">
                  <p class="mt-0 pa-0">
                    <v-icon color="blue">mdi-information</v-icon>
                    {{ $t("message.payments.shemaWallet") }}
                  </p>
                  <table class="ma-0 pa-0">
                    <tr v-for="(item, idx) in travellers" :key="idx">
                      <td v-if="idx && item.color" class="pa-0 pt-1 text-left" :style="'color: '+palette[item.color-1]">{{ item.name }}</td>
                      <td v-if="idx && !item.color" :class="userColors[idx-1]+'--text pa-0 pt-1 text-left'">{{ item.name }}</td>
                      <td v-if="idx" style="width:100%" :class="userColors[idx-1]+'--text pa-0 pt-1 pl-2 text-right'">
                        <bar-part
                          :part="item.shema[form.data.shema]"
                          :total="totalParts"
                          :progress="progressBar"
                          :tcolor="item.color ? palette[item.color-1] : null"
                          :color="userColors[idx-1]">
                        </bar-part>
                      </td>
                    </tr>
                  </table>                   
                </v-col>
              </transition>              
            </v-row>
          </template>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <v-textarea
                v-model="form.data.description"
                @input="form.data.descriptionChanged = true"
                :label="form.data.transaction ? form.data.descriptionLabel : $t('message.payment.description')"
                :rules="[validationRules().required, validationRules().length]"
                hide-details="auto"
                outlined
                no-resize
                height="100px"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider class="mt-0"></v-divider>

      <v-card-actions class="ma-5 pa-0">
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="4" @click.stop="clearForm">
          {{ $t("message.clear") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions class="ma-5 mb-0 pa-0 pb-5">
        <v-btn
          color="success"
          :disabled="!formValid || (shareShemasAllowed && sliderIsNull)"
          elevation="4"
          @click.stop="save"
        >
          {{ $t("message.save") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="4" @click.stop="closeForm">
          {{ $t("message.close") }}
        </v-btn>
      </v-card-actions>
      <div v-show="vKeyboard" style="height:20em">
      </div>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="green darken-4"
      absolute
      center
      rounded="pill"
      top
      class="mt-12"
    >
      <v-icon color="white">mdi-content-save-edit</v-icon>&nbsp;
      <strong>{{ $t("message.saved_") }}</strong>
    </v-snackbar>
  </v-dialog>
</template>
<script>
import { defs } from "./defaults.js";
import VfExpantionText from "./VfExpantionText";
import BarPart from "./BarPart";
export default {
  name: "PaymentAdd",
  model: {
    prop: 'dialogModel',
    event: 'open'
  },    
  components: {
    VfExpantionText,
    BarPart 
  },  
  props: {
    travellers: { type: Array },
    cordovaApp: { type: Boolean },
    currencys: { type: Array },
    lbls: { type: Array },
    shareds: { type: Array },
    transactions: { type: Array },
    vKeyboard: { type: Boolean},
    dialogModel: { type: Number},
    slave: { type: Number },
    flag: { type: Number },
    userColors: { type: Array },
    privateBudget: { type: Boolean}
  },
  data: () => {
    return {
      snackbar: false,
      formValid: false,
      rateFrom: 0,
      form: {
        data: {
          icon: null,
          transaction: 0,
          payer: 0,
          receiver: 0,
          amount: 0,
          currency: {
            value: 0
          },
          rate: 1,         
          shema: 0,
          shemas: [],
          description: "",
          descriptionLabel: "",
          descriptionChanged: false, 
          linkTo: false
        },
      },
      slider: [],
      sliderMax: 10,
      sliderFixer: true,
      vfInfo: false,
      dialog: false,
      expantionText: false,

      // walletAllowed: true,
      // shoppingAllowed: true,
      flags: defs.slaveFlags,
      palette: defs.colorPalette.colors,
      listenerUpdate: false, 
      progressBarTimer: null,
      progressBar: 1    
    };
  },
  updated() {
    if(!this.listenerUpdate) {
      document.getElementById('form-udate')?.addEventListener('submit', (evt) => {
        evt.preventDefault();
        this.listenerUpdate = true;
        return false;
      }, false);
    }
  },  
  computed: {
    iconLabels() {
      let icons = [];
      this.lbls.forEach((label) => {
        if(this.form.data?.transaction?.iconFlag & label.flags) {
          icons.push(label);
        }
      });
      return icons;
    },
    totalParts() {
      let total = 0;
      this.travellers.forEach((trv, idx) => {
        if(idx) {
          total += trv.shema[this.form.data.shema];
        }
      });
      return total;
    },
    payerFixed() {
      return (this.flag & this.flags.writeAll) ? 0 : this.getTravellerBySlave();
    },
    transactionsList() {
      return this.transactions.filter(item =>
        !item.banned
        && (!item.privateOnly || this.privateBudget)
        //&& ((!item.wallet || this.walletAllowed)
        //&& (!item.shopping || this.shoppingAllowed))
      );
    },
    sliderIsNull() {
      let result = true;
      this.slider.forEach((sld) => {
        if(sld > 0) {
          result = false;
        }
      });
      return result;
    },    
    shareShemaBaseOnly() {
      return (this.form.data.transaction.wallet &&
      (this.form.data.transaction.type == 1)) ? true : false;
    },
    shareShemasAllowed() {
      return (
        (typeof this.form.data.transaction.shema == 'undefined') &&     // No fixed shemas
        (this.form.data.transaction.type == 1)) ? true : false;         // General payments only
    },
    shareShemas() {
      let list = structuredClone(this.shareds);
      list.forEach((shr, idx) => {
        shr.text = this.travellers[0].shema[idx];
      });
      list.push({
        text: this.$t("message.members.free"),
        value: 3,
      });
      return list;
    },
    payers() {
      let payers = [];
      this.travellers.forEach((trv, idx) => {
        if (trv.allowed) {
          payers.push({ text: trv.name, value: idx });
        }
      });
      return payers;
    },
    receivers() {
      let receivers = [];
      this.payers.forEach((rcv) => {
        if (rcv.value != this.form.data.payer) {
          receivers.push(rcv);
        }
      });
      return receivers;
    },
    mints() {
      let mints = [];
      this.currencys.forEach((mnt, idx) => {
        if (mnt.allowed) {
          mints.push({ text: mnt.name, value: idx });
        }
      });
      return mints;
    },
    actualRate() {
      let rate = 1;
      switch(this.rateFrom) {
        case 0:
          rate = this.currencys[this.form.data.currency.value].rate;
          break;
        case 1:
          rate = this.currencys[this.form.data.currency.value].forex;
          break;
        case 2:
          rate = this.form.data.rate;
          break;          
      }
      return rate;
    },
    rateSample() {
      if (
        this.form.data.currency.value &&
        this.form.data.currency.value != this.currencys[0].id
      ) {
        return (
          "1 " +
          this.form.data.currency.text +
          " = " +
          this.actualRate +
          //this.currencys[this.form.data.currency.value].rate +
          " " +
          this.currencys[this.currencys[0].id].name
        );
      }
      return "";
    },
    amountFinal() {
      if (
        this.form.data.currency.value &&
        this.form.data.currency.value != this.currencys[0].id
      ) {
        return (
          (
            this.form.data.amount *
            this.actualRate
            // this.currencys[this.form.data.currency.value].rate
          ).toFixed(2) +
          " " +
          this.currencys[this.currencys[0].id].name
        );
      }
      return "";
    },
  },
  methods: {
    startProgressBar() {
      if(this.progressBarTimer) {
        window.clearInterval(this.progressBarTimer);
      }
      this.progressBar = 0;
      this.progressBarTimer = window.setInterval(() => {
        if(this.progressBar < 0.99) {
          this.progressBar += 0.025;
        } else {
          window.clearInterval(this.progressBarTimer);
          this.progressBarTimer = null;
          this.progressBar = 1;
        }
      }, 25);
    },
    sliderUnfix() {
      this.sliderFixer = false;
      window.setTimeout(()=>{
        this.sliderFixer = true;
      },3000);
    },
    parseSplit(txt, idx) {
      let parts = txt.split('$');
      if(parts.length < (idx+1)) {
        parts[idx] = '*';
      } 
      return parts[idx];
    },
    currencySelect() {
      this.rateFrom = 0;
      this.form.data.rate = this.currencys[this.form.data.currency.value].rate;
    },
    getTravellerBySlave() {
      let trv = null;
      this.travellers.forEach((trav, id) => {
        if(trav.slave == this.slave) {
          trv = id;
        }
      });
      return trv;
    },
    validationRules() {
      return {
        required: (value) => !!value || this.$t("message.validation.required"),
        number: (value) =>
          (parseFloat(value) > 0) || this.$t("message.validation.numberPos0"),
        length: (value) =>
          (value && value.length > 2) || this.$t("message.validation.length3")
      };
    },
    init() {
      // console.log('LINKS INIT');
      // console.log(JSON.stringify(this.travellers[0].links));  
      this.slider = new Array(this.travellers.length - 1);
      this.slider.fill(1);          
      this.vfInfo = false;
      this.expantionText = false;   
      this.rateFrom = 0;
      this.form = {
        data: {
          icon: 0,
          transaction: 0,
          payer: this.payerFixed ? this.payerFixed : 0,
          receiver: 0,
          amount: 0,
          currency: {
            text: this.currencys[this.currencys[0].id].name,
            value: this.currencys[0].id,
          },
          rate: 0,          
          shema: 0,
          shemas: [],
          description: "",
          descriptionChanged: false,
          linkTo: false,
        },
      };
    },

    save() {
      let shema = parseInt(this.form.data.shema); // доли по выбранной схеме
      let shemas = (shema == 3) ? structuredClone(this.slider) : null; // произвольные доли
      if(typeof this.form.data.transaction.shema != 'undefined') {
        let pshema = this.form.data.transaction.shema;
        if(pshema >= 0) {   // доли по фиксированной схеме
          shema = this.form.data.transaction.shema;
        } else {            // персональная доля 100%
          shema = 3;
          shemas = new Array(this.travellers.length - 1);
          shemas.fill(0);          
          switch(pshema) {
            case -1:      // перевод
              shemas[this.form.data.receiver-1] = 1;
              break;
            case -2:      // личная покупка
              shemas[this.form.data.payer-1] = 1;
              break;              
          }
        }
      }
      this.$emit("save", {
        type: this.form.data.transaction.type,
        subtype: this.form.data.transaction.value,
        icon: this.form.data.icon,        
        payer: this.form.data.payer,
        rate: this.actualRate,
        // receiver: this.form.data.receiver,
        amount: this.form.data.amount,
        currency: this.form.data.currency.value,
        shema: parseInt(shema),
        shemas: shemas,
        setLink: this.form.data.linkTo,
        description: this.form.data.description.trim()
      });

      this.clearForm();
      this.snackbar = true;
    },
    closeForm() {
      if(this.dialog) {
        this.clearForm();
      }
      this.$emit("open",0);
    },
    clearForm() {
      this.init();
      this.$refs.pform.resetValidation();
    },
    shareSelect() {       // Проверяет нет ли связи иконки с выбранной схемой. Если она произвольная, то корректирует слайдер
      this.form.data.linkTo = false;
      if(typeof (this.travellers[0].links[this.form.data.icon]) != 'undefined') {   // С этой иконкой есть связь на схему
        let links = this.travellers[0].links[this.form.data.icon];
        if(typeof links == 'object') {                                // Произвольная - сравнить
          if(this.form.data.shema == 3) {
            this.slider = structuredClone(links);
            this.form.data.linkTo = true;
          }
        } else if(this.form.data.shema == links) {                    // Фиксированная
          this.form.data.linkTo = true;
        }
      }
      this.startProgressBar();
    },
    typeSelect() {    // Переключает иконку при выборе типа платежа
      this.form.data.icon = this.form.data.transaction.icon;
      this.iconSelect();
      this.startProgressBar();
    },
    iconSelect() {    // Формирует описание платежа и включает связанную с иконкой схему дележки, если есть.
      this.form.data.shema = 0;
      //console.log('LINKS');
      //console.log(JSON.stringify(this.travellers[0].links));
      if (!this.form.data.descriptionChanged ||
        !this.form.data.description.length) {       // Корректируем описание платежа под иконку, если оно еще нетронутое
        this.form.data.description =
          this.lbls[this.form.data.icon - 1].text + ". ";
        this.form.data.descriptionChanged = false;
      } 
      
      this.form.data.descriptionLabel = this.lbls[this.form.data.icon - 1].text;
      if(typeof (this.travellers[0].links[this.form.data.icon]) != 'undefined') {   // С этой иконкой есть связь на схему
        let links = this.travellers[0].links[this.form.data.icon];
        if(typeof links == 'object') {                                // Произвольная
          this.form.data.shema = 3;
          this.slider = structuredClone(links);
        } else {                                                      // Фиксированная
          this.form.data.shema = links;
        }
        this.form.data.linkTo = true;
      } else {
        this.form.data.linkTo = false;                                // Нет связи
      }
      this.startProgressBar();
    },
    toggleInfo() {
      this.vfInfo = !this.vfInfo;
    }
  },
  watch: {
    dialogModel: {
      handler(value) {
        if(value) {
          this.init();
        }
        this.dialog = !!value;
      }
    },    
    dialog: function () {
      if (this.formValid) {
        // this.$refs.pform.reset(); // Validation();
        this.$refs.pform.resetValidation();
      }
    },
    shareShemasAllowed: {
      handler() {
        this.startProgressBar();
      }
    }
  }
};
</script>
<style scoped>
.vf-add-payment {
  color: white;
  font-size: 1.1em !important;  
  font-weight: bold;  
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.fade-move {
  transition: transform 1s;
}
p.vf-info {
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0;
}
.vf-memo {
  font-size: 0.9em;
}
h3.vf-info {
  font-size: 1em;
  margin: 0.3em 0 0 0;
  text-decoration: underline;
}
h3.vf-info-1 {
  margin-top: 1em;
}
.line-compact {
  line-height: 0.9em !important;
}
</style>
