<template>
  <v-dialog persistent v-model="dialog" width="500" ref="travellersCard">
    <v-card>
      <v-card-title class="primary vf-add-payment px-5">
        {{ $t("message.members.travellersPayers") }}
        <v-spacer />
        <v-icon @click.stop="vfInfo = !vfInfo" :color="vfInfo ? 'amber' : 'white'"
          >mdi-information-outline</v-icon
        >
      </v-card-title>
      <v-card-text class="pa-0">
        <!-- {{ JSON.stringify(travellers) }} -->
        <transition name="fade">
          <v-row class="mx-5 mt-0 mb-4 pa-0" v-if="vfInfo">
            <v-col cols="12" class="ma-0 pa-0" >
              <h3 class="vf-info vf-info-1">{{ $t("message.members.namePeoplesQty") }}:</h3>
              <p class="vf-info">{{ $t("message.members.namePeoplesQtyInfo") }}</p>
              <p class="vf-info">{{ $t("message.members.namePeoplesQtyInfo0") }}</p>
              <p class="vf-info vf-info-2">{{ $t("message.members.namePeoplesQtyInfo1") }}</p>
              <p class="vf-info vf-info-2">{{ $t("message.members.namePeoplesQtyInfo2") }}</p>
              <p class="vf-info vf-info-2">{{ $t("message.members.namePeoplesQtyInfo3") }}</p>
              <h3 class="vf-info">{{ $t("message.members.activation") }}:</h3>
              <p class="vf-info">{{ $t("message.members.activationInfo") }}</p>
              <h3 class="vf-info">{{ $t("message.members.addDelete") }}:</h3>
              <p class="vf-info">{{ $t("message.members.addDeleteInfo") }}</p>
              <p class="vf-info vf-info-2">{{ $t("message.members.addDeleteInfo1") }}</p>
              <p class="vf-info vf-info-2">{{ $t("message.members.addDeleteInfo2") }}</p>
            </v-col>
          </v-row>
        </transition>
        <v-form id="form-udate" class="ma-0" v-model="trvValid" action="#" validate-on="blur" @submit="() => false">
          <v-row v-if="travellers.length > 1" :class="'ma-0 px-5 pt-8 pb-0 ' + ((masterOptions > 0)  ? 'grey' : '')">
            <v-col :cols="12" class="ma-0 pa-0" v-if="!shareEdit">
              <v-select
                class="ma-0"
                dense
                :items="shareNames"
                :label="$t('message.members.shareds')"
                v-model="shareShema"
                append-outer-icon="mdi-square-edit-outline"
                @click:append-outer="shareEditStart"
                outlined>
              </v-select>
            </v-col>
            <v-col :cols="12" class="ma-0 pa-0 text-left" v-if="shareEdit">
              <v-text-field
                type="text"
                class="ma-0"
                id="share-name-input"
                dense
                v-model="shemaNames[shareShema]"
                :label="$t('message.members.ruleName')+' '+shemasTitles[shareShema]"
                :rules="[validationRules().required, validationRules().length]"
                @input="travellerEdit"
                :append-outer-icon="(trvValid && sliderValid)? 'mdi-check' : 'mdi-alert-outline'"
                @click:append-outer="shareEdit = (trvValid && sliderValid) ? false : true"
                outlined>
              </v-text-field>
            </v-col>      
          </v-row>
          <v-row :class="'ma-0 px-5 pb-4 ' + ((masterOptions > 0)  ? 'grey' : '')" v-if="!sliderValid">
            <v-col cols="12" class="text-left ma-0 pa-0 red">
              <p class="ma-0 pa-2 yellow--text">
                <v-icon color="yellow">mdi-alert-outline</v-icon> &nbsp;
                {{$t("message.payment.somebodyMust")}}
              </p>
            </v-col>
          </v-row>
          <template v-for="(traveller, idx) in travellers">
            <v-row :class=" 'ma-0 px-5 py-0 ' + (((masterOptions > 0) && (masterOptions != idx)) ? 'grey' : '')" :key="idx" v-if="idx > 0">
              <v-col :cols="10" class="ma-0 mb-0 pa-0">
                <v-text-field
                  type="text"
                  :id="'traveller-name-'+idx"
                  class="mt-3"
                  dense
                  v-model="traveller.name"
                  :label="$t('message.name')"
                  :rules="[validationRules().required, validationRules().length]"
                  hide-details="auto"
                  @input="travellerEdit"
                  outlined
                >
                <template #append>
                  <v-icon 
                    v-if="traveller.color"
                    :style="'color: '+palette.colors[traveller.color-1]"
                    @click.stop="openPalette(idx)"
                    >mdi-square-rounded
                  </v-icon>
                  <v-icon 
                    v-else
                    :color="userColors[idx-1]"
                    @click.stop="openPalette(idx)"
                    >mdi-square-rounded
                  </v-icon>                  
                </template>
                </v-text-field>
                <transition name="fade" :key="'a_'+idx" >
                  <vf-color-picker
                    v-if="activePalette == idx"
                    :palette="palette"
                    v-model="traveller.color"
                    @sel="openPalette(0)"
                  />
                </transition>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" class="mt-5 mb-0 pa-0 text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="mb-n10 pa-0"
                      v-bind="attrs"
                      v-on="on"> 
                        <v-icon v-if="!sliderValid || !trvValid || !changeAllowed || (traveller.slave == slave)" color="grey lighten-1">
                          mdi-delete
                        </v-icon>
                        <v-icon v-else color="primary" @click.stop="travellerDel(traveller.slave)">
                          mdi-delete
                        </v-icon>
                    </div>
                  </template>
                  {{ $t((!changeAllowed || (traveller.slave == slave)) ? 'message.members.cantDelete' : 'message.delete') }}
                </v-tooltip>
              </v-col>            
            </v-row>
            <v-row :class="'ma-0 px-5 py-0 text-right ' + (((masterOptions > 0) && (masterOptions != idx)) ? 'grey' : '')" :key="'_'+idx" v-if="idx > 0">
              <v-col cols="1" class="mt-11 pa-0 text-left">
                <img src="../assets/pie.svg" height="24"/>
              </v-col>
              <v-col cols="9" class="ma-0 pl-0 py-0 pr-1 text-left" @click.stop="sliderUnfix(idx)">
                <v-tooltip bottom nudge-left="0" nudge-top="35">
                  <template v-slot:activator="{ on, attrs }">  
                    <div
                      class="ma-0 pa-0"
                      v-bind="attrs"
                      v-on="on">                               
                      <v-slider                
                        class="mx-0 mb-n4 mt-11"
                        label=""
                        v-model="traveller.shema[shareShema]"
                        @change="travellerEdit"
                        :thumb-color="(!cordovaApp || (sliderFixer == idx)) ? 'primary' : 'blue lighten-2'"
                        thumb-label="always"
                        thumb-size="32"
                        min="0"
                        max="10"
                        :readonly="traveller.shema_fixed || (cordovaApp && (sliderFixer != idx)) || !trvValid"
                        ticks>
                      </v-slider>
                    </div>
                  </template>
                  {{ $t('message.members.sharePart') }}
                </v-tooltip>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" class="mb-0 mt-7 mx-0 pa-0">
                <v-tooltip bottom nudge-left="0" nudge-top="20">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="mb-n10 pa-0"
                      v-bind="attrs"
                      v-on="on">                  
                      <v-switch      
                        class="ml-2"       
                        v-model="traveller.allowed"
                        @change="travellerEdit"
                        style="width: 120px"
                        :disabled="!sliderValid || !trvValid || (traveller.slave == slave)"
                      ></v-switch>
                    </div>
                  </template>
                  {{ $t((traveller.slave == slave) ? 'message.members.selfInactive' : (traveller.allowed ? 'message.members.active' : 'message.members.inactive')) }}
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row :class="'ma-0 px-5 pt-0 pb-7 ' + (((masterOptions > 0) && (masterOptions != idx)) ? 'grey' : '')" :key="'1_'+idx" v-if="(idx > 0) && (flag & flags.creator)">
                <v-col cols="9" class="ma-0 pa-0 text-left primary--text pointer" @click.stop="masterOptions = masterOptions ? 0 : idx">
                  {{$t('message.members.masterOptions')}}
                </v-col>
                <v-col cols="3" class="ma-0 pa-0 text-right">
                  <v-icon class="ma-0" v-if="masterOptions == idx" @click.stop="masterOptions = 0">
                    mdi-chevron-up
                  </v-icon>
                  <v-icon class="ma-0" v-else @click.stop="masterOptions = idx">
                    mdi-chevron-down
                  </v-icon>  
                </v-col>  
            </v-row>         
            <transition name="fade" :key="'2_'+idx" >
              <v-row class="ma-0 mt-10 px-5 py-0" v-if="(idx > 0) && (masterOptions == idx)">
                  <v-col cols="7" class="ma-0 pa-0 text-left">
                    <v-tooltip bottom nudge-left="0" nudge-top="35">
                      <template v-slot:activator="{ on, attrs }">  
                        <div
                          class="ma-0 pa-0"
                          v-bind="attrs"
                          v-on="on">                  
                          <v-slider
                            class="ma-0"
                            label=""
                            v-model="sliderFlags[idx - 1]"
                            @input="sliderChange(idx - 1)"
                            thumb-color="primary"
                            thumb-label="always"
                            :readonly="!trvValid || !sliderValid"
                            min="0"
                            max="3"
                            ticks>
                            <template v-slot:thumb-label="props">
                              <v-icon dark>{{ slider[props.value].icon }}</v-icon>
                            </template>
                          </v-slider>
                        </div>
                      </template>
                      {{ $t('message.members.permissions') }}
                    </v-tooltip>
                    <p class="mt-n8 mb-1 pa-0">{{ $t(sliderInfo[idx - 1]) }}</p>
                  </v-col>
                  <v-spacer/>
                  <v-col cols="3" v-if="trvValid && sliderValid" class="ma-0 pa-0 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="ma-0"
                          color="primary"
                          elevation="4"
                          small
                          @click.stop="$emit('invite',traveller)">
                          <v-icon>mdi-account-plus</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("message.trips.invite") }}
                    </v-tooltip>
                  </v-col>
                </v-row>
            </transition>
            <v-divider :key="'2_'+idx" class="ma-0"></v-divider>
          </template>
        </v-form>
        <transition name="fade">
          <v-row v-if="addUserAndvanced" class="mx-5 my-0 mt-4 pa-0">
            <v-col cols="12" class="ma-0 pa-0" >
              <p v-html="$t('message.members.addAdvanced')"></p>
              <v-radio-group v-model="addUserAndvanced" class="ma-0 pa-0">
                <v-radio v-for="(txt, idx) in $t('message.members.addAdvanceds')" :value="idx+1" class="ma-0 my-2 pa-0" :key="idx" @click="travellersScroll">
                  <template v-slot:label>
                    <p class="ma-0 pa-0 primary--text">{{ txt }}</p>
                  </template>
                </v-radio>
              </v-radio-group>
              <p v-if="addUserAndvanced > 0" class="primary--text ma-0 pa-0"><b>{{$t('message.members.addAdvanced1')}}</b></p>
              <transition name="fade">
                <ul class="my-0 pt-0 pb-4" >
                  <template v-for="(txt, idx) in $t('message.members.addAdvancedsOptions')">
                    <li v-if="advancedOptionsFlags[addUserAndvanced] & parseInt(txt[0])" class="ma-0 pa-0" :key="idx" v-html="txt[1]">
                    </li>
                  </template>
                </ul>
              </transition>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade">
          <v-row v-if="addUserAndvanced==2" class="mx-5 my-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0" >
              <p v-if="balance.walletAmount <= 0" class="ma-0 pa-0">{{$t('message.members.negativeWallet')}}</p>
              <v-radio-group v-model="addUserAndvancedWallet" class="ma-0 pa-0">
                <v-radio :disabled="balance.walletAmount < 0" :value="1" class="ma-0 my-2 pa-0" @click="travellersScroll">
                  <template v-slot:label>
                    <p class="ma-0 pa-0">{{ $t('message.members.addAdvancedsWallet[0]') }}</p>
                  </template>
                </v-radio>
                <v-radio :disabled="balance.walletAmount <= 0" :value="2" class="ma-0 my-2 pa-0" @click="travellersScroll">
                  <template v-slot:label>
                    <p class="ma-0 pa-0">{{ $t('message.members.addAdvancedsWallet[1]') }}</p>
                  </template>
                </v-radio>  
                <v-radio :value="3" class="ma-0 my-2 pa-0" @click="travellersScroll">
                  <template v-slot:label>
                    <p class="ma-0 pa-0">{{ $t('message.members.addAdvancedsWallet[2]') }}</p>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade">
          <v-row v-if="(addUserAndvanced==2) && (addUserAndvancedWallet > 1)" class="mx-5 my-0 pa-0">
            <v-col cols="10">
              <v-form class="ma-0 pa-0" v-model="addUserAndvancedWalletValid" action="#" validate-on="blur" @submit="() => false">
                <v-text-field
                  type="text"
                  class="mt-0 mb-4"
                  dense
                  v-model="addUserAndvancedValue"
                  :label="$t('message.members.addAdvancedsValue') + ' ('+currencys[currencys[0].id].name+')'"
                  :rules="(addUserAndvancedWallet == 2) ? 
                    [validationRules().required, validationRules().numbermax] : 
                    [validationRules().required, validationRules().numberx]"
                  hide-details="auto"
                  outlined
                />
              </v-form>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade">
          <v-row v-if="(addUserAndvanced >0) && allowPlusCheck" class="mx-5 my-0 mb-6 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <v-checkbox
                class="ma-0 pa-0"
                hide-details
                v-model="allowPlusCheckOk"
                :label="$t('message.members.addAdvancedOk')"/>
            </v-col>
          </v-row>
        </transition>        
      </v-card-text>
      <v-divider class="ma-0"></v-divider>
      <v-card-actions class="ma-5 mb-0 pa-0 pb-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              ref="travellerAdd"
              v-bind="attrs"
              v-on="on"        
              v-if="travellers.length < maxMembers + 1"
              color="primary"
              elevation="4"
              :disabled="!allowPlus"
              @click.stop="travellerAdd"
            >
              <v-icon large>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              v-else 
              elevation="4" 
              disabled>
              <v-icon large>mdi-plus</v-icon>
            </v-btn>            
          </template>
          {{ $t("message.members.addTraveller") }}
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn class="my-2" color="primary" elevation="4" :disabled="!trvValid || !sliderValid" @click="closeForm">
          {{ $t("message.close") }}
        </v-btn>
      </v-card-actions>
      <div v-if="vKeyboard" style="height: 20em"></div>
      <div ref="dialogBottom" class="ma-0 pa-0"></div>
    </v-card>
  </v-dialog>
</template>
<script>
import VfColorPicker from "./VfColorPicker";
import { defs } from "./defaults.js";
export default {
  name: "TravellersEdit",
  components: {
    VfColorPicker
  },
  model: {
    prop: "dialogModel",
    event: "open",
  },
  props: {
    travellers: { type: Array },
    maxMembers: { type: Number, default: 16 },
    changeAllowed: { type: Boolean },
    vKeyboard: { type: Boolean },
    cordovaApp: { type: Boolean },
    dialogModel: { type: Number },
    slave: { type: Number },
    flag: { type: Number },
    userColors: { type: Array },
    balance: { type: Object },
    currencys: { type: Array },
  },
  data: () => {
    return {
      shareShema: 0,
      shareEdit: false,
      vfInfo: false,
      masterOptions: 0,
      dialog: false,
      observer: false,
      slider: defs.flagsSlider,
      sliderId: defs.slaveFlagsId, // translate flagValue -> sliderId
      sliderFlags: [], // slider v-models
      sliderFixer: 0,
      sliderInfo: [], // slider titles
      flags: defs.slaveFlags,
      palette: defs.colorPalette,
      activePalette: 0,
      addTrigger: false,
      trvValid: false,
      shemasTitles: defs.shemas,
      listenerUpdate: false,

      addUserAndvanced: 0,
      allowPlusCheckOk: false,
      advancedAllowed: true,  // Разрешено добавление новых юзеров при наличии платежей
      addUserAndvancedWallet: 0,  // Способ ведения кошелька 0-нет 1-дебетный 2-кредитный
      addUserAndvancedWalletValid: false, // Валидатор баланса кошелька
      addUserAndvancedValue: 0,   // Баланс кошелька при сложном добавлении юзера
      advancedOptionsFlags: [0,1,2,4]
    };
  },
  updated() {
    if(!this.listenerUpdate) {
      document.getElementById('form-udate')?.addEventListener('submit', (evt) => {
        evt.preventDefault();
        this.listenerUpdate = true;
        return false;
      }, false);
    }
  },  
  computed: {
    allowPlusCheck() {
      return (((this.addUserAndvanced != -1) && (this.addUserAndvanced != 2))  // Простые правила добавления участника
            || ((this.addUserAndvanced == 2) && (this.addUserAndvancedWallet == 1))  // Сложное правило с нулевым кошельком
            || ((this.addUserAndvanced == 2) && (this.addUserAndvancedWallet > 1) && this.addUserAndvancedWalletValid))  // Сложное правило и указан баланс кошелька
    },    
    allowPlus() {
      return ((this.advancedAllowed 
        && ((this.allowPlusCheck && this.allowPlusCheckOk) 
          || !this.addUserAndvanced))
        || this.changeAllowed) 
        && this.sliderValid 
        && this.trvValid;
    },
    shemaUpdates() {
      let data = 0;
      let id = 1;
      this.travellers.forEach((trv)=>{
        for(let idx=0; idx<3; idx++) {
          data += parseInt(trv.shema[idx])*id;
          id++;
        }
      });
      return data;
    },
    sliderValid() {
      return ((this.travellers.length < 2) || this.travellers.reduce((partialSum, trv, idx) => partialSum + (idx ? trv.shema[this.shareShema] : 0), 0)) ? true : false;
    },
    shemaNames() {
      return this.travellers[0].shema;
    },
    shareNames() {
      let share = [];
      this.travellers[0].shema.forEach((name,idx)=>{
        share.push({
          value: idx,
          text: this.shemasTitles[idx] + ': '+name
        });
      });
      return share;
    }    
  },
  methods: {
    openPalette(id) {
      this.activePalette = (this.activePalette == id) ? 0 : id;
      this.travellerEdit();
    },
    sliderUnfix(id) {
      this.sliderFixer = id;
      window.setTimeout(()=>{
        this.sliderFixer = 0;
      },3000);
    },
    shareEditStart() {
      this.shareEdit = true;
      this.$nextTick(() => {
        let input = document.getElementById('share-name-input');
        if(input) {
          input.focus();
          input.select();
        }
      });
    },
    flagsRefresh() {
      // slider v-models inits
      let x = this.travellers;
      this.sliderFlags = new Array(this.travellers.length - 1);
      this.sliderInfo = new Array(this.travellers.length - 1);
      this.travellers.forEach((trv, id) => {
        if (id > 0) {
          let slId = this.sliderId[trv.flag];
          if (typeof slId != "undefined") {
            this.sliderFlags[id - 1] = this.sliderId[trv.flag];
            this.sliderInfo[id - 1] =
              this.slider[this.sliderFlags[id - 1]].info;
          } else {
            x[id].flag = this.flags.read;
            this.sliderFlags[id - 1] = 0;
            this.sliderInfo[id - 1] = this.slider[0].info;
          }
        }
      });
    },
    sliderChange(idx) {
      // alert(this.slider[this.sliderFlags[idx]].info);
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            if(this.sliderValid && this.trvValid) {
              let x = this.travellers;
              x[idx+1].flag = this.slider[this.sliderFlags[idx]].flag;
              this.sliderInfo[idx] = this.slider[this.sliderFlags[idx]].info;
              this.$emit("change");
            }
          });
        });
      });
    },
    closeForm() {
      this.observer = false;
      this.masterOptions = 0;
      this.$emit("open", 0);
    },
    validationRules() {
      return {
        required: (value) => !!value || this.$t("message.validation.required"),
        number: (value) =>
          (parseInt(value) == parseFloat(value) && parseInt(value) >= 0) ||
          this.$t("message.validation.integerWith0"),
        numberx: value => (parseFloat(value) > 0) || this.$t('message.validation.numberPos0'),
        numbermax: value => ((parseFloat(value) > 0) && ((parseFloat(value) <= this.balance.walletAmount))) 
          || this.$t('message.validation.numberWallet'),
        length: (value) =>
          (value && value.length > 1) || this.$t("message.validation.length2"),
      };
    },
    travellerEdit() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            if(this.trvValid && this.sliderValid) {
              this.$emit('change');
            }
          });
        });
      });
    },
    travellerDel(id) {
      this.addTrigger = true;
      this.$emit('del',id);
    },
    travellerAdd() {
      let data = {
        rule: this.changeAllowed ? 0 : this.addUserAndvanced,
        wallet: this.addUserAndvancedWallet,
        wallet_amount: parseFloat(this.addUserAndvancedValue),
        // wallet_rest: 
      };
      if(this.changeAllowed || (this.addUserAndvanced > 0)) {
        this.addTrigger = true;
        this.$emit('add', data);
        this.addUserAndvanced = 0;
        this.travellersScroll();
      } else if(this.addUserAndvanced <= 0) {
        this.allowPlusCheckOk = false;
        this.addUserAndvancedWallet = 0;
        this.addUserAndvancedValue = 0;
        this.addUserAndvanced = this.addUserAndvanced ? 0 : -1;
        this.travellersScroll();
      }
    },
    travellersScroll() {
      this.allowPlusCheckOk = false;
      this.$vuetify.goTo(this.$refs.dialogBottom, { 
        container: document.querySelector('.v-dialog--active') 
      });
    },
    travellerFocusSelect(id) {
      this.$nextTick(() => {
        let input = document.getElementById('traveller-name-'+id);
        if(input) {
          input.focus();
          input.select();
          this.travellersScroll();
        }
      });
    },
    toggleInfo() {
      this.vfInfo = !this.vfInfo;
    }
  },
  watch: {
    shemaUpdates: {
      handler(newValue, oldValue) {
        if(newValue != oldValue) {
          this.$emit('resample');
        }
      }
    },
    travellers: {
      handler(newValue, oldValue) {
        this.sliderFixer = 0;
        this.flagsRefresh();
        if(this.addTrigger) {
          this.addTrigger = false;
          if(newValue.length > oldValue.length) {     // добавлен 
            this.travellerFocusSelect(newValue.length-1);
          } else if(newValue.length < oldValue.length) { // удален
            this.$nextTick( () => {
              // console.log('Deleted');
              this.travellersScroll();
            });
          }
        }
      },
      deep: true
    },
    dialogModel: {
      handler(value) {
        this.addUserAndvanced = 0;  
        if(value) {
          this.flagsRefresh();
        }
        this.shareShema = 0;
        this.vfInfo = false;
        this.activePalette = 0;
        this.masterOptions = 0;        
        this.dialog = !!value;
      },
    },
    addUserAndvancedWallet: {
      handler(value) {
        if(value == 2) {
          this.addUserAndvancedValue = this.balance.walletAmount;
        }
        if(value == 3) {
          this.addUserAndvancedValue = 0;
        }        
      }
    },
    addUserAndvancedValue: {
      handler() {
        this.travellersScroll();
      }
    }
  },
};
</script>
<style scoped>
.vf-add-payment {
  color: white;
  font-size: 1.1em !important;  
  font-weight: bold;  
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
p.vf-info {
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0;
}
h3.vf-info {
  font-size: 1em;
  margin: 0.3em 0 0 0;
  text-decoration: underline;
}
h3.vf-info-1 {
  margin-top: 1em;
}
.vf-info-1 {
  margin-top: 1em !important;
}
.vf-info-2 {
  margin-top: 1em !important;
}
.pointer {
  cursor: pointer;
}
</style>