<template>
  <div class="text-center ma-0 pa-0 py-4" >
    <div v-for="(row, idxx) in palette.rows" :key="idxx" class="ma-0 pa-0">
      <template v-for="idx in row">
        <div :key="idx" 
          :class="(idx == color) ? 'rounded-xl' : 'rounded-lg'" 
          :style="'cursor: pointer; margin: 2px; padding: 0; display: inline-block; min-width: 34px; min-height: 34px; background-color: black; margin-top: '+
          (palette.extraRow == idxx ? ' 10px' : '2px')+'; background-color:'+palette.colors[idx-1]"
          @click.stop="$emit('sel',idx)">
            &nbsp;
        </div>  
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "VfColorPicker",
  model: {
    prop: 'color',
    event: 'sel'
  },    
  props: {  
    color: { type: Number },
    palette: { type: Object }
  }
}
</script>