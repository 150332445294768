<template>
  <table :class="table.class + ' vf-table'" cellspacing="0">
    <tr :class="table.rowClassHeader">
      <th
        v-for="(header, headerIdx) in headers"
        :key="headerIdx"
        :class="header.class"
      >
        <slot :name="'header-' + header.value" :header="header" :id="headerIdx">
          {{ header.text }}<!-- : {{ JSON.stringify(filterables) }}-->
        </slot>
      </th>
    </tr>
    <template v-for="(item, itemIdx) in items">
        <slot name="row" :item="item" :id="itemIdx">
          <v-hover v-slot="{ hover }" >
            <tr
              :id="'data-table-'+itemIdx"
              v-show="byFilter(item)"
              :key="itemIdx"
              @click.stop="switchRow = (switchRow == itemIdx) ? -1 : itemIdx"
              :class="'pointer ' + 
                ((switchRow == itemIdx)
                  ? table.rowClassExpanded
                  : (hover
                  ? table.rowClassHover +
                    ' ' +
                    (itemIdx % 2 ? table.rowClassHoverEven : table.rowClassHoverOdd)
                  : table.rowClass +
                    ' ' +
                    (itemIdx % 2 ? table.rowClassEven : table.rowClassOdd)))
              "
            >
              <td
                v-for="(header, headerIdx) in headers"
                :key="headerIdx"
                :class="
                  (header.itemNoWrap ? 'vf-nowrap ' : '') +
                  header.itemClass +
                  ' ' +
                  (itemIdx % 2 ? header.itemClassEven : header.itemClassOdd)
                "
              >
                <template v-if="headerIdx != switchCol">
                  <slot :name="'item-' + header.value" :item="item" :id="itemIdx" :hd="byFilter(header,item,itemIdx)">
                    {{ item[header.value] }}
                  </slot>
                </template>
                <template v-else>
                  <v-icon
                    v-if="switchRow == itemIdx"
                    :class="header.switchClass"
                    @click.stop="switchRow = -1"
                  >
                    mdi-chevron-up
                  </v-icon>
                  <v-icon
                    v-else
                    :class="header.switchClass"
                    @click.stop="switchRow = itemIdx"
                  >
                    mdi-chevron-down
                  </v-icon>
                </template>
              </td>
            </tr>
          </v-hover>
          <transition name="fade" :key="itemIdx + '_'">
            <slot name="expanded-row" :item="item" :id="itemIdx">
              <tr
                v-if="switchRow == itemIdx"
                :class="table.rowClassExpandedScope"
              >
                <td :colspan="cols" :class="table.colClassExpandedScope">
                  <slot name="expanded" :item="item" :id="itemIdx">
                    Expanded Scope
                  </slot>
                </td>
              </tr>
            </slot>
          </transition>
        </slot>

    </template>
  </table>
</template>
<script>
export default {
  name: "VfDataTable",
  model: {
    prop: 'expanded',
    event: 'onExpand'
  },    
  props: {
    table: { type: Object },
    headers: { type: Array },
    items: { type: Array },
    mode: { type: Number },
    filter: { type: String },
    expanded: { type: Number, default: -1 }
  },
  data() {
    return {
      cols: 0,
      switchRowObserver: true,
      switchRow: -1,
      switchCol: -1,
      bitof: "vf-expanded",
    };
  },
  computed: {
    filterables() {
      let fl = [];
      this.headers.forEach((header) => {
        if(header.filterable) {
          fl.push(header.value);
        }
      });
      return fl;
    }
  },
  mounted() {
    this.searchSwitchSpan();
    this.hidden = new Array(this.items.length);
    this.hidden.fill(false);
  },
  methods: {
    byFilter(item) {
      let show = this.filterables.length ? false : true;
      this.filterables.forEach((name) => {
        if(item[name] && item[name].includes(this.filter)) {
          show = true;
        }
      });
      return show;
    },
    searchSwitchSpan() {
      this.cols = 0;
      this.switchCol = -1;
      this.switchRow = -1;
      this.headers.forEach((header, idx) => {
        if (typeof header.element == "undefined") {
          this.cols++;
        } else {
          switch (header.element) {
            case "switch":
              this.switchCol = idx;
              this.cols++;
              break;
          }
        }
      });
    },
  },
  watch : {
    switchRow: {
      handler(value) {
        if(this.switchRowObserver) {
          this.$emit('onExpand',value);
        }
      }
    },
    expanded: {
      handler(value) {
        this.switchRowObserver = false;
        this.switchRow = value;
        this.switchRowObserver = true;
      }
    },
    mode: {
      handler() {
        this.switchRow = -1;
      }
    },
    items: {
      handler() {
        this.switchRow = -1;
      }
    }
  }
};
</script>
<style scoped>
table.vf-table {
  width: 100%;
}
td.vf-nowrap {
  white-space: nowrap; 
}
td.vf-icon {
  min-width: 50px;
}
td.vf-2icon {
  min-width: 80px;
}
td.vf-3icon {
  min-width: 100px;
}
td.vf-4icon {
  min-width: 160px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.fade-move {
  transition: transform 1s;
}
.pointer {
  cursor: pointer;
}
.w10p {
  width: 10%;
}
.w20p {
  width: 20%;
}
.w30p {
  width: 30%;
}
.w40p {
  width: 40%;
}
.w50p {
  width: 50%;
}
.w60p {
  width: 60%;
}
.w70p {
  width: 70%;
}
.w80p {
  width: 80%;
}
.w90p {
  width: 90%;
}
</style>
