<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-justify">
        <v-row justify="center">
          <v-col class="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-2 mt-10">
            <img style="width: 100%" src="../assets/logo2.svg"/>
          </v-col>
        </v-row>
                  
        <p class="vf-body">{{ $t("message.about.start1") }}</p>
        <v-row justify="center">
          <v-col class="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-parallax
                  class="my-6 transition-swing"
                  :class="hover ? `elevation-20` : `elevation-6`"
                  height="270"
                  src="../assets/shareScreen.jpg"
                ></v-parallax>
              </template>
            </v-hover>
          </v-col>
        </v-row>
        <p @click.stop="demoTripScroll" class="vf-body">{{ $t("message.about.start2") }}</p>
        <h5 class="primary--text my-4">{{ $t("message.about.casualTitle") }}</h5>
        <p class="vf-list" v-for="(txt, idx) in $t('message.about.casual')" :key="'a'+idx">• {{txt}}</p>
        <p class="vf-body">{{$t('message.about.example1')}}</p>
        <p class="vf-body">{{$t('message.about.example2')}}</p>
        <p class="vf-body">{{parseScroll($t('message.about.casualInfo'),0)}} 
          <a href="#" @click.stop="demoTripScroll">{{parseScroll($t('message.about.casualInfo'),1)}}</a>
          {{parseScroll($t('message.about.casualInfo'),2)}}</p>
        <v-row justify="center">
          <v-col class="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-parallax
                  class="my-6 transition-swing"
                  :class="hover ? `elevation-20` : `elevation-6`"
                  height="270"
                  src="../assets/paysScreen.jpg"
                ></v-parallax>
              </template>
            </v-hover>
          </v-col>
        </v-row>
        <h5 class="primary--text my-4">{{ $t("message.about.freeTitle") }}</h5>
        <p class="vf-list" v-for="(txt, idx) in $t('message.about.free')" :key="'b'+idx">• {{parseScroll(txt,0)}} <a href="#" @click.stop="demoTripScroll">{{parseScroll(txt,1)}}</a>{{parseScroll(txt,2)}}</p>
        <h5 class="primary--text my-4">{{ $t("message.about.tollTitle") }}</h5>
        <p class="vf-list" v-for="(txt, idx) in $t('message.about.toll')" :key="'c'+idx">• {{txt}}</p>        
        <h5 class="primary--text my-4">{{ $t("message.about.impossibleTitle") }}</h5>
        <p class="vf-list" v-for="(txt, idx) in $t('message.about.impossible')" :key="'d'+idx">• {{txt}}</p>      
        <v-row justify="center">
          <v-col class="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5">          
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-parallax
                  class="my-6 transition-swing"
                  :class="hover ? `elevation-20` : `elevation-6`"
                  height="270"
                  src="../assets/statScreen.jpg"
                ></v-parallax>
              </template>
            </v-hover>      
          </v-col>
        </v-row>                
        <h5 class="primary--text my-4">{{ $t("message.about.beginTitle") }}</h5>
        <p class="vf-list" v-for="(txt, idx) in $t('message.about.begin')" :key="'e'+idx">• {{parseScroll(txt,0)}} <a href="#" @click.stop="demoTripScroll">{{parseScroll(txt,1)}}</a>{{parseScroll(txt,2)}}</p>
        <p class="vf-body" v-if="cordovaApp">{{$t('message.about.infoPrice')}}</p>
        <p class="vf-body">{{$t('message.about.free1')}}</p> 
        <p class="vf-body">{{$t('message.about.beginPlus')}}</p>
        <p class="vf-body">{{parseScroll($t('message.about.free2'),0)}}
          <v-icon color="primary">{{parseScroll($t('message.about.free2'),1)}}</v-icon></p>
        <p class="vf-body">{{$t('message.about.goodLuck')}}</p>          
      </v-col>
    </v-row>
    <v-divider class="my-6"/>
    <v-row>
      <v-col cols="2" class="text-right"> </v-col>
      <v-col cols="8" class="text-left">  
        <h5 ref="demoTrip" id="demo-trip" @click.stop="demoDescription = !demoDescription" class="primary--text pointer">{{ $t("message.about.demoTitle1") }}</h5>
      </v-col>
      <v-col cols="2" class="text-right"> 
        <v-icon v-if="!demoDescription" @click.stop="demoDescription = true">
          mdi-chevron-down
        </v-icon>
        <v-icon v-else @click.stop="demoDescription = false">
          mdi-chevron-up
        </v-icon>           
      </v-col>
    </v-row>
    <transition name="fade">
      <v-row v-if="demoDescription" justify="center">
        <v-col class="col-6 col-sm-3 col-md-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }"> 
              <v-btn
                v-bind="attrs"
                v-on="on"   
                width="100%"     
                class="ma-0"
                elevation="4"
                color="primary"
                @click.stop="joinDemo">
                <v-icon large>mdi-hiking</v-icon>
              </v-btn>        
            </template>
            {{$t('message.about.joinDemo')}}
          </v-tooltip>
        </v-col>
        <v-col class="col-6 col-sm-3 col-md-2">
          <v-tooltip 
          bottom
          nudge-top="-10px"
          >
            <template v-slot:activator="{ on, attrs }"> 
              <v-btn
                v-if="cordovaApp"
                v-bind="attrs"
                v-on="on"
                @click.stop="fileDownload(demoTrip[config.locale].xls)"
                width="100%"    
                class="ma-0"
                elevation="4"
                color="primary">
                <v-icon large>mdi-microsoft-excel</v-icon>
              </v-btn>              
              <a v-else :href="demoTrip[config.locale].xls" >     
                <v-btn
                  v-bind="attrs"
                  v-on="on"    
                  width="100%"    
                  class="ma-0"
                  elevation="4"
                  color="primary">
                  <v-icon large>mdi-microsoft-excel</v-icon>
                </v-btn>
              </a>
            </template>
            {{$t('message.about.xlsDemo')}}
          </v-tooltip>
        </v-col>
        <v-col cols="12">
          <h5 class="description primary--text my-4">{{ $t("message.about.demoTitle2") }}</h5>
          <p class="vf-body">{{ $t("message.about.demo1") }}</p>
          <p class="vf-body" v-html="$t('message.about.demoEdgeTitle')"></p>
          <p class="vf-list" v-for="(txt, idx) in $t('message.about.demoEdge')" :key="'f'+idx">• {{txt}}</p>
          <p class="vf-body" v-html="$t('message.about.demoRawTitle')"></p>
          <p class="vf-list" v-for="(txt, idx) in $t('message.about.demoRaw')" :key="'g'+idx">• {{txt}}</p>
          <p class="vf-body" v-html="$t('message.about.demo2')"></p>
          <p class="vf-body" v-html="$t('message.about.demoListTitle')"></p>
          <p class="vf-list" v-for="(txt, idx) in $t('message.about.demoList')" :key="'h'+idx">• {{txt}}</p>
          <p class="vf-body" v-html="$t('message.about.share1')"></p>
          <p class="vf-body" v-html="$t('message.about.share2')"></p>
          <p class="vf-body" v-html="$t('message.about.share3')"></p>
          <p class="vf-body" v-html="$t('message.about.share4')"></p>
          <p class="vf-body" v-html="$t('message.about.share5')"></p>
          <img src="../assets/share_demo.svg" class="mt-3" style="width: 15em"/>
          <!-- <p class="vf-list" v-for="(txt, idx) in $t('message.about.shareList')" :key="'i'+idx" v-html="txt"></p> -->
          <p class="vf-body" v-html="$t('message.about.share6')"></p>
          <p class="vf-body" v-html="$t('message.about.demo3')"></p>
          
          <p class="vf-body" v-html="$t('message.about.balanceTitle')"></p>
          <p class="vf-list" v-for="(txt, idx) in $t('message.about.balance')" :key="'j'+idx" v-html="'• ' + txt"></p>
          <p class="vf-body" v-html="$t('message.about.balanceInfo1')"></p>
          <p class="vf-body" v-html="$t('message.about.balanceInfo2')"></p>
        </v-col>
      </v-row> 
    </transition>
    <v-divider class="my-6"/>
    <v-row justify="center">
      <v-col class="col-12 col-sm-8 col-md-5 col-lg-4 col-xl-3 text-center">
        <v-btn    
          class="ma-0"
          width="100%"
          elevation="4"
          color="primary"
          @click.stop="$emit('agree',0)">
          {{$t('doc.confidenceAgreementTitle')}}
        </v-btn>
      </v-col>
      <v-col class="col-12 col-sm-8 col-md-5 col-lg-4 col-xl-3 text-center">   
        <v-btn    
          class="ma-0"
          width="100%"
          elevation="4"
          color="primary"
          @click.stop="$emit('agree',1)">
          {{$t('doc.userAgreementTitle')}}
        </v-btn>        
      </v-col> 
      <v-col class="col-12 col-sm-8 col-md-5 col-lg-4 col-xl-3 text-center">   
        <v-btn    
          class="ma-0"
          width="100%"
          elevation="4"
          color="success"
          @click.stop="$emit('account')">
          {{$t(config.master ? 'message.navbar.account' : 'message.navbar.createAccount')}}
        </v-btn>        
      </v-col>            
      <div style="height: 200px"></div>
    </v-row> 
  </v-container>
</template>
<script>
import { CDCordova, cordovaApp } from "./cordova.js";
export default {
  name: "AboutInfo",
  props: {
    config: { type: Object },
    urls: { type: Object }
  },
  data: () => {
    return {
      prlx: false,
      demoDescription: false,
      cordovaApp: cordovaApp,
    }
  },
  mounted() {
    this.prlx = true;
  },
  computed: {
    demoTrip() {
      return [ 
        {lang: 'EN', slave: 1199, key: 'FCAe8435', xls: this.urls.local + 'xls/demo_en.xls'},
        {lang: 'UA', slave: 1209, key: 'bF95DA7x', xls: this.urls.local + 'xls/demo_ua.xls'},
        {lang: 'RU', slave: 1219, key: '2CaecCB8', xls: this.urls.local + 'xls/demo_ru.xls'}
      ]
    }
  },
  methods: {
    fileDownload(src){
      this.$emit('console','XLS file dowload: '+src)
      CDCordova.InAppBrowser.open(src,'_system');
    },
    parseScroll(txt, idx) {
      let parts = txt.split('$');
      if(parts.length <= idx) {
        parts[idx] = '';
      } 
      return parts[idx];
    },
    joinDemo() {
      this.$emit('invite',{key:  this.demoTrip[this.config.locale].key, slave: this.demoTrip[this.config.locale].slave});
      // window.open(this.urls.root + '/?m=slave&k=' + this.demoTrip[this.config.locale].key + '&s=' + this.demoTrip[this.config.locale].slave+'&i='+Date.now(),'_self');
    },
    demoTripScroll() {
      this.$vuetify.goTo(this.$refs.demoTrip);
    }
  }
};
</script>
<style scoped>
.vf-body {
  font-size: 1em;
  margin: 0.7em 0 0 0;
  text-align: left;
}
.vf-list {
  font-size: 1em;
  margin: 0;
  text-align: left;
}
h5 {
  font-size: 1.2em;
  text-align: center;
}
h5.description {
  text-align: center;
}
.pointer {
  cursor: pointer;
}
</style>
