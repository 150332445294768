<template>
  <div id="qrframe">
    <div
      class="qr-innerframe"
      style="
        border-left-width: 10px;
        border-top-width: 10px;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
      "
    ></div>
    <div
      class="qr-innerframe"
      style="
        border-right-width: 10px;
        border-top-width: 10px;
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 3;
        grid-row-end: 4;
      "
    ></div>
    <div class="qr-center"></div>
    <div class="qr-border"></div>
    <div
      class="qr-innerframe"
      style="
        border-left-width: 10px;
        border-bottom-width: 10px;
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 5;
        grid-row-end: 6;
      "
    ></div>
    <div
      class="qr-innerframe"
      style="
        border-right-width: 10px;
        border-bottom-width: 10px;
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row-start: 5;
        grid-row-end: 6;
      "
    ></div>
  </div>
</template>
<script>
export default {
  name: "QrScanner",
  props: {
    ready: { type: Boolean }
  },
  data: () => {
    return {
      qrTimer: null,
      qrVisible: false
    };
  },
  methods: {
    qrPrepare() {
      window.setTimeout(() => {
        this.$emit('console','QR Prepare');
        window.QRScanner.prepare((err, status) => {
          this.$emit('console','QR error: '+err+' QR status: '+JSON.stringify(status));
          if(!err) {
            if (status.authorized) {
              this.$emit('console','QR ready');
              window.setTimeout(() => {
                this.qrShow();
              }, 300);          
            } else {
              this.$emit('console','QR not allowed');
            }
          }
        });
      }, 300);
    },
    qrShow() {
      this.$emit('console','QR scan begin');
      this.qrVisible = true;
      document.querySelector('.v-main__wrap').style.backgroundColor = 'transparent';
      document.querySelector('.v-main').style.backgroundColor = 'transparent';
      document.querySelector('.v-application--wrap').style.backgroundColor = 'transparent';
      document.querySelector('#app').style.backgroundColor = 'transparent';
      window.QRScanner.show();
      this.qrTimer = window.setInterval(() => {
        window.QRScanner.scan((err, text) => {
          if (err) {
            this.$emit('console','QR not recognized');
          } else {
            this.$emit('console','QR Recognized :)');
            window.clearInterval(this.qrTimer);
            window.setTimeout(() => {
              this.qrHide(text);
            }, 300);
          }
        });
      }, 3000);
    },
    qrHide(text = null) {
      if(this.qrVisible) {
        this.qrVisible = false;
        this.$emit('console','QR scanner hide');
        document.querySelector('.v-main__wrap').style.backgroundColor = 'white';
        document.querySelector('.v-main').style.backgroundColor = 'white';
        document.querySelector('.v-application--wrap').style.backgroundColor = 'white';
        document.querySelector('#app').style.backgroundColor = 'white';
        window.QRScanner.destroy(() => {
            this.$emit('console','QR scanner destroyed');
            if(text) {
              window.setTimeout(() => {
                this.$emit("scan", text);
              }, 300);            
            }
        });
      }
    }
  },
  watch: {
    ready: {
      handler(value) {
        this.$emit('console','QR scanner Watch '+value);
        if (value) {
          this.qrPrepare();
        } else {
          this.qrHide();
        }
      },
    }
  },
};
</script>
<style>
#qrframe {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 10% 2% 15% 46% 15% 2% 10%;
  grid-template-rows: 20% 2% 10% 31% 10% 2% 25%;
  background-color: transparent;
}
.qr-innerframe {
  margin: 10px;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 255, 0.5);
  background-color: transparent;
}
.qr-center {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(250, 250, 250, 0.5);
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 5;
  background-color: transparent;
}
.qr-border {
  border-width: 4px;
  border-style: solid;
  border-color: rgba(250, 250, 250, 0.5);
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 7;
  background-color: transparent;
}
</style>
