import { appVersionApi, cordovaApp, CDNavigator } from "./components/cordova.js";
const API_URL = 'https://www.tripcount.click/get/api/ver/'+appVersionApi;
const LOG_URL = 'https://www.tripcount.click/log/api';
const PING_URL = 'https://www.tripcount.click/ping/api';

const SERVER_ERROR_BAD_ID = 1;
// const ERROR_BAD_REGISTRATION = 2;
const SERVER_ERRORS_NONREPAIRABLE = 100;
const SERVER_ERRORS_FATAL = 200;

const API_ATTEMPT = 3;            // макс. кол-во попыток подряд получить данные
const API_ID_ATTEMPT = 5;         // макс. кол-во корректировок id пакета подряд
const API_TIMEOUT_ATTEMPT = 3;    // макс. кол-во пропусков цикла из-за ожидания API
const API_ERROR_DELAY = 2500;     // пауза при ошибках http
const API_COMMAND_ATTEMPT = 3;
const QUEUE_MAX_DEEP = 25;
const QUEUE_FINAL_MAX_DEEP = 5;
const WEBLOG_MAX_DEEP = 500;
const API_MASTER_NODE = 6;
const API_MASTER_KEY = "mFbPJw7z";
const API_MASTER_ID = 1001;
const API_REFRESH = 5000;
const API_LOG_LOW = 10;
const API_LOG_MIDDLE = 11;  // errors
const API_LOG_HIGH = 12;

let pack = 1001;
let pack_dispose = 1000;
let slave = {
  key : "FcEdD8JA",
  node : 0,
  id : 0,
  getter : 'get_id' };

let master = {
  key : API_MASTER_KEY,
  node : API_MASTER_NODE,
  id : API_MASTER_ID,
  trip: 0,
  getter : '_get_id' };
let ee = 0;
let app = '';
let api_timeout = 0;
let api_queues = {};
let api_uuid = '';
let api_logger = null;
let shajs = require("sha.js");
let api_timer = null;
let sleepTimer = null;
let api_timer_ratio = 1;
let api_timer_ratio_max = 3;
let api_timer_ratio_cnt = 0;
let api_timer_ratio_off = 3;
let api_timer_cnt = 1;
let dt = new Date();
let api_log_cnt = parseInt(dt.getTime().toString()); //.substring(1,13))-5796903000;
let api_callback = null;
let api_callback_busy = null;
let api_callback_msg = null;
let api_callback_statement = null;
let api_busy = false;
let api_command_process = false;
let api_silent = true;
let api_promises = [];
let weblog_buffer = [];
let weblog_busy = false;


function autoRatio(mod = 0) {
  if(mod) {
    api_timer_ratio_cnt ++;
  } else {
    api_timer_ratio_cnt = 0;
  }
  if((api_timer_ratio_cnt >= api_timer_ratio_off) && (api_timer_ratio < api_timer_ratio_max)) {
    api_timer_ratio = api_timer_ratio_max;
  }
  if((api_timer_ratio_cnt < api_timer_ratio_off) && (api_timer_ratio >= api_timer_ratio_max)) {
    api_timer_ratio = 1;
    api_timer_cnt = 1;
  }
}

function busy(mode, model='api',silent = false) {
  api_busy = mode;
  if(api_callback_busy && !(api_busy && silent)) {
    api_callback_busy(api_busy);
  }
  api_log('Api ' + (mode ? 'Locked by ' : 'Released by ') + model);
}
function masterMode() {
  return slave.node ? -1 : (master.node ? 1 : 0);
}
function api_log(text, channel = API_LOG_HIGH) {
  if(!api_silent) {
    console.log(text);
  }
  if(api_logger) {
    api_logger(text, channel);
  }
}
function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode("0x" + p1);
      }
    )
  );
}

function errorSet(err, memo = '') {       //0 - ok, 1 - api error, -1 - server error, -2 fatal server error
  if (err) {
    apiUnsubscribe();
  }
  if (api_callback_statement) {
    api_callback_statement(err, memo);
  }
  api_log('Err: '+err, err ? API_LOG_MIDDLE : API_LOG_HIGH);
  if(err < 0) {                     // Server & FATAL
    api_callback_statement = null;  // Блокируем дальнейший поток уведомлений
  }
}

async function apiEngine(method = null, data_in = null, node = slave) {
  if(!method) {
    method = node.getter;
  }
  let result = null;
  let repeat_id = 0;
  let api_errors = 0;
  let error_memo = '';
  if(!data_in) {
    data_in = {};
  }  
  if(!data_in.lang) {
    data_in.lang = node.lang;
  }
  if(!data_in.app) {
    data_in.app = app;
  }
  api_log('MasterID setting '+master.node, API_LOG_LOW);
  if((node == slave) && master.node && (master.node != API_MASTER_NODE)) {
    /*
    if(!data_in) {
      data_in = {};
    }
    */
    data_in.master_id = master.node;
    data_in.master_key = shajs("sha1")
      .update(master.key + node.id + pack + master.node)
      .digest("hex").substring(7,13);    
  }
  do {
    if(repeat_id) {
      api_log('Next id attempt', API_LOG_LOW);
    }
    let data = JSON.stringify({
      pack: pack++,
      id: parseInt(node.id),
      node: parseInt(node.node),
      method: method,
      data: data_in});  
    api_log('Api '+method, API_LOG_LOW);
    let key = shajs("sha1")
      .update(node.key + data)
      .digest("hex");
    let body = JSON.stringify({ data: b64EncodeUnicode(data), key: key }); // btoa(data)
    api_log('DATA: '+data, API_LOG_LOW);
    api_log('KEY: '+key, API_LOG_LOW);
    api_log('BODY: '+body, API_LOG_LOW);
    api_errors = 0;
    do {
      let connection = cordovaApp ? CDNavigator.connection.type : 'web';
      if(api_errors) {
        api_log('Next attempt', API_LOG_LOW);
      }
      if(connection != 'none')
      {
        api_log('Connected '+connection, API_LOG_MIDDLE);
        try {
          let response = await fetch(API_URL, {
            method: "POST",
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              "Content-Type": "application/json", // or 'application/x-www-form-urlencoded' 'application/json'
              Accept: "application/json", // expected data sent back
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: body, // body data type must match "Content-Type" header
          });
          if (response && response.ok) {
            result = await response.json();
            if (typeof result == "object") {
              api_log('Fetched: ', API_LOG_LOW);
              api_log(JSON.stringify(result), API_LOG_LOW);  
              error_memo = 'Server error '+result.error+' : '+result.memo;
              if(result.pack <= pack_dispose) {        // result not actual - DISPOSE
                api_log('=== *** === API result disposed', API_LOG_MIDDLE);
                api_errors = 0;
                repeat_id = 0;
                result = null;
              } else if(result.error > SERVER_ERRORS_FATAL) {
                api_log('Data has fatal error: '+result.memo, API_LOG_MIDDLE);              
                api_errors = -2;
                result = null;
              } else if(result.error > SERVER_ERRORS_NONREPAIRABLE) {
                api_log('Data has internal error: '+result.memo, API_LOG_MIDDLE);
                api_errors = -1;
                result = null;
              } else if(result.error == SERVER_ERROR_BAD_ID) {
                api_log('Data error: '+result.memo, API_LOG_MIDDLE);
                if(result.id) {
                  node.id = parseInt(result.id);
                  api_errors = (++repeat_id < API_ID_ATTEMPT) ? 0 : -1;
                } else {
                  api_errors = -1;  
                }
              } else if(result.error) {
                api_log('Data has non-critical error: '+result.memo, API_LOG_MIDDLE);
                api_errors = -3;
              } else {
                api_errors = 0;
                repeat_id = 0
              }
            } else {
              api_log('Bad data', API_LOG_MIDDLE);
              result = null;
              api_errors++;
            }
          } else {
            api_log('Bad http', API_LOG_MIDDLE);
            api_errors++;
          }
        } catch (err) {
          api_log('Http error '+err.message, API_LOG_MIDDLE);
          api_errors++;
        }
      } else {
        api_log('Offline', API_LOG_MIDDLE);
        api_errors++;        
      }
      if(api_errors > 0) {
        await delayMe(API_ERROR_DELAY);
      }
    } while((api_errors > 0) && (api_errors < API_ATTEMPT));
  } while(!api_errors && (repeat_id > 0) && (repeat_id < API_ID_ATTEMPT));
  errorSet((api_errors > 0) ? 1 : api_errors, (api_errors > 0) ? 'message.api.engineError' : error_memo);
  //await delayMe(1500);
  return result;
}
async function delayMe(tme) {
  return new Promise(function(resolve) {
    window.setTimeout(() => {
      resolve();
    }, tme);
  });
}
async function getNext() {                    // опрос наличия новых данных и получение, если есть.
  let processResult = true;
  api_log('Current id (get): '+slave.id);
  let mMode = masterMode();
  if (api_callback && mMode) {
    if((mMode > 0) && !master.id) {
      autoRatio();
      let result = await apiEngine('_get_id',{},master);
      if (result) {
        errorSet(0);
        master.id = parseInt(result.id);
        if(result.data) {
          api_callback_msg(result.data);        
        }
      } else {
        api_log('Master get_id error', API_LOG_MIDDLE);
        processResult = false;
      }
    }
    if((mMode < 0) || master.id) {
      autoRatio(1);
      let result = (mMode > 0) ? await apiEngine("_get_trip_id",{trip: master.trip},master) : await apiEngine("get_id");
      if (!result) {
        slave.id = 0;
        master.id = 0;
        api_log('get_id error found', API_LOG_MIDDLE);
        processResult = false;
      } else {
        let id = parseInt(result.id);
        if(result.data) {
          api_callback_msg(result.data);            
        }        
        api_log('Data update? '+id+' / '+ slave.id);
        errorSet(0);
        if (id != slave.id) {
          slave.id= id;
          autoRatio();
          let dresult = (mMode > 0) ? await apiEngine("_get_data",{trip: master.trip},master) : await apiEngine("get_data");
          if (!dresult) {
            slave.id = 0;
            master.id = 0;
            api_log('get_data error found', API_LOG_MIDDLE);
            processResult = false;
          } else {
            errorSet(0);
            slave.id = parseInt((mMode > 0) ? dresult.slave_id : dresult.id);
            api_callback(dresult.data);          
            api_log('Data updated '+dresult.id);
            if(dresult.data) {
              api_callback_msg(dresult.data);            
            }
          }
        } else {
          api_log('No updates need');
          if(result.data) {
            api_callback_msg(result.data);
          }
        }  
      }
    }
  }  
  return processResult;
}

async function getId(node = slave) {
  node.id = 0;
  api_log('API init');
  autoRatio();
  let data = await apiEngine(null,{},node);
  if (data && data.id) {
    node.id = data.id;
    api_log('API init success');
    errorSet(0);
    if(data.data) {
      api_callback_msg(data.data);
    }    
  } else {
    api_log('API init failure', API_LOG_MIDDLE);
    // errorSet(1, 'API init failure');
  }
}
export const api = async function(method, data, urgent = true) {        // постановка новой срочной команды в очередь и запуск очереди, если api не занят
	let resolver = {};
	let disposer = {};
	let prom = new Promise(function(resolve) {
		disposer = () => {
      api_log('API command '+method+' DISPOSED!!!!!', API_LOG_MIDDLE);
			resolve(null);
		} 
    resolver = async () => {
      let processResult = true;
      if(!urgent) {                                                                   // если !urgent, сначала выпускаем все, что в основном стеке команд
        busy(true,'api_do_before');
        processResult = await setNext();
        busy(false,'api_do_before');
      }
      if(processResult) {
        let result = await apiDo(method, data);                                         // исполняем команду
        if(result) {                                                                    // если все ок
          api_log('API command '+method+' resolved', API_LOG_MIDDLE);
          resolve(result);                                                              // выдаем разультат
          api_promises.splice(0,1);  
          apiNext();        
        } else {
          processResult = false;
        }
      } 
      if(!processResult) {                                                            // ошибка метода или очереди перед ним
        api_log('API command error', API_LOG_MIDDLE);
        if(api_promises.counter > 0) {
          api_promises[0].counter--;                                                  // разрешаем повторный запуск
        } else {                                                                      // если все плохо
          errorSet(-1, 'message.api.commandError');                      
          resolve(null);          
          api_promises.splice(0,1);  
          apiNext();
        }
      }
    }
  });
	prom.resume = resolver;
	prom.dispose = disposer;  
  api_promises.push({
    promise: prom,
    counter: API_COMMAND_ATTEMPT
  });
  if(!api_command_process) {                                          // если обработка команд api не запущена, запускаем обработчик сразу же 
    apiNext();
  }    
  return prom;
}
function apiNext() {      
	if(!api_busy) {
		if(api_promises.length) {
			api_command_process = true;
			console.log('Resume');
			api_promises[0].promise.resume();
		} else {
			api_command_process = false;
		}
	}  
}
function apiDispose() {
  pack_dispose = pack++;
  api_command_process = true;
	api_promises.forEach((prm) => {
		prm.promise.dispose();
	});
  api_command_process = false;
  api_promises = [];
  errorSet(0);
}
async function apiDo(method, data) {   // исполнение срочной команды
  busy(true,'api_do');
  api_log('API. Method: '+method);
  let result = null;
  let node = (method[0] == '_') ? master : slave;
  if(method === node.getter) {
    node.id = -1;
  }
  if(!node.id) {
    await getId(node);
  }
  if(node.id) {
    autoRatio();
    result = await apiEngine(method, data, node);
    api_log('API direct. Data processed');
    if (!result) {
      node.id = 0;
      api_log('API direct. Data has errors', API_LOG_MIDDLE);
    } else {
      node.id = parseInt(result.id);
      if(typeof result.slave_id != 'undefined') {
        slave.id = parseInt(result.slave_id);
      }
      api_log('API direct. Data received. ID: '+node.id);      
      errorSet(0);
      if(result.data) {
        api_callback_msg(result.data);
      }  
    }
  }
  busy(false,'api_do');
  return !result ? null : result.data;
}

async function setNext(checkId = false) {            // просмотр очередей/стеков и исполнение несрочной команды из стека или очереди ( keys _** )
  let processResult = true;
  api_log('Set next. Keys found:');
  for(let queue_key in api_queues) {
    api_log('--> '+queue_key);
    let api_queue = api_queues[queue_key];
    let lng = api_queue.length;
    if(lng) {
      api_log('exists');
      let final = (queue_key[0] == '_') ? false : true;
      let data = api_queue[final ? (lng - 1) : 0];
      if(typeof data.method != 'undefined') {
        let method = data.method;
        api_log('method: '+method);
        delete data.method;
        let node = (method[0] == '_') ? master : slave;
        autoRatio();
        if(checkId && !node.id) {
          await getId(node);
        }
        if(node.id) {
          let result = await apiEngine(method, data, node);
          if(result) {
            errorSet(0);
            node.id = parseInt(result.id);
            if(final) {
              api_queue.splice(0, lng);
            } else {
              api_queue.shift();
            }
            if(result.data) {
              api_callback_msg(result.data);
            }
          } else {
            node.id = 0;
            api_log('API command queue. Data has errors', API_LOG_MIDDLE);
            processResult = false;
          }
        } else {
          processResult = false;
        }
      } else {
        api_log('API undefined method', API_LOG_MIDDLE);  
      }
    } else {
      delete api_queues[queue_key];
      api_log('Empty. Deleted');
    }
  }
  return processResult;
}
export const apiStackDepth = function (set = null) {
  let depth = 0;
  if(set) {
    if(typeof api_queues[set] != 'undefined') {
      depth = api_queues[set].length;
    }
  } else {
    for(let key in api_queues) {
      if(api_queues[key].length > depth) {
        depth = api_queues[key].length;
      }
    }
  }
  return depth;
}
export const apiPush = function (data, set = 'main') {    // постановка новой несрочной команды в очередь ( keys _** ) или стек
  if(typeof api_queues[set] == 'undefined') {
    api_queues[set] = [];
  }
  let final = (set[0] == '_') ? false : true; // true новые данные отменяют предыдущие (стек). false - накопление данных (очередь), set начинается с "_"
  let dta = structuredClone(data);
  let len = api_queues[set].length;
  if(final) {
    if(len > QUEUE_FINAL_MAX_DEEP) {
      api_queues[set].shift();
    }
    api_queues[set].push(dta);
  } else if(len <= QUEUE_MAX_DEEP) {
    api_queues[set].push(dta);
  } else {
    api_log('API Queue «'+set+'» full!', API_LOG_MIDDLE);
  }
};
async function apiCycle() {
  if (slave.node || master.node) {
    if (api_busy) {
      api_timeout++;
      api_log('API busy', API_LOG_MIDDLE);
    } else {
      busy(true,'cycle');
      api_log('API tick');
      api_timeout = 0;
      if(await getNext()) {
        await setNext();
      }
      busy(false,'cycle');
      apiNext();
    }
    if (api_timeout >= API_TIMEOUT_ATTEMPT) {
      errorSet(-1, 'message.api.busyTooLong');
      busy(false,'watchdog');
    }    
  } else {
    apiUnsubscribe();
  }
}

export const apiSetup = function (callback_data, callback_busy, callback_statement, callback_msg, uuid, version) {
  errorSet(0);  
  api_timeout = 0;
  api_callback = callback_data;
  api_callback_busy = callback_busy;
  api_callback_statement = callback_statement;
  api_callback_msg = callback_msg;
  api_uuid = uuid;
  app = version;  
  api_log('API Setup complete');
};

export const apiSubscribe = function (force = true) {
  api_log('API subscription');      
  if(sleepTimer) {
    clearInterval(sleepTimer);
    sleepTimer = null;
  }
  apiUnsubscribe(force);
  if(slave.node || (master.node && master.trip)) {
    slave.id = 0;
    master.id = 0;
    busy(false,'subscribe');
    api_timeout = 0;
    apiCycle();
    api_timer = setInterval(() => {
      if((--api_timer_cnt) < 1) {
        api_log('.'+(ee++));
        apiCycle();
        api_timer_cnt = api_timer_ratio;
      }
    }, API_REFRESH);
    api_log('API subscribed');
    return true;
  } else {
    api_log('API not subscribed');
    return false;
  }
};

export const apiSleep = function () {     // Несрочное отключение API с завершением всех очередей.
  sleepTimer = window.setInterval(()=>{
    let exsts = api_promises.length ? true : false;
    if(!exsts) {
      exsts = api_busy;
    }
    if(!exsts) {
      for(let que in api_queues) {
        if(que.length) {
          exsts = true;
        }
      }
    }
    if(!exsts) {
      clearInterval(sleepTimer);      
      if(api_timer) {
        clearInterval(api_timer);
        api_timer = null;
        api_log('Api Sleeping complete');
      }
    } else {
      api_log('API busy or queues not empty!');
    }
  },1000);
}

export const apiUnsubscribe = function (force = true) {
  if (api_timer) {
    clearInterval(api_timer);
    api_timer = null;
  }
  if(force) {    
    api_log('Api disposing queues');
    api_queues = {};
    apiDispose();
  }
  api_log('Api STOP');
};

export const apiSet = function (data = null, mst = false) {
  if(data) {
    apiDispose();
    let node = mst ? master : slave;
    node.key = data.key;
    node.lang = data.lang;
    node.node = parseInt(data.id);
    api_log('API =Set= Key:'+data.key+' • id:'+data.id+' • Master:'+mst + (mst ? (' • Trip:'+data.trip) : ''));
    node.id = 0;
    if(mst) {
      master.trip = data.trip;
    }
  }
  errorSet(0);
};

export const apiWipe = function (force = true) {
  apiDispose();
  master.key = API_MASTER_KEY;
  master.node = API_MASTER_NODE;
  master.id = API_MASTER_ID;
  if(force) {
    slave.node = 0;
    slave.id = 0;
  }
  busy(false,'wipe');
};

export const apiLoggerSet = function(lg) {
  api_logger = lg;
}

export const apiWebLog = function(data) {
  if(weblog_buffer.length < WEBLOG_MAX_DEEP) {
    weblog_buffer.push(data);
    apiWebLogProcess();
  } else {
    console.log('WebLog Overloaded');
    weblog_buffer.pop();
    weblog_buffer.push({ channel: API_LOG_MIDDLE, text: 'WebLog Overloaded'});
  }
}

function apiWebLogProcess() {
  let leng = weblog_buffer.length;
  //console.log('WebLog Length '+leng);
  if(leng) {
    if(!weblog_busy) {
      weblog_busy = true;
      try {
        fetch(LOG_URL, {
          method: "POST",
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json", // or 'application/x-www-form-urlencoded' 'application/json'
            Accept: "application/json", // expected data sent back
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *client
          body: JSON.stringify({id: api_log_cnt, node: api_uuid, data: weblog_buffer[0]}), // body data type must match "Content-Type" header
        }).then(() => {
          //console.log('WebLog Processed '+api_log_cnt);
          api_log_cnt++;
          weblog_buffer.shift();
          weblog_busy = false;
          apiWebLogProcess();
        }, () => {
          //console.log('WebLog Error '+api_log_cnt);
          weblog_busy = false;
        });
        return true;
      } catch(err) {
        //console.log('WebLog Fatal Error '+api_log_cnt);
        weblog_busy = false;
        return null;
      }
    } else {
      //console.log('WebLog Busy');
    }
  } else {
    //console.log('WebLog Empty');
  }
}

export const apiPing = async function() {
  return new Promise(function(resolve) {
    let connection = cordovaApp ? CDNavigator.connection.type : 'web';
    if(connection != 'none') {
      try {
        fetch(PING_URL, {
          method: "POST",
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json", // or 'application/x-www-form-urlencoded' 'application/json'
            Accept: "application/json", // expected data sent back
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *client
          body: JSON.stringify({id: 0}), // body data type must match "Content-Type" header
        }).then((result) => {
          resolve(result.ok);
        }).catch(()=>{
          resolve(null);
        })
      } catch(err) {
        resolve(null);  
      }
    } else {
      resolve(null);
    }
  });
}
