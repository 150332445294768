var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{class:_vm.table.class + ' vf-table',attrs:{"cellspacing":"0"}},[_c('tr',{class:_vm.table.rowClassHeader},_vm._l((_vm.headers),function(header,headerIdx){return _c('th',{key:headerIdx,class:header.class},[_vm._t('header-' + header.value,function(){return [_vm._v(" "+_vm._s(header.text))]},{"header":header,"id":headerIdx})],2)}),0),_vm._l((_vm.items),function(item,itemIdx){return [_vm._t("row",function(){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.byFilter(item)),expression:"byFilter(item)"}],key:itemIdx,class:'pointer ' + 
              ((_vm.switchRow == itemIdx)
                ? _vm.table.rowClassExpanded
                : (hover
                ? _vm.table.rowClassHover +
                  ' ' +
                  (itemIdx % 2 ? _vm.table.rowClassHoverEven : _vm.table.rowClassHoverOdd)
                : _vm.table.rowClass +
                  ' ' +
                  (itemIdx % 2 ? _vm.table.rowClassEven : _vm.table.rowClassOdd))),attrs:{"id":'data-table-'+itemIdx},on:{"click":function($event){$event.stopPropagation();_vm.switchRow = (_vm.switchRow == itemIdx) ? -1 : itemIdx}}},_vm._l((_vm.headers),function(header,headerIdx){return _c('td',{key:headerIdx,class:(header.itemNoWrap ? 'vf-nowrap ' : '') +
                header.itemClass +
                ' ' +
                (itemIdx % 2 ? header.itemClassEven : header.itemClassOdd)},[(headerIdx != _vm.switchCol)?[_vm._t('item-' + header.value,function(){return [_vm._v(" "+_vm._s(item[header.value])+" ")]},{"item":item,"id":itemIdx,"hd":_vm.byFilter(header,item,itemIdx)})]:[(_vm.switchRow == itemIdx)?_c('v-icon',{class:header.switchClass,on:{"click":function($event){$event.stopPropagation();_vm.switchRow = -1}}},[_vm._v(" mdi-chevron-up ")]):_c('v-icon',{class:header.switchClass,on:{"click":function($event){$event.stopPropagation();_vm.switchRow = itemIdx}}},[_vm._v(" mdi-chevron-down ")])]],2)}),0)]}}],null,true)}),_c('transition',{key:itemIdx + '_',attrs:{"name":"fade"}},[_vm._t("expanded-row",function(){return [(_vm.switchRow == itemIdx)?_c('tr',{class:_vm.table.rowClassExpandedScope},[_c('td',{class:_vm.table.colClassExpandedScope,attrs:{"colspan":_vm.cols}},[_vm._t("expanded",function(){return [_vm._v(" Expanded Scope ")]},{"item":item,"id":itemIdx})],2)]):_vm._e()]},{"item":item,"id":itemIdx})],2)]},{"item":item,"id":itemIdx})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }